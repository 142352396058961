import { Card, Table, TableProps } from 'antd';
import React, { ReactNode, useMemo } from 'react';
import { Widget } from './Widget';
import css from './TableWidget.module.less';
import cn from 'classnames';
import { GetRowKey } from 'rc-table/es/interface';
import { RemoteData, success } from '@devexperts/remote-data-ts';
import { renderRemoteData } from '../ui-kit/Loading';
import { isRemoteData } from '../../utils/remote-data.utils';

interface TableWidgetTheme {
	card?: string;
}

export type TableWidgetProps<T> = Omit<TableProps<T>, 'dataSource'> & {
	rowKey: GetRowKey<T>;
	header?: ReactNode;
	dataSource: RemoteData<unknown, T[]> | T[];
	theme?: TableWidgetTheme;
};

export interface SortOrder<T> {
	sortBy: keyof T;
	order: 'descend' | 'ascend';
}

export function TableWidget<T extends object>({
	columns,
	sticky,
	header,
	dataSource,
	theme,
	...props
}: TableWidgetProps<T>) {
	const styledColumns = useMemo(
		() =>
			columns?.map(col => ({
				...col,
				title: function () {
					return <span>{col.title}</span>;
				},
				showSorterTooltip: false,
				className: cn(css.col, col.className),
			})),
		[columns],
	);

	const data = useMemo(
		() => (isRemoteData(dataSource) ? (dataSource as RemoteData<Error, T[]>) : success(dataSource)),
		[dataSource],
	);

	return (
		<Widget className={cn(sticky && css.sticky)} header={header}>
			<Card bordered={false} className={cn(css.card, sticky && css.sticky, theme?.card)}>
				{renderRemoteData(data, {
					success: data => (
						<Table<T>
							pagination={false}
							{...props}
							dataSource={data}
							columns={styledColumns}
							sticky={sticky}
							className={cn(css.table, props.className)}
						/>
					),
				})}
			</Card>
		</Widget>
	);
}
