import { parseISO } from 'date-fns';
import { map } from 'fp-ts';
import { pipe } from 'fp-ts/lib/function';
import { ordString } from 'fp-ts/lib/Ord';
import { memo, useMemo, useState } from 'react';
import { useCurrentDate } from '../../../utils/context.utils';
import { periodToFilter, TimePeriod } from '../../../utils/date.utils';
import { remoteData } from '../../../utils/remote-data.utils';
import { useTradesData } from '../../../utils/trades.utils';
import { GlossaryHint } from '../../ui-kit/GlossaryHint';
import { TimePeriodSelector } from '../../ui-kit/TimePeriodSelector';
import { WidgetTitle } from '../../ui-kit/WidgetTitle';
import { BarchartWidget } from '../barchart/BarchartWidget';

interface TradesByBrokerTraderWidgetContainerProps {}

export const TradesByBrokerTraderWidgetContainer = memo<TradesByBrokerTraderWidgetContainerProps>(() => {
	const [timePeriod, setTimePeriod] = useState<TimePeriod>({ preset: 'ytd' });
	const now = useCurrentDate();
	const dataRaw = useTradesData();
	const data = useMemo(
		() =>
			pipe(
				dataRaw,
				remoteData.map(data => {
					const result = new Map<string, Map<string, number>>();
					const brokers = new Set<string>();
					const dateFilter = periodToFilter(timePeriod, now);
					data.forEach(d => {
						if (!dateFilter(parseISO(d.tradeDate))) {
							return;
						}
						const trader = d.trader || 'Unknown';
						let entry = result.get(trader);
						if (!entry) {
							entry = new Map<string, number>();
							result.set(trader, entry);
						}
						const broker = d.brokerGroup || 'Unknown';
						entry.set(broker, (entry.get(broker) ?? 0) + 1);
						brokers.add(broker);
					});
					const categories = Array.from(brokers.values());
					const series = pipe(
						result,
						map.collect(ordString)((trader, brokers) => ({
							name: trader,
							data: categories.map(c => brokers.get(c) ?? 0),
						})),
					);
					return { categories, series };
				}),
			),
		[dataRaw, timePeriod, now],
	);

	return (
		<BarchartWidget
			header={
				<WidgetTitle>
					Trades per Broker&nbsp;
					<GlossaryHint term="Trades per Broker (Chart)" />
				</WidgetTitle>
			}
			data={data}
			chartProps={{
				options: {
					annotations: {
						yaxis: [{ y: 0 }],
					},
				},
			}}
			beforeChart={
				<TimePeriodSelector
					value={timePeriod}
					onChange={setTimePeriod}
					presets={['yesterday', 'wtd', 'mtd', 'ytd']}
				/>
			}
			// series={pipe(
			// data,
			// record.collect((name, data) => ({ name, data: data.map(d => d.value) })),
			// )}
		/>
	);
});
