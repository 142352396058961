import { Typography } from 'antd';
import { pipe } from 'fp-ts/lib/function';
import { Fragment, memo, PropsWithChildren, useContext } from 'react';
import { ProductContext } from '../../utils/context.utils';
import { remoteData } from '../../utils/remote-data.utils';
import { EffectiveDateContainer } from './EffectiveDate';
import { FundSelectorContainer } from './FundSelector';
import css from './TitleWithFundSelector.module.less';
import cn from 'classnames';

interface TitleWithFundSelectorProps {
	hasSelector?: boolean;
	noEffectiveDate?: boolean;
}

export const TitleWithFundSelector = memo<PropsWithChildren<TitleWithFundSelectorProps>>(
	({ hasSelector = false, noEffectiveDate = false, children }) => {
		return (
			<Typography.Title level={1} className={css.header}>
				{/* <div className={css.leftSide}> */}
				<span className={cn(css.label, !hasSelector && css.label_fill)}>{children}</span>
				{hasSelector && (
					<div className={css.selectWrapper}>
						<FundSelectorContainer className={css.select} />
					</div>
				)}
				{/* </div> */}
				{!noEffectiveDate && <EffectiveDateContainer className={css.effectiveDate} />}
			</Typography.Title>
		);
	},
);

type TitleWithAutoFundSelectorProps = Omit<TitleWithFundSelectorProps, 'hasSelector'> & {
	activeLabel?: string;
};

export const TitleWithAutoFundSelector = memo<PropsWithChildren<TitleWithAutoFundSelectorProps>>(
	({ children, activeLabel, ...props }) => {
		const { productOptions } = useContext(ProductContext);
		const hasSelector = pipe(
			productOptions,
			remoteData.exists(opts => opts.length > 1),
		);
		const label = hasSelector ? activeLabel ?? <Fragment>{children}:</Fragment> : children;

		return <TitleWithFundSelector hasSelector={hasSelector} children={label} {...props} />;
	},
);
