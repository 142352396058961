import { Layout } from 'antd';
import React, { memo, useState } from 'react';
import css from './App.module.less';
import { SidebarContainer } from './components/layout/Sidebar';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { OverviewPage } from './components/pages/overview/OverviewPage';
import { PositionsPage } from './components/pages/PositionsPage';
import { TradingPage } from './components/pages/TradingPage';
import { RiskPage } from './components/pages/RiskPage';
import { GlossaryPageContainer } from './components/pages/GlossaryPage';
import { ReportsPage } from './components/pages/reports/ReportsPage';
import { RemoteData } from '@devexperts/remote-data-ts';
import { AuthToken } from './services/auth.service';
import { pipe } from 'fp-ts/lib/pipeable';
import { remoteData } from './utils/remote-data.utils';
import { ProductContextProvider } from './components/ui-kit/FundSelector';
import { Header } from './components/layout/mobile/Header';
import { NavbarContainer } from './components/layout/mobile/Navbar';
import { ErrorCover, LoadingCover } from './components/ui-kit/Loading';
import { useIsMobile } from './utils/context.utils';
import cn from 'classnames';
import { ContactsPage } from './components/pages/ContactsPage';

interface AppProps {
	token: RemoteData<Error, AuthToken>;
}

const cover = (
	<div style={{ width: '100vw', height: '100vh' }}>
		<LoadingCover />
	</div>
);

const errorCover = (
	<div style={{ width: '100vw', height: '100vh' }}>
		<ErrorCover />
	</div>
);

export const App = memo<AppProps>(({ token }) => {
	const [layoutClass, setLayoutClass] = useState('');
	const isMobile = useIsMobile();
	return pipe(
		token,
		remoteData.fold(
			() => cover,
			() => cover,
			() => errorCover,
			() => (
				<Router>
					<ProductContextProvider>
						<Layout className={cn(css.layout, isMobile && css.layout_mobile)}>
							{isMobile ? <Header /> : <SidebarContainer />}
							<Layout className={css.content}>
								<Layout.Content className={layoutClass}>
									<Switch>
										<Route path="/positions">
											<PositionsPage onLayoutClassChange={setLayoutClass} />
										</Route>
										<Route path="/trading">
											<TradingPage onLayoutClassChange={setLayoutClass} />
										</Route>
										<Route path="/risk">
											<RiskPage onLayoutClassChange={setLayoutClass} />
										</Route>
										<Route path="/glossary">
											<GlossaryPageContainer onLayoutClassChange={setLayoutClass} />
										</Route>
										<Route path="/reports">
											<ReportsPage />
										</Route>
										<Route path="/contacts">
											<ContactsPage />
										</Route>
										<Route path="/">
											<OverviewPage onLayoutClassChange={setLayoutClass} />
										</Route>
										<Redirect to={'/'} />
									</Switch>
								</Layout.Content>
							</Layout>
							{isMobile && <NavbarContainer />}
						</Layout>
					</ProductContextProvider>
				</Router>
			),
		),
	);
});
