import { option } from 'fp-ts';
import { ordNumber } from 'fp-ts/lib/Ord';

export const getOrElseZero = option.getOrElse(() => 0);

/*
export function getGrossExposure(e: Exposure) {
	return getOrElseZero(e.long) + getOrElseZero(e.short);
}

export function getNetExposure(e: Exposure) {
	return getOrElseZero(e.long) - getOrElseZero(e.short);
}
*/

export const ordOptionNumber = option.getOrd(ordNumber);

export const toPercent = (x: number) => x * 100;
