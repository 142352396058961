import { constVoid } from 'fp-ts/lib/function';
import { jwtDecode } from 'jwt-decode';
import Keycloak from 'keycloak-js/dist/keycloak.js';

export interface AuthToken {
	raw: string;
	groups?: string[];
}

export interface AuthService {
	getToken(): Promise<AuthToken>;
	logout(): Promise<void>;
}

export const authService = (): AuthService => {
	const keycloak = new Keycloak({
		clientId: 'horus-client-app',
		realm: 'horus-client-app',
		url: (window as any).UI_ENV?.keycloak ?? '/',
	});

	const initPromise = keycloak.init({
		onLoad: 'login-required',
	});

	const getToken = async () => {
		await initPromise;
		return keycloak
			.updateToken(10)
			.then(() => (keycloak.token ? parseToken(keycloak.token) : Promise.reject('Not authenticated')));
	};

	setInterval(() => {
		getToken().then(constVoid, constVoid);
	}, 3 * 60 * 1000);

	return { getToken, logout: keycloak.logout };
};

function parseToken(tok: string): AuthToken {
	const token = jwtDecode<AuthToken>(tok);
	token.raw = tok;
	return token;
}
