import { Eq } from 'fp-ts/Eq';
import { Ord } from 'fp-ts/Ord';
import { ord } from 'fp-ts';
export type Nullable<T> = T | null;

const isDefined = <A>(f: Nullable<A>): f is NonNullable<A> => f !== null;

export const nullable = {
	map:
		<A, B>(f: (val: A) => B) =>
		(val: Nullable<A>): Nullable<B> =>
			val === null ? null : f(val),
	isDefined,
	filter:
		<A>(f: (val: NonNullable<A>) => boolean) =>
		(val: Nullable<A>): Nullable<A> =>
			isDefined(val) && f(val) ? val : null,
	getOrd: <A>(o: Ord<A>) =>
		ord.fromCompare<Nullable<A>>((x, y) => (x === null ? (y === null ? 0 : -1) : y === null ? 1 : o.compare(x, y))),
};

export type Optional<T> = T | null | undefined;

const isDefinedOptional = <A>(f: Optional<A>): f is NonNullable<A> => f !== null && f !== undefined;
const getEqOptional = <A>(o: Eq<A>): Eq<Optional<A>> => ({
	equals: (a, b) => {
		if (isDefinedOptional(a)) {
			if (isDefinedOptional(b)) {
				return o.equals(a, b);
			} else {
				return false;
			}
		} else {
			return !isDefinedOptional(b);
		}
	},
});
const getOrdOptional = <A>(o: Ord<A>): Ord<Optional<A>> =>
	ord.fromCompare((a, b) => {
		if (isDefinedOptional(a)) {
			if (isDefinedOptional(b)) {
				return o.compare(a, b);
			} else {
				return 1;
			}
		} else {
			return isDefinedOptional(b) ? -1 : 0;
		}
	});

export const optional = {
	isDefined: isDefinedOptional,
	map:
		<A, B>(f: (val: NonNullable<A>) => B) =>
		(val: Optional<A>): Optional<B> =>
			isDefinedOptional(val) ? f(val) : null,
	filter:
		<A>(f: (val: NonNullable<A>) => boolean) =>
		(val: Optional<A>): Optional<A> =>
			isDefinedOptional(val) && f(val) ? val : null,
	getOrd: getOrdOptional,
	getEq: getEqOptional,
	getOrElse:
		<B>(fn: () => B) =>
		<A>(val: Optional<A>) =>
			isDefinedOptional(val) ? val : fn(),
	fold:
		<B, A>(onNone: () => B, onSome: (val: A) => B) =>
		(val: Optional<A>) =>
			isDefinedOptional(val) ? onSome(val) : onNone(),
};
