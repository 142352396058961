import { Card, Input, List, Popover, Skeleton, TableProps } from 'antd';
import React, { Fragment, memo, useMemo, useRef, useState } from 'react';
import * as remoteData from '@devexperts/remote-data-ts';
import { RemoteData, isPending } from '@devexperts/remote-data-ts';
import { pipe } from 'fp-ts/pipeable';
import { GetContacts_contacts } from '../../../../generated-types/GetContacts';
import { SearchOutlined } from '@ant-design/icons';
import { TableWidget } from '../../layout/TableWidget';
import { array } from 'fp-ts';
import { Optional, optional } from '../../../../../common/utils/nullable.utils';
import { Option } from 'fp-ts/lib/Option';
import { Loading, LoadingCover, renderRemoteData } from '../../ui-kit/Loading';
import { option } from 'fp-ts';
import css from './PrimaryContactWidget.module.less';
import { constNull } from 'fp-ts/lib/function';
import { useContactsData } from '../../../utils/contacts.utils';
import { HexagonIcon } from '../../ui-kit/Hexagon';
import { ReactComponent as HeadsetSvg } from '../../../icons/headset.svg';
import { colorGray2 } from '../../../utils/color.utils';
import cn from 'classnames';

interface PrimaryContactWidgetProps {
	inline?: boolean;
	contact: RemoteData<Error, Option<GetContacts_contacts>>;
}

export const PrimaryContactWidget = memo<PrimaryContactWidgetProps>(({ contact, inline = false }) => {
	const containerRef = useRef<HTMLDivElement | null>(null);

	return (
		<div
			ref={containerRef}
			className={cn({
				[css.container]: remoteData.isPending(contact),
				[css.container_padded]: inline && pipe(contact, remoteData.exists(option.isSome)),
			})}
		>
			{renderRemoteData(contact /*remoteData.pending as typeof contact*/, {
				pending: <LoadingCover className={css.loading} size={45} />,
				success: option.fold(constNull, contact => {
					const content = (
						<Card className={css.card} bordered={false}>
							<div className={css.name}>
								{contact.firstName} {contact.lastName}
							</div>
							{pipe(
								contact.email,
								optional.map(email => (
									<div className={css.email}>
										<a className={css.emailLink} href={`mailto:${email}`}>
											{email}
										</a>
									</div>
								)),
							)}
							{pipe(
								contact.phoneNumber,
								optional.map(phone => (
									<div className={css.phone}>
										<a className={css.phoneLink} href={`tel:${phone}`}>
											{phone}
										</a>
									</div>
								)),
							)}
						</Card>
					);

					return inline ? (
						content
					) : (
						<Popover
							overlayClassName={css.popover}
							getPopupContainer={() => containerRef.current || document.body}
							content={content}
						>
							<div className={css.iconWrap}>
								<HexagonIcon fill={colorGray2} className={css.icon} size={50}>
									<HeadsetSvg />
								</HexagonIcon>
							</div>
						</Popover>
					);
				}),
			})}
		</div>
	);
});

const primaryContactCompany = /Quay Partners/i;
const primaryContactRole = /Partner/i;

type PrimaryContactWidgetContainerProps = Pick<PrimaryContactWidgetProps, 'inline'>;

export const PrimaryContactWidgetContainer = memo<PrimaryContactWidgetContainerProps>(props => {
	const dataRaw = useContactsData();
	const dataRD = useMemo(
		() =>
			pipe(
				dataRaw,
				remoteData.map(
					array.findFirst(
						c => primaryContactCompany.test(c.company ?? '') && primaryContactRole.test(c.role ?? ''),
					),
				),
			),
		[dataRaw],
	);

	return <PrimaryContactWidget contact={dataRD} {...props} />;
});
