import React, { Fragment, memo } from 'react';
import { ResponsivePage } from '../layout/ResponsivePage';
import { WidgetList } from '../layout/WidgetList';
import { CostOfCarryWidgetContainer } from '../widgets/cost-of-carry/CostOfCarryWidget';
import { ExposureBreakdownWidgetContainer } from '../widgets/exposure-breakdown/ExposureBreakdownWidget';
import { ExposureByCurrencyWidgetContainer } from '../widgets/exposure-by-currency/ExposureByCurrencyWidget';
import { LiquidityWidgetContainer } from '../widgets/liquidity/LiquidityWidget';
import { RiskMetricsWidgetContainer } from '../widgets/risk-metrics/RiskMetricsWidget';

interface RiskPageProps {
	onLayoutClassChange: (c: string) => void;
}

export const RiskPage = memo<RiskPageProps>(({ onLayoutClassChange }) => {
	return <ResponsivePage onLayoutClassChange={onLayoutClassChange} pageName="Risk" page={RiskPageContents} />;
});

export const RiskPageContents = memo(() => {
	return (
		<Fragment>
			<WidgetList>
				<RiskMetricsWidgetContainer />
				<ExposureBreakdownWidgetContainer />
				<ExposureByCurrencyWidgetContainer />
				<LiquidityWidgetContainer />
				<CostOfCarryWidgetContainer />
			</WidgetList>
		</Fragment>
	);
});
