import React, { ComponentType, Fragment, memo, useContext, useEffect } from 'react';
import { ProductContext, useIsMobile } from '../../utils/context.utils';
import { MobileFundsCarousel, MobileFundsCarouselTheme } from './MobileFundsCarousel';
import { TitleWithAutoFundSelector, TitleWithFundSelector } from '../ui-kit/TitleWithFundSelector';
import css from './ResponsivePage.module.less';
import cn from 'classnames';

interface ResponsivePageTheme {
	mobileLayout?: string;
	mobile?: MobileFundsCarouselTheme;
}

interface ResponsivePageProps {
	onLayoutClassChange: (c: string) => void;
	pageName: string;
	page: ComponentType<{}>;
	mobilePage?: ComponentType<{}>;
	noDesktopHeader?: boolean;
	extendedCards?: boolean;
	theme?: ResponsivePageTheme;
}

export const ResponsivePage = memo<ResponsivePageProps>(
	({
		onLayoutClassChange,
		pageName,
		page: Page,
		mobilePage: MobilePage = Page,
		noDesktopHeader = false,
		extendedCards,
		theme,
	}) => {
		const isMobile = useIsMobile();
		const productContext = useContext(ProductContext);

		useEffect(() => {
			if (isMobile) {
				onLayoutClassChange(cn(theme?.mobileLayout, css.layout));
				return () => onLayoutClassChange('');
			}
		}, [isMobile, onLayoutClassChange, theme?.mobileLayout]);

		console.log('Rendering responsive page', isMobile, onLayoutClassChange, productContext);

		return isMobile ? (
			<Fragment>
				<MobileFundsCarousel
					theme={theme?.mobile}
					extendedCards={extendedCards}
					header={<TitleWithFundSelector hasSelector={false}>{pageName}</TitleWithFundSelector>}
					renderPanel={product => (
						<ProductContext.Provider value={{ ...productContext, selectedProduct: product }}>
							<MobilePage />
						</ProductContext.Provider>
					)}
				/>
			</Fragment>
		) : (
			<Fragment>
				{!noDesktopHeader && <TitleWithAutoFundSelector>{pageName}</TitleWithAutoFundSelector>}
				<Page />
			</Fragment>
		);
	},
);
