import { gql, useQuery } from '@apollo/client';
import { pipe } from 'fp-ts/lib/pipeable';
import { memo, useContext, useMemo } from 'react';
import { GetProductCurrencyExposure } from '../../../../generated-types/GetProductCurrencyExposure';
import { chartColors } from '../../../utils/color.utils';
import { ProductContext } from '../../../utils/context.utils';
import { toPercent } from '../../../utils/math.utils';
import { remoteData, useRemoteData } from '../../../utils/remote-data.utils';
import { renderPercent } from '../../../utils/string.utils';
import { GlossaryHint } from '../../ui-kit/GlossaryHint';
import { WidgetTitle } from '../../ui-kit/WidgetTitle';
import { BarchartWidget } from '../barchart/BarchartWidget';

interface ExposureByCurrencyWidgetContainerProps {}

const QUERY = gql`
	query GetProductCurrencyExposure($product: String!) {
		products(ids: [$product]) {
			id
			generationDate
			exposureByCurrency {
				label
				value
			}
		}
	}
`;

export const ExposureByCurrencyWidgetContainer = memo<ExposureByCurrencyWidgetContainerProps>(() => {
	const { selectedProduct } = useContext(ProductContext);
	const { data, loading, error } = useQuery<GetProductCurrencyExposure>(QUERY, {
		variables: { product: selectedProduct?.id ?? '' },
		skip: !selectedProduct,
	});

	const dataRaw = useRemoteData(data?.products?.[0]?.exposureByCurrency ?? undefined, loading, error);
	const dataRD = useMemo(
		() =>
			pipe(
				dataRaw,
				remoteData.map(data => ({
					categories: data.map(c => c.label),
					series: [{ name: 'Exposure by Currency', data: data.map(d => toPercent(d.value)) }],
				})),
			),
		[dataRaw],
	);

	return (
		<BarchartWidget
			header={
				<WidgetTitle>
					Exposure by Currency&nbsp;
					<GlossaryHint term={/Exposure by currency/i} />
				</WidgetTitle>
			}
			data={dataRD}
			noLegend
			chartProps={{
				options: {
					annotations: {
						yaxis: [{ y: 0 }],
					},
					stroke: {
						show: true,
						width: 2,
						colors: chartColors,
					},
					yaxis: {
						labels: {
							formatter: (val: number) => renderPercent(val),
						},
					},
				},
			}}
		/>
	);
});
