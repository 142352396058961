import { Button, Card, Col, Input, Row, Typography } from 'antd';
import React, { Fragment, memo, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import css from './GlossaryPage.module.less';
import { array, nonEmptyArray, option, ord } from 'fp-ts';
import { makeBy } from 'fp-ts/lib/Array';
import { pipe } from 'fp-ts/lib/pipeable';
import { ordString } from 'fp-ts/lib/Ord';
import { NonEmptyArray } from 'fp-ts/lib/NonEmptyArray';
import { constVoid } from 'fp-ts/lib/function';
import cn from 'classnames';
import { NoDataCover, renderRemoteData } from '../ui-kit/Loading';
import { RemoteData } from '@devexperts/remote-data-ts';
import { remoteData } from '../../utils/remote-data.utils';
import { useGlossaryData } from '../../utils/glossary.utils';

interface GlossaryPageProps {
	terms: RemoteData<
		Error,
		Array<{
			term: string;
			definition: string;
			search: string;
		}>
	>;
	onLayoutClassChange: (c: string) => void;
}

const anchors = makeBy('Z'.charCodeAt(0) - 'A'.charCodeAt(0) + 1, i => String.fromCharCode('A'.charCodeAt(0) + i));

interface Bookmark {
	letter: string;
	offset: number;
}

export const GlossaryPage = memo<GlossaryPageProps>(({ terms, onLayoutClassChange }) => {
	const [search, setSearch] = useState('');

	useEffect(() => {
		onLayoutClassChange(css.layout);
		return () => onLayoutClassChange('');
	}, [onLayoutClassChange]);

	const filteredTerms = useMemo(() => {
		const input = search.toLowerCase();
		return pipe(terms, remoteData.map(array.filter(t => t.search.includes(input))));
	}, [terms, search]);

	const [currentBookmark, setCurrentBookmark] = useState<string | undefined>();
	const [bookmarks, setBookmarks] = useState<Bookmark[]>([]);
	const contentRef = useRef<HTMLDivElement | null>(null);
	useLayoutEffect(() => {
		const itemList = contentRef.current?.querySelectorAll<HTMLDivElement>(`.${css.termTitle}`);
		if (itemList?.length) {
			const items = Array.from(itemList.values()) as NonEmptyArray<HTMLDivElement>;
			const allEntries = pipe(
				items,
				array.map(item => ({
					letter: item.innerText.substr(0, 1).toUpperCase(),
					offset: item.offsetTop,
				})),
			);
			console.log(allEntries);
			const bookmarks = pipe(
				allEntries,
				array.map(e => ({ ...e, offset: e.offset - allEntries[0].offset })),
				array.filter(e => e.letter.length === 1),
				nonEmptyArray.groupSort(ord.contramap((o: Bookmark) => o.letter)(ordString)),
				array.map(nonEmptyArray.head),
			);
			console.log(items, bookmarks);
			setBookmarks(bookmarks);
		}
	}, [filteredTerms]);

	useLayoutEffect(() => {
		const handle = (target: HTMLDivElement) => {
			const top = target.scrollTop;
			pipe(
				bookmarks,
				array.findLast(b => b.offset <= top),
				option.fold(constVoid, bm => setCurrentBookmark(bm.letter)),
			);
		};
		const handler = (e: Event) => e.target && handle(e.target as HTMLDivElement);
		const target = contentRef.current;
		if (target) {
			handle(target);
			target.addEventListener('scroll', handler);
			return () => target.removeEventListener('scroll', handler);
		}
	}, [bookmarks]);

	const goToBookmark = useCallback(
		(letter: string) => {
			const bm = bookmarks.find(b => b.letter === letter);
			if (bm && contentRef.current) {
				contentRef.current.scrollTo({ behavior: 'smooth', top: bm.offset });
			}
		},
		[bookmarks],
	);

	return (
		<Fragment>
			<Row className={css.header} gutter={[0, 10]}>
				<Col flex="auto">
					<Typography.Title className={css.pageTitle} level={1}>
						Glossary
					</Typography.Title>
				</Col>
				<Col>
					<Input
						allowClear={true}
						type="text"
						placeholder="Search"
						value={search}
						className={css.search}
						onChange={e => setSearch(e.target.value)}
						suffix={<SearchOutlined />}
					/>
				</Col>
			</Row>
			<div className={css.anchors}>
				{anchors.map(letter => (
					<Button
						key={letter}
						disabled={bookmarks.every(x => x.letter !== letter)}
						className={cn(css.anchor, currentBookmark === letter && css.anchor_active)}
						onClick={() => goToBookmark(letter)}
					>
						{letter}
					</Button>
				))}
			</div>
			<Card className={css.card}>
				<div className={css.content} ref={contentRef}>
					{renderRemoteData(filteredTerms, {
						success: filteredTerms =>
							filteredTerms.length ? (
								filteredTerms.map((term, i) => (
									<div className={css.term} key={i}>
										<div className={css.termTitle}>{term.term}</div>
										<div className={css.termDefinition}>{term.definition}</div>
									</div>
								))
							) : (
								<NoDataCover />
							),
					})}
				</div>
			</Card>
		</Fragment>
	);
});

export const GlossaryPageContainer = memo<Omit<GlossaryPageProps, 'terms'>>(props => {
	const data = useGlossaryData();
	return <GlossaryPage {...props} terms={data} />;
});
