import { Card } from 'antd';
import { memo, useState } from 'react';
import { renderDate, renderMoney, renderNumGrouped, renderOptional } from '../../../utils/string.utils';
import css from './TradeCard.module.less';
import cn from 'classnames';
import { Trade } from './TradesWidget';
import { optional } from '../../../../../common/utils/nullable.utils';
import { pipe } from 'fp-ts/lib/pipeable';

interface TradeCardProps {
	trade: Trade;
}

export const TradeCard = memo<TradeCardProps>(({ trade }) => {
	const [expanded, setExpanded] = useState(false);
	return (
		<Card className={css.card} onClick={() => setExpanded(x => !x)}>
			<div className={css.row1}>
				<span className={css.ticker}>{trade.productName}</span>
				<div className={css.metrics}>
					<span className={css.qty}>
						{pipe(trade.quantity, optional.map(renderNumGrouped), renderOptional)}
					</span>
					{/* <span className={css.value}>{renderMoney(position.value, position.currency ?? undefined)}</span> */}
				</div>
			</div>
			<div className={cn(css.props, expanded && css.props_expand)}>
				<div className={css.propsInner}>
					<div className={css.prop}>
						<span className={css.label}>Trade Date:</span>
						{renderDate(trade.tradeDate)}
					</div>
					<div className={css.prop}>
						<span className={css.label}>Instrument:</span>
						{renderOptional(trade.instrument)}
					</div>
					<div className={css.prop}>
						<span className={css.label}>Ticker:</span>
						{renderOptional(trade.bloombergTicker)}
					</div>
					<div className={css.prop}>
						<span className={css.label}>Price:</span>
						{renderMoney(trade.price, trade.baseCurrency ?? undefined)}
					</div>
					<div className={css.prop}>
						<span className={css.label}>Currency:</span>
						{renderOptional(trade.baseCurrency)}
					</div>
					<div className={css.prop}>
						<span className={css.label}>Settlement Amount:</span>
						{renderMoney(trade.settleAmount)}
					</div>
					<div className={css.prop}>
						<span className={css.label}>Broker Group:</span>
						{renderOptional(trade.brokerGroup)}
					</div>
					<div className={css.prop}>
						<span className={css.label}>Trader:</span>
						{renderOptional(trade.trader)}
					</div>
				</div>
			</div>
		</Card>
	);
});
