import { Card, CardProps, Col, Row, Tooltip } from 'antd';
import { CSSProperties, memo, ReactNode, useMemo } from 'react';
import { HexagonIcon } from './Hexagon';
import css from './MetricCard.module.less';
import cn from 'classnames';
import { useIsDarkTheme } from '../../utils/context.utils';
import { progressColors } from '../../utils/color.utils';
import { renderPercent } from '../../utils/string.utils';

export interface MetricCardTheme {
	card?: string;
	iconFill?: string;
	iconSize?: number;
	iconStyle?: CSSProperties;
	valueRow?: string;
}

export type MetricCardProps = CardProps & {
	icon?: ReactNode;
	chart?: ReactNode;
	title: ReactNode;
	value: ReactNode;
	change?: ReactNode;
	theme?: MetricCardTheme;
};

export const MetricCard = memo<MetricCardProps>(({ icon, chart, title, value, change, theme, ...rest }) => {
	const isDark = useIsDarkTheme();
	const iconFill = theme?.iconFill ?? (isDark ? '#2D2D2D' : '#F4F5FA');
	return (
		<Card bordered={false} {...rest} className={cn(css.card, rest.className, theme?.card)}>
			<Row>
				{icon && (
					<Col>
						<HexagonIcon size={theme?.iconSize ?? 50} fill={iconFill}>
							{icon}
						</HexagonIcon>
					</Col>
				)}
				{chart && <Col children={chart} />}
			</Row>
			<Row className={cn(css.valueRow, theme?.valueRow)}>
				<Col className={css.value}>{value}</Col>
				{change && <Col children={change} />}
			</Row>
			<div className={css.title}>{title}</div>
		</Card>
	);
});

export const MetricCardMobile = memo<MetricCardProps>(({ icon, chart, title, value, change, theme, ...rest }) => {
	const isDark = useIsDarkTheme();
	const iconFill = theme?.iconFill ?? (isDark ? '#2D2D2D' : '#F4F5FA');
	return (
		<div className={css.listItem}>
			{icon && (
				<HexagonIcon size={50} fill={iconFill} className={css.icon}>
					{icon}
				</HexagonIcon>
			)}
			<div className={css.listItemContent}>
				<Row>
					<Col className={cn(css.value, css.value_mobile)}>{value}</Col>
					{change && <Col children={change} />}
					{chart && <Col children={chart} />}
				</Row>
				<div className={cn(css.title, css.title_mobile)}>{title}</div>
			</div>
		</div>
	);
});

type MetricBarCardValue = {
	value: number;
	tooltip?: ReactNode;
};

export type MetricBarCardProps = Omit<MetricCardProps, 'value'> & {
	value: Array<MetricBarCardValue>;
};

export const MetricBarCard = memo<MetricBarCardProps>(({ icon, chart, title, value, change, theme, ...rest }) => {
	const isDark = useIsDarkTheme();
	const iconFill = theme?.iconFill ?? (isDark ? '#2D2D2D' : '#F4F5FA');

	const [pieces, labels] = useMemo(() => {
		const pieces = [] as ReactNode[];
		const labels = [] as ReactNode[];
		let sum = 0;
		for (let i = 0; i < value.length; i++) {
			sum += value[i].value;
			const piece = (
				<div
					key={i}
					className={css.trackPiece}
					style={{ backgroundColor: progressColors[i], width: `${sum}%`, textAlign: 'right' }}
				>
					{value[i].tooltip && (
						<Tooltip overlay={value[i].tooltip}>
							<span
								style={{
									display: 'inline-block',
									width: `${(value[i].value / sum) * 100}%`,
									height: '100%',
								}}
							/>
						</Tooltip>
					)}
				</div>
			);
			pieces.push(piece);
			labels.push(
				<div key={i} className={cn(css.value, css.trackLabel)} style={{ flex: `1 1 ${value[i].value}%` }}>
					{renderPercent(value[i].value)}
				</div>,
			);
		}
		return [pieces.reverse(), labels];
	}, [value]);

	return (
		<Card bordered={false} {...rest} className={cn(css.card, rest.className, theme?.card)}>
			<Row>
				{icon && (
					<Col>
						<HexagonIcon size={theme?.iconSize ?? 50} fill={iconFill}>
							{icon}
						</HexagonIcon>
					</Col>
				)}
			</Row>
			<div>
				<div className={css.trackLabels}>{labels}</div>
				<div className={css.track}>{pieces}</div>
			</div>

			<div className={css.title}>{title}</div>
		</Card>
	);
});
