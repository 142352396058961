import { ProductContext, useIsDarkTheme, useIsMobile } from '../../utils/context.utils';
import { HexagonIcon } from './Hexagon';
import calendarIcon from '../../icons/calendar.svg';
import { memo, useContext } from 'react';
import { format } from 'date-fns';
import { pipe } from 'fp-ts/lib/pipeable';
import { optional } from '../../../../common/utils/nullable.utils';
import css from './EffectiveDate.module.less';
import cn from 'classnames';
import { GlossaryHint } from './GlossaryHint';

interface EffectiveDateProps {
	date: Date;
	className?: string;
}

export const EffectiveDate = memo<EffectiveDateProps>(({ date, className }) => {
	const isMobile = useIsMobile();
	const isDark = useIsDarkTheme();
	const iconFill = isDark ? '#464646' : '#fff';
	return (
		<div className={cn(css.container, className)}>
			<HexagonIcon size={isMobile ? 30 : 40} fill={iconFill} className={css.icon}>
				<img src={calendarIcon} />
			</HexagonIcon>
			<span>
				As at {format(date, 'yyyy-MM-dd')}&nbsp;
				<GlossaryHint term={'Reporting Date'} className={css.hint} />
			</span>
		</div>
	);
});

export const EffectiveDateContainer = memo<Omit<EffectiveDateProps, 'date'>>(props => {
	const { selectedProduct } = useContext(ProductContext);
	return (
		pipe(
			selectedProduct,
			optional.map(product => product.effectiveDate),
			optional.map(date => <EffectiveDate date={date} {...props} />),
		) ?? null
	);
});
