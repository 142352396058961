import { DatePicker } from 'antd';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import moment, { Moment } from 'moment';
import cn from 'classnames';
import css from './DateRangePicker.module.less';
import { useIsMobile } from '../../utils/context.utils';
import Autosizer from 'react-virtualized-auto-sizer';
import { tuple } from 'fp-ts/lib/function';
import { nullable } from '../../../../common/utils/nullable.utils';

const { RangePicker } = DatePicker;

export type DateRange<T = Date> = [T | null, T | null];

interface DateRangePickerProps {
	className?: string;
	value?: DateRange;
	onChange?: (val: DateRange | undefined) => void;
	inceptionDate?: Date;
	mobile?: boolean;
}

const minWidth = 240;

function toMomentRange(range?: DateRange): DateRange<Moment> {
	return range ? tuple(range[0] ? moment(range[0]) : null, range[1] ? moment(range[1]) : null) : [null, null];
}

const DateRangePickerMobile = memo<DateRangePickerProps>(({ value, onChange }) => {
	const [values, setValues] = useState<DateRange<Moment>>(() => toMomentRange(value));
	useEffect(() => {
		setValues(toMomentRange(value));
	}, [value]);

	const handleChangeFrom = useCallback(
		(date: Moment | null) => {
			setValues(values => {
				const newVal = tuple(date, values[1]);
				if (newVal.every(nullable.isDefined)) {
					onChange?.(newVal.map(m => m?.toDate()) as DateRange);
				}
				return newVal;
			});
		},
		[setValues, onChange],
	);

	const handleChangeTo = useCallback(
		(date: Moment | null) => {
			setValues(values => {
				const newVal = tuple(values[0], date);
				if (newVal.every(nullable.isDefined)) {
					onChange?.(newVal.map(m => m?.toDate()) as DateRange);
				}
				return newVal;
			});
		},
		[setValues, onChange],
	);

	const [valFrom, valTo] = values;
	const disabledFrom = useCallback((date: Moment) => !!valTo && date.isSameOrAfter(valTo), [valTo]);
	const disabledTo = useCallback((date: Moment) => !!valFrom && date.isSameOrBefore(valFrom), [valFrom]);

	return (
		<div className={css.mobileRange}>
			<DatePicker
				className={cn(css.mobilePicker, css.mobilePicker_from)}
				disabledDate={disabledFrom}
				allowClear={false}
				bordered={false}
				value={values[0]}
				onChange={handleChangeFrom}
				// placeholder={'Date from'}
				suffixIcon={null}
				showToday={false}
			/>
			<span className={css.sep}>to</span>
			<DatePicker
				className={css.mobilePicker}
				disabledDate={disabledTo}
				allowClear={false}
				bordered={false}
				value={values[1]}
				onChange={handleChangeTo}
				// placeholder={'Date to'}
				suffixIcon={null}
				showToday={false}
			/>
		</div>
	);
});

export const DateRangePickerInner = memo<DateRangePickerProps>(
	({ value, onChange, className, inceptionDate, mobile: isMobile = false }) => {
		// const isMobile = false; //!useBreakpoint().md;

		const ranges = useMemo(
			() =>
				({
					'1 month': [moment().subtract({ months: 1 }), moment()],
					YTD: [moment().startOf('year'), moment()],
					'12 months': [moment().subtract({ months: 12 }), moment()],
					...(inceptionDate
						? {
								'Since Inception': [moment(inceptionDate), moment()],
						  }
						: {}),
				} as Record<string, [Moment, Moment]>),
			[inceptionDate],
		);

		const antdValue = useMemo(() => value?.map(x => moment(x)) as DateRange<Moment>, [value]);
		const onAntdValueChange = useCallback(
			(val: DateRange<Moment> | null | undefined) =>
				onChange?.(val ? (val.map(x => x?.toDate()) as DateRange) : undefined),
			[onChange],
		);

		if (isMobile) {
			return <DateRangePickerMobile value={value} onChange={onChange} />;
		} else {
			return (
				<RangePicker
					ranges={ranges}
					separator={' to '}
					bordered={false}
					className={cn(css.picker, className)}
					value={antdValue}
					onChange={onAntdValueChange}
				/>
			);
		}
	},
);

export const DateRangePicker = memo<DateRangePickerProps>(props => {
	// const isMobile = useIsMobile();

	// if (isMobile) {
	return <DateRangePickerInner {...props} mobile />;
	// } else {
	// return (
	// <Autosizer disableHeight>
	// {({ width }) => <DateRangePickerInner {...props} mobile={width < minWidth} />}
	// </Autosizer>
	// );
	// }
});
