import { gql, useQuery } from '@apollo/client';
import { RemoteData } from '@devexperts/remote-data-ts';
import { Empty, Input, TablePaginationConfig, TableProps } from 'antd';
import { pipe } from 'fp-ts/lib/pipeable';
import { ChangeEvent, Fragment, memo, useCallback, useContext, useMemo, useState } from 'react';
import { GetProductPositions, GetProductPositionsVariables } from '../../../../generated-types/GetProductPositions';
import { ProductContext, useIsMobile } from '../../../utils/context.utils';
import { colorRed, colorGreen } from '../../../utils/color.utils';
import { remoteData, useRemoteData } from '../../../utils/remote-data.utils';
import { nullable, Nullable, optional } from '../../../../../common/utils/nullable.utils';
import { TableWidget, SortOrder } from '../../layout/TableWidget';
import { PositionCard } from '../../ui-kit/PositionCard';
import { Ord, ordNumber, ordString } from 'fp-ts/lib/Ord';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { SearchOutlined } from '@ant-design/icons';
import { array, ord } from 'fp-ts';
import css from './PositionsWidget.module.less';
import {
	ordStringCaseInsensitive,
	renderNumGrouped,
	ordStringLength,
	renderNumFixed2,
} from '../../../utils/string.utils';
import { toPercent } from '../../../utils/math.utils';
import { renderRemoteData } from '../../ui-kit/Loading';

export interface Position {
	instrument: Nullable<string>;
	productName: Nullable<string>;
	ticker: Nullable<string>;
	quantity: Nullable<number>;
	side: Nullable<string>;
	currency: Nullable<string>;
	openPrice: Nullable<number>;
	value: Nullable<number>;
	lastPrice: Nullable<number>;
	profitLoss: Nullable<number>;
	stopLossAlert: Nullable<string>;
	exposurePctOfNav: Nullable<number>;
	country: Nullable<string>;
	custodian: Nullable<string>;
}

interface PositionsWidgetProps {
	positions: RemoteData<Error, Position[]>;
	search: string;
	onChangeSearch: (val: string) => void;
}

const ordOptionalString = optional.getOrd(ordStringCaseInsensitive);
const ordOptionalNumber = optional.getOrd(ordNumber);

const columnConfigs: TableProps<Position>['columns'] = [
	{
		dataIndex: 'productName',
		key: 'productName',
		sorter: true, // ord.contramap((p: Position) => p.productName)(ordOptionalString).compare,
		title: 'Position',
		fixed: 'left',
		className: css.productCol,
		defaultSortOrder: 'ascend',
		// width: adjusted dynamically based on the values length, see below
	},
	{
		dataIndex: 'ticker',
		key: 'ticker',
		sorter: true, // ord.contramap((p: Position) => p.ticker)(ordOptionalString).compare,
		title: 'Ticker',
		width: 160,
	},
	{
		dataIndex: 'instrument',
		key: 'instrument',
		sorter: true, // ord.contramap((p: Position) => p.instrument)(ordOptionalString).compare,
		title: 'Instrument',
		width: 130,
	},
	{
		dataIndex: 'side',
		key: 'side',
		sorter: true, // ord.contramap((p: Position) => p.side)(ordOptionalString).compare,
		title: 'Side',
		width: 80,
	},
	{
		dataIndex: 'quantity',
		key: 'quantity',
		sorter: true, // ord.contramap((p: Position) => p.quantity)(ordOptionalNumber).compare,
		title: 'Quantity',
		align: 'right',
		width: 75,
		render: renderNumGrouped,
	},
	{
		dataIndex: 'currency',
		key: 'currency',
		sorter: true, //  ord.contramap((p: Position) => p.currency)(ordOptionalString).compare,
		title: 'Ccy',
		width: 80,
	},
	{
		dataIndex: 'openPrice',
		key: 'openPrice',
		sorter: true, // ord.contramap((p: Position) => p.openPrice)(ordOptionalNumber).compare,
		title: 'Open Price',
		align: 'right',
		width: 140,
		render: renderNumFixed2,
	},
	// {
	// 	dataIndex: 'value',
	// 	key: 'value',
	// 	sorter: true, // ord.contramap((p: Position) => p.value)(ordOptionalNumber).compare,
	// 	title: 'Value',
	// 	align: 'right',
	// 	width: 100,
	// 	render: renderNumGrouped,
	// },
	// {
	// 	dataIndex: 'lastPrice',
	// 	key: 'lastPrice',
	// 	sorter: true, // ord.contramap((p: Position) => p.lastPrice)(ordOptionalNumber).compare,
	// 	title: 'Last Price',
	// 	align: 'right',
	// 	width: 130,
	// 	render: renderNumGrouped,
	// },
	// {
	// 	dataIndex: 'profitLoss',
	// 	key: 'profitLoss',
	// 	sorter: true, // ord.contramap((p: Position) => p.profitLoss)(ordOptionalNumber).compare,
	// 	title: 'P&L',
	// 	align: 'right',
	// 	width: 90,
	// 	render: (val: Nullable<number>) => {
	// 		const color = val ? (val > 0 ? colorGreen : colorRed) : undefined;
	// 		return <span style={{ color }}>{renderNumGrouped(val)}</span>;
	// 	},
	// },
	{
		dataIndex: 'stopLossAlert',
		key: 'stopLossAlert',
		sorter: true, // ord.contramap((p: Position) => p.stopLossAlert)(ordOptionalString).compare,
		title: 'Alert',
		width: 130,
	},
	// {
	// 	dataIndex: 'exposurePctOfNav',
	// 	key: 'exposurePctOfNav',
	// 	sorter: true, // ord.contramap((p: Position) => p.exposurePctOfNav)(ordOptionalNumber).compare,
	// 	title: 'Exposure %',
	// 	align: 'right',
	// 	render: renderPercent,
	// 	width: 140,
	// },
	{
		dataIndex: 'country',
		key: 'country',
		sorter: true, // ord.contramap((p: Position) => p.country)(ordOptionalString).compare,
		title: 'Country',
		width: 115,
	},
	{
		dataIndex: 'custodian',
		key: 'custodian',
		sorter: true, // ord.contramap((p: Position) => p.custodian)(ordOptionalString).compare,
		title: 'Custodian',
		width: 130,
	},
];

const ords: Partial<Record<keyof Position, Ord<Position>>> = {
	instrument: ord.contramap((p: Position) => p.instrument)(nullable.getOrd(ordString)),
	productName: ord.contramap((p: Position) => p.productName)(nullable.getOrd(ordString)),
	ticker: ord.contramap((p: Position) => p.ticker)(nullable.getOrd(ordString)),
	quantity: ord.contramap((p: Position) => p.quantity)(nullable.getOrd(ordNumber)),
	side: ord.contramap((p: Position) => p.side)(nullable.getOrd(ordString)),
	currency: ord.contramap((p: Position) => p.currency)(nullable.getOrd(ordString)),
	openPrice: ord.contramap((p: Position) => p.openPrice)(nullable.getOrd(ordNumber)),
	value: ord.contramap((p: Position) => p.value)(nullable.getOrd(ordNumber)),
	lastPrice: ord.contramap((p: Position) => p.lastPrice)(nullable.getOrd(ordNumber)),
	profitLoss: ord.contramap((p: Position) => p.profitLoss)(nullable.getOrd(ordNumber)),
	stopLossAlert: ord.contramap((p: Position) => p.stopLossAlert)(nullable.getOrd(ordString)),
	exposurePctOfNav: ord.contramap((p: Position) => p.exposurePctOfNav)(nullable.getOrd(ordNumber)),
	country: ord.contramap((p: Position) => p.country)(nullable.getOrd(ordString)),
	custodian: ord.contramap((p: Position) => p.custodian)(nullable.getOrd(ordString)),
};

const ordPositionByLength = pipe(
	ordStringLength,
	ord.contramap((p: Position) => p.productName ?? ''),
);

export const PositionsWidget = memo<PositionsWidgetProps>(({ positions, search, onChangeSearch }) => {
	const handleSearchChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => onChangeSearch(e.target.value),
		[onChangeSearch],
	);
	const [sort, setSort] = useState<Nullable<SortOrder<Position>>>({
		sortBy: 'productName',
		order: 'ascend',
	});

	const handleChangeTable = useCallback(
		(
			pagination: TablePaginationConfig,
			filters: Record<string, FilterValue | null>,
			sorter: SorterResult<Position> | SorterResult<Position>[],
		) => {
			const sort = Array.isArray(sorter) ? sorter[0] : sorter;
			if (sort?.column && sort.order) {
				setSort({
					order: sort.order,
					sortBy: sort.column.dataIndex as keyof Position,
				});
			} else {
				setSort(null);
			}
		},
		[],
	);

	const sortOrd = useMemo(() => {
		if (sort) {
			const sorter = ords[sort.sortBy];
			if (sorter) {
				return sort.order === 'ascend' ? sorter : ord.getDualOrd(sorter);
			}
		}
		return null;
	}, [sort]);

	const sortedData = useMemo(
		() => (sortOrd ? pipe(positions, remoteData.map(array.sort(sortOrd))) : positions),
		[sortOrd, positions],
	);

	const searchInput = (
		<Input
			type="text"
			value={search}
			className={css.search}
			onChange={handleSearchChange}
			suffix={<SearchOutlined />}
		/>
	);

	const positionNameWidth = useMemo(
		() =>
			pipe(
				sortedData,
				remoteData.map(data => {
					const sizes = pipe(data, array.sort(ordPositionByLength));
					console.log(sizes);
					return sizes[Math.floor(sizes.length * 0.9)]?.productName?.length ?? 0;
				}),
				remoteData.getOrElse(() => 0),
				// constants to approximately measure the text width in pixels
				chars => `max(150px, min(${(chars / 23) * 210 + 30}px, 25vw))`,
			),
		[sortedData],
	);
	const columns = useMemo(
		() => columnConfigs.map(c => (c.key === 'productName' ? { ...c, width: positionNameWidth } : c)),
		[positionNameWidth],
	);

	const isMobile = useIsMobile();
	return isMobile ? (
		<Fragment>
			{searchInput}
			{renderRemoteData(positions, {
				success: positions =>
					positions.length ? (
						positions.map((p, i) => <PositionCard position={p} key={`card-${i}`} />)
					) : (
						<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
					),
			})}
		</Fragment>
	) : (
		<Fragment>
			{searchInput}
			{/* <div style={{ overflow: 'hidden', height: '100%', display: 'flex', flexDirection: 'column' }}> */}
			{/* <Widget> */}
			<TableWidget<Position>
				// this `getContainer` suppresses the virtual scrollbar
				// TODO: find out a better way
				sticky={{ getContainer: () => document.querySelector<HTMLElement>('.ant-table-body')! }}
				onChange={handleChangeTable}
				rowKey={(p, index) => `row-${index}`}
				columns={columns /*.map(x => ({ ...x, width: 150 }))*/}
				scroll={{ x: 'auto' }}
				dataSource={sortedData}
			/>
			{/* </Widget> */}
			{/* </div> */}
		</Fragment>
	);
});

const QUERY = gql`
	query GetProductPositions($productId: String!, $search: String) {
		positions(productId: $productId, search: $search) {
			instrument
			productName
			ticker
			quantity
			side
			currency
			openPrice
			value
			lastPrice
			profitLoss
			stopLossAlert
			exposurePctOfNav
			country
			custodian
		}
	}
`;

export const PositionsWidgetContainer = memo(() => {
	const { selectedProduct } = useContext(ProductContext);
	const [search, setSearch] = useState('');
	const { data, loading, error } = useQuery<GetProductPositions, GetProductPositionsVariables>(QUERY, {
		variables: { productId: selectedProduct?.id ?? '', search },
		skip: !selectedProduct,
	});

	const rd = pipe(
		useRemoteData(data, loading, error),
		remoteData.map(rd =>
			rd.positions.map(p => ({
				...p,
				exposurePctOfNav: pipe(p.exposurePctOfNav, nullable.map(toPercent)),
			})),
		),
	);
	return <PositionsWidget positions={rd} search={search} onChangeSearch={setSearch} />;
});
