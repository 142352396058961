import { gql, useQuery } from '@apollo/client';
import { empty } from 'fp-ts/lib/Array';
import { memo, useContext, useMemo, useState } from 'react';
import { ProductContext } from '../../../utils/context.utils';
import {
	GetProductTradingLast12Months,
	GetProductTradingLast12MonthsVariables,
} from '../../../../generated-types/GetProductTradingLast12Months';
import css from './TradingPrevMonthsWidget.module.less';
import { BarchartWidget } from '../barchart/BarchartWidget';
import { TimePeriodSelector } from '../../ui-kit/TimePeriodSelector';
import { format, parseISO } from 'date-fns';
import deepmerge from 'deepmerge';
import { WidgetTitle } from '../../ui-kit/WidgetTitle';
import { GlossaryHint } from '../../ui-kit/GlossaryHint';

type TradingInterval = '12m' | '3m' | '1m';

interface TradingBar {
	date: string;
	series: string;
	value: number;
}

interface TradingPrevMonthsWidgetProps {
	data: TradingBar[];
	interval: TradingInterval;
	onChangeInterval: (val: TradingInterval) => void;
}

const presets = ['12m' as const, '3m' as const, '1m' as const];

export const TradingPrevMonthsWidget = memo<TradingPrevMonthsWidgetProps>(({ data, interval, onChangeInterval }) => {
	const [series, categories] = useMemo(() => {
		const allDates = Array.from(new Set(data.map(p => p.date)).values()).sort();
		const tree = new Map<string, Map<string, number>>();
		data.forEach(point => {
			let s = tree.get(point.series);
			if (!s) {
				tree.set(point.series, (s = new Map()));
			}
			s.set(point.date, point.value);
		});
		const series = Array.from(tree.entries()).map(([key, value]) => ({
			name: key,
			data: allDates.map(date => value.get(date) ?? 0),
		}));
		const categories = formatCategories(allDates, interval);
		return [series, categories];
	}, [data, interval]);

	return (
		<BarchartWidget
			header={
				<WidgetTitle>
					Trading&nbsp;
					<GlossaryHint term="Trading (Chart)" />
				</WidgetTitle>
			}
			beforeChart={
				<div className={css.datepicker}>
					<TimePeriodSelector
						presets={presets}
						value={{ preset: interval }}
						onChange={val => onChangeInterval((val.preset ?? presets[0]) as TradingInterval)}
					/>
				</div>
			}
			categories={categories}
			series={series}
			adjustOptions={(opts, width) => {
				const barWidth = (width - 100) / categories.length;
				return deepmerge(opts, {
					xaxis: {
						overwriteCategories:
							barWidth < 20 ? categories.map((c, i, a) => ((a.length - i) % 2 ? c : '')) : null,
					},
				});
			}}
			chartProps={{
				options: {
					annotations: {
						yaxis: [{ y: 0 }],
					},
					xaxis: {
						labels: {
							hideOverlappingLabels: false,
						},
					},
					tooltip: {
						shared: true,
						intersect: false,
					},
				},
			}}
		/>
	);
});

const QUERY = gql`
	query GetProductTradingLast12Months($product: String!, $interval: String!) {
		products(ids: [$product]) {
			id
			generationDate
			tradingLastYear(interval: $interval) {
				series
				date
				value
			}
		}
	}
`;

export const TradingPrevMonthsWidgetContainer = () => {
	const [interval, onChangeInterval] = useState<TradingInterval>('12m');
	const { selectedProduct } = useContext(ProductContext);
	const { data } = useQuery<GetProductTradingLast12Months, GetProductTradingLast12MonthsVariables>(QUERY, {
		variables: { product: selectedProduct?.id ?? '', interval },
		skip: !selectedProduct,
	});

	return (
		<TradingPrevMonthsWidget
			interval={interval}
			onChangeInterval={onChangeInterval}
			data={data?.products[0]?.tradingLastYear ?? empty}
		/>
	);
};

function formatCategories(allDates: string[], interval: '12m' | '3m' | '1m'): string[] {
	const dates = allDates.map(d => parseISO(d));
	switch (interval) {
		case '1m':
		case '3m':
			return dates.map(x => format(x, 'MMM d'));
		case '12m':
			return dates.map(x => format(x, "MMM ''yy"));
	}
}
