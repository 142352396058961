import { gql, useQuery } from '@apollo/client';
import { useContext } from 'react';
import { GetContacts } from '../../generated-types/GetContacts';
import { ProductContext } from './context.utils';
import { useRemoteData } from './remote-data.utils';

const QUERY = gql`
	query GetContacts($client: String!) {
		contacts(client: $client) {
			firstName
			lastName
			phoneNumber
			company
			role
			email
		}
	}
`;

export const useContactsData = () => {
	const { selectedClient } = useContext(ProductContext);
	const ress = useQuery<GetContacts>(QUERY, {
		variables: { client: selectedClient },
		skip: !selectedClient,
	});
	const { data, loading, error } = ress;

	return useRemoteData(data?.contacts ?? undefined, loading, error);
};
