import { gql, useQuery } from '@apollo/client';
import { Radio } from 'antd';
import deepmerge from 'deepmerge';
import { option } from 'fp-ts';
import { Option } from 'fp-ts/lib/Option';
import { pipe } from 'fp-ts/lib/pipeable';
import { Fragment, memo, useContext, useMemo, useState } from 'react';
import {
	GetProductExposureBreakdown,
	GetProductExposureBreakdown_products_exposureByAssetClass,
} from '../../../../generated-types/GetProductExposureBreakdown';
import { ProductContext } from '../../../utils/context.utils';
import { toPercent } from '../../../utils/math.utils';
import { remoteData, useRemoteData } from '../../../utils/remote-data.utils';
import { renderPercent } from '../../../utils/string.utils';
import { GlossaryHint } from '../../ui-kit/GlossaryHint';
import { WidgetTitle } from '../../ui-kit/WidgetTitle';
import { BarchartWidget } from '../barchart/BarchartWidget';

export type ExposureValues = {
	short: Option<number>;
	long: Option<number>;
	gross: Option<number>;
	net: Option<number>;
};

const chartProps = {
	options: {
		chart: {
			stacked: false,
			animations: {
				enabled: false,
			},
		},
		yaxis: {
			labels: {
				formatter: (val: number) => renderPercent(val),
			},
		},
	},
};

const categories = ['Gross', 'Net', 'Long', 'Short'];

type Type = 'By Asset Class' | 'By Sector' | 'By Region';

interface ExposureBreakdownWidgetContainerProps {
	// type: Type;
}

const QUERY = gql`
	query GetProductExposureBreakdown($product: String!) {
		products(ids: [$product]) {
			id
			generationDate
			exposureByRegion {
				...exposureFields
			}
			exposureBySector {
				...exposureFields
			}
			exposureByAssetClass {
				...exposureFields
			}
		}
	}

	fragment exposureFields on ExposureBreakdownItem {
		groupName
		grossExposurePctOfNav
		netExposurePctOfNav
		longExposurePctOfNav
		shortExposurePctOfNav
	}
`;

export const ExposureBreakdownWidgetContainer = memo<ExposureBreakdownWidgetContainerProps>(({}) => {
	const { selectedProduct } = useContext(ProductContext);
	const { data, loading, error } = useQuery<GetProductExposureBreakdown>(QUERY, {
		variables: { product: selectedProduct?.id ?? '' },
		skip: !selectedProduct,
	});

	const [type, setType] = useState<Type>('By Asset Class');
	const dataRaw = useRemoteData(data, loading, error);
	const dataRD = useMemo(
		() =>
			pipe(
				dataRaw,
				remoteData.map(
					data =>
						({
							'By Asset Class': mapExposures(data.products[0]?.exposureByAssetClass ?? []),
							'By Region': mapExposures(data.products[0]?.exposureByRegion ?? []),
							'By Sector': mapExposures(data.products[0]?.exposureBySector ?? []),
						}[type]),
				),
				remoteData.map(series => ({ series, categories })),
			),
		[dataRaw, type],
	);

	const chartOptions = useMemo(
		() =>
			pipe(
				dataRD,
				remoteData.map(data => data.series.length),
				remoteData.getOrElse(() => 5),
				numCategories =>
					deepmerge(chartProps, {
						options: {
							annotations: {
								yaxis: [{ y: 0 }],
							},
							plotOptions: {
								bar: {
									columnWidth: `${Math.min(numCategories, 9) * 10}%`,
								},
							},
						},
					}),
			),
		[dataRD],
	);

	const settings = useMemo(
		() => (
			<div>
				<Radio.Group
					options={['By Asset Class', 'By Sector', 'By Region']}
					value={type}
					optionType="button"
					onChange={e => setType(e.target.value)}
				/>
			</div>
		),
		[type, setType],
	);

	return (
		<BarchartWidget
			beforeChart={settings}
			header={
				<WidgetTitle>
					{'Exposure ' + type.replace('By', 'by')}&nbsp;
					<GlossaryHint term={/Exposure by Asset Class/i} />
				</WidgetTitle>
			}
			data={dataRD}
			chartProps={chartOptions}
		/>
	);
});

function mapExposure(val: GetProductExposureBreakdown_products_exposureByAssetClass) {
	return [
		pipe(
			option.fromNullable(val.grossExposurePctOfNav),
			option.fold(() => 0, toPercent),
		),
		pipe(
			option.fromNullable(val.netExposurePctOfNav),
			option.fold(() => 0, toPercent),
		),
		pipe(
			option.fromNullable(val.longExposurePctOfNav),
			option.fold(() => 0, toPercent),
		),
		pipe(
			option.fromNullable(val.shortExposurePctOfNav),
			option.fold(() => 0, toPercent),
		),
	];
}

function mapExposures(val: GetProductExposureBreakdown_products_exposureByAssetClass[]) {
	return val.map(val => ({
		name: val.groupName,
		data: mapExposure(val),
	}));
}
