import { pipe } from 'fp-ts/lib/pipeable';
import { CSSProperties, memo, useMemo } from 'react';
import { useGlossaryData } from '../../utils/glossary.utils';
import { remoteData } from '../../utils/remote-data.utils';
import { optional } from '../../../../common/utils/nullable.utils';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import css from './GlossaryHint.module.less';
import cn from 'classnames';

export interface GlossaryHintProps {
	term: string | RegExp;
	style?: CSSProperties;
	className?: string;
}

export const GlossaryHint = memo<GlossaryHintProps>(({ term, style, className }) => {
	const data = pipe(useGlossaryData(), remoteData.toUndefined);
	const item = useMemo(() => {
		const actualTerm = typeof term === 'string' ? term.trim().toLowerCase() : term;
		return pipe(
			data,
			optional.map(items =>
				items.find(item =>
					typeof actualTerm === 'string'
						? item.term.trim().toLowerCase() === actualTerm
						: actualTerm.test(item.term),
				),
			),
		);
	}, [data, term]);
	return item ? (
		<Tooltip overlay={item.definition}>
			<InfoCircleOutlined className={cn(css.icon, className)} style={style} />
		</Tooltip>
	) : null;
});
