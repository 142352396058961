import { memo } from 'react';
import { HexagonSvg } from './Hexagon';
import css from './Legend.module.less';
import cn from 'classnames';

export interface LegendItem {
	color: string;
	title: string;
	visible?: boolean;
}

interface LegendProps {
	items: Array<LegendItem>;
}

export const Legend = memo<LegendProps>(({ items }) => {
	return (
		<div>
			{items.map(item => (
				<span key={item.title} className={cn(css.item, item.visible === false && css.item_hidden)}>
					<HexagonSvg size={24} fill={item.color} className={css.icon} shadow />
					{item.title}
				</span>
			))}
		</div>
	);
});
