import { Skeleton } from 'antd';
import React, { memo } from 'react';
import { RemoteData, isPending } from '@devexperts/remote-data-ts';
import * as remoteData from '@devexperts/remote-data-ts';
import cashSvg from '../../../icons/cash.svg';
import calendarSvg from '../../../icons/calendar.svg';
import bookmarkSvg from '../../../icons/bookmark.svg';
import { pipe } from 'fp-ts/pipeable';
import css from './ProductDetailsWidget.module.less';
import { Option } from 'fp-ts/lib/Option';
import { option } from 'fp-ts';
import { renderOption } from '../../../utils/string.utils';
import { HexagonIcon } from '../../ui-kit/Hexagon';
import { useIsDarkTheme } from '../../../utils/context.utils';
import { renderRemoteData } from '../../ui-kit/Loading';
import { flow } from 'fp-ts/lib/function';
import { GlossaryHint } from '../../ui-kit/GlossaryHint';

export type ProductDetails = {
	currencyCode: Option<string>;
	inceptionDate: Option<string>;
	productType: Option<string>;
};

interface ProductDetailsWidgetProps {
	product: RemoteData<Error, ProductDetails>;
}

export const ProductDetailsWidget = memo<ProductDetailsWidgetProps>(({ product }) => {
	const isDark = useIsDarkTheme();
	const iconFill = isDark ? '#464646' : '#fff';
	const renderItem = (icon: string, title: string, helpKey: string | RegExp, key: keyof ProductDetails) => (
		<div className={css.item}>
			<HexagonIcon size={40} fill={iconFill} className={css.icon}>
				<img src={icon} />
			</HexagonIcon>
			<div className={css.value}>
				<div className={css.label}>
					{title}&nbsp;
					<GlossaryHint term={helpKey} />
				</div>
				{renderRemoteData(product, {
					inline: true,
					success: flow(
						product => product[key],
						renderOption,
						x => <span className={css.figure}>{x}</span>,
					),
				})}
			</div>
		</div>
	);

	return (
		<div className={css.container}>
			{renderItem(cashSvg, 'Reporting Currency', 'Reporting Currency', 'currencyCode')}
			{renderItem(calendarSvg, 'Inception Date', 'Inception Date', 'inceptionDate')}
			{renderItem(bookmarkSvg, 'Product Type', 'Product Type', 'productType')}
		</div>
	);
});
