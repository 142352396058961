import React, { Fragment, memo, useState } from 'react';
import { useIsMobile } from '../../utils/context.utils';
import { ResponsivePage } from '../layout/ResponsivePage';
import { WidgetList } from '../layout/WidgetList';
import { CommissionsWidgetContainer } from '../widgets/commissions-widget/CommissionsWidget';
import { EventsWidgetContainer } from '../widgets/events/EventsWidget';
import { TradesBreakdownBarchartWidgetContainer } from '../widgets/trades-breakdown-barchart/TradesBreakdownBarchartWidget';
import { TradesByBrokerTraderWidgetContainer } from '../widgets/trades-by-broker-trader/TradesByBrokerTraderWidget';
import { TradesDistributionWidgetContainer } from '../widgets/trades-distribution/TradesDistributionWidget';
import { TradesWidgetContainer } from '../widgets/trades/TradesWidget';
import { TradingMetricsWidgetContainer } from '../widgets/trading-metrics/TradingMetricsWidget';

interface TradingPageProps {
	onLayoutClassChange: (c: string) => void;
}

export const TradingPage = memo<TradingPageProps>(({ onLayoutClassChange }) => {
	return <ResponsivePage onLayoutClassChange={onLayoutClassChange} pageName="Trading" page={TradingPageContents} />;
});

export const TradingPageContents = memo(() => {
	const [showTrades, setShowTrades] = useState(false);

	const isMobile = useIsMobile();

	return (
		<Fragment>
			<WidgetList>
				{/* <TradesWidgetContainer onClose={() => setShowTrades(false)} visible={showTrades} /> */}
				<CommissionsWidgetContainer />
				<EventsWidgetContainer />
				{/* <TradingMetricsWidgetContainer onShowTrades={() => setShowTrades(true)} /> */}
				{!isMobile && <TradesWidgetContainer />}
				<TradesByBrokerTraderWidgetContainer />
				{/* <TradesBreakdownBarchartWidgetContainer type="Trades per Broker" /> */}
				{/* <TradesDistributionWidgetContainer type="Traders - Volume" /> */}
				<TradesBreakdownBarchartWidgetContainer type="Trades per Instrument" />
				<TradesDistributionWidgetContainer type="Execution Venue" />
				{isMobile && <TradesWidgetContainer cards />}
			</WidgetList>
		</Fragment>
	);
});
