import { gql, useQuery } from '@apollo/client';
import { Select, SelectProps } from 'antd';
import { memo, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ClientOption, ProductContext } from '../../utils/context.utils';
import { GetProducts, GetProducts_products } from '../../../generated-types/GetProducts';
import moment from 'moment';
import css from './FundSelector.module.less';
import { pipe } from 'fp-ts/lib/pipeable';
import { array, eq } from 'fp-ts';
import { eqString } from 'fp-ts/lib/Eq';
import cn from 'classnames';
import { remoteData, useRemoteData } from '../../utils/remote-data.utils';
import { constEmpty } from '../../utils/data.utils';
import { RemoteData } from '@devexperts/remote-data-ts';
import { renderRemoteData } from './Loading';
import { optional } from '../../../../common/utils/nullable.utils';

type FundSelectorProps = Omit<SelectProps<string>, 'options'> & {
	options: RemoteData<Error, Array<{ label: string; value: string }>>;
};

export const FundSelector = memo<FundSelectorProps>(({ options, className, ...rest }) => {
	return renderRemoteData(options, {
		success: options => (
			<Select
				className={cn(css.select, className)}
				bordered={false}
				defaultValue={options[0]?.value}
				options={options}
				{...rest}
			/>
		),
		inline: true,
	});
});

export const FundSelectorContainer = (props: { className?: string }) => {
	const { productOptions, selectedProduct, onSelectProduct } = useContext(ProductContext);
	const selectOpts = useMemo(
		() => pipe(productOptions, remoteData.map(array.map(p => ({ value: p.id, label: p.name })))),
		[productOptions],
	);
	return <FundSelector options={selectOpts} value={selectedProduct?.id} onChange={onSelectProduct} {...props} />;
};

export const ClientSelectorContainer = memo<Omit<SelectProps<string>, 'value' | 'onChange' | 'options'>>(props => {
	const { clientOptions, selectedClient, onSelectClient } = useContext(ProductContext);
	const selectOpts = useMemo(
		() =>
			pipe(
				clientOptions,
				array.map(value => ({ value: value.code, label: value.shortName })),
			),
		[clientOptions],
	);
	const isDisabled = selectOpts.length <= 1;
	return (
		<Select
			{...props}
			className={cn(props.className, css.client)}
			dropdownClassName={css.clientDropdown}
			dropdownAlign={{ offset: [0, -4] }}
			disabled={isDisabled}
			options={selectOpts}
			value={selectedClient}
			onChange={onSelectClient}
		/>
	);
});

const storageKey = 'HorusEye.fund';
const storeSelection = (val: ProductSelection | undefined) => {
	val ? localStorage.setItem(storageKey, val.id) : localStorage.removeItem(storageKey);
};

const eqClientOption = pipe(
	eqString,
	eq.contramap((opt: ClientOption) => opt.code),
);

const QUERY = gql`
	query GetProducts {
		products {
			id
			generationDate
			name
			belongsToClient
			clientShortName
			currency
			inception
			effectiveDate
			type
			demo
		}
	}
`;

type ProductSelection = Omit<GetProducts_products, 'currency' | 'inception' | 'type' | 'demo'> & {
	currency?: string;
	type?: string;
	inception?: Date;
	demo?: boolean;
};
export const ProductContextProvider = memo<PropsWithChildren<{}>>(({ children }) => {
	const fundFromLocalStorage = useMemo(() => localStorage.getItem(storageKey), []);
	const [selection, setSelection] = useState<ProductSelection | undefined>();
	const { data: dataRaw, loading, error } = useQuery<GetProducts>(QUERY);
	const dataRD = useRemoteData(dataRaw, loading, error);

	const productsRD = useMemo(
		() =>
			pipe(
				dataRD,
				remoteData.map(dataRaw =>
					dataRaw.products.map(d => ({
						...d,
						currency: d.currency ?? undefined,
						inception:
							pipe(
								d.inception,
								optional.map(d => moment(d).toDate()),
							) ?? undefined,
						effectiveDate:
							pipe(
								d.effectiveDate,
								optional.map(d => moment(d).toDate()),
							) ?? undefined,
						type: d.type ?? undefined,
						demo: d.demo ?? undefined,
					})),
				),
			),
		[dataRD],
	);
	const products = useMemo(() => pipe(productsRD, remoteData.getOrElse(constEmpty())), [productsRD]);

	useEffect(() => {
		if (products) {
			setSelection(curr => {
				const preselectedFund = curr?.id ?? fundFromLocalStorage;
				if (preselectedFund) {
					const fund = products.find(p => p.id === preselectedFund);
					if (fund) {
						return fund;
					}
				}
				return (curr && products.find(p => p.belongsToClient === curr.belongsToClient)) ?? products[0];
			});
		}
	}, [products, fundFromLocalStorage]);

	const setSelectedClient = useCallback(
		(client: string) => {
			if (products) {
				const clientProducts = products.filter(p => p.belongsToClient === client);
				setSelection(curr => {
					const newVal = (curr && clientProducts.find(p => p.id === curr.id)) ?? clientProducts[0];
					storeSelection(newVal);
					return newVal;
				});
			}
		},
		[products],
	);

	const setSelectedProduct = useCallback(
		(id: string) => {
			if (products) {
				const product = products.find(p => p.id === id);
				setSelection(product);
				storeSelection(product);
			}
		},
		[products],
	);

	const clientOptions = useMemo(
		() =>
			pipe(
				products ?? [],
				array.map(p => ({ code: p.belongsToClient, shortName: p.clientShortName })),
				array.uniq(eqClientOption),
			),
		[products],
	);

	const selectedClient = selection?.belongsToClient;
	const productOptions = useMemo(
		() => pipe(productsRD, remoteData.map(array.filter(p => p.belongsToClient === selectedClient))),
		[selectedClient, productsRD],
	);

	return (
		<ProductContext.Provider
			value={{
				clientOptions,
				productOptions,
				selectedProduct: selection,
				selectedClient,
				onSelectProduct: setSelectedProduct,
				onSelectClient: setSelectedClient,
			}}
		>
			{children}
		</ProductContext.Provider>
	);
});
