import { gql, useQuery } from '@apollo/client';
import { failure, pending, RemoteData, success } from '@devexperts/remote-data-ts';
import { Col } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { array, option, ord } from 'fp-ts';
import { Option } from 'fp-ts/lib/Option';
import { pipe } from 'fp-ts/lib/pipeable';
import { memo, useContext } from 'react';
import {
	GetProductShareClassPerformance,
	GetProductShareClassPerformanceVariables,
} from '../../../../generated-types/GetProductShareClassPerformance';
import { ProductContext } from '../../../utils/context.utils';
import { ordOptionNumber } from '../../../utils/math.utils';
import { remoteData } from '../../../utils/remote-data.utils';
import { renderOptionPercent } from '../../../utils/string.utils';
import { TableWidget } from '../../layout/TableWidget';
import { GlossaryHint } from '../../ui-kit/GlossaryHint';
import { WidgetTitle } from '../../ui-kit/WidgetTitle';

export type PerformanceSummary = {
	groupName: string;
	dayChange: Option<number>;
	mtd: Option<number>;
	qtd: Option<number>;
	ytd: Option<number>;
	sinceInception: Option<number>;
};

interface ShareClassPerformanceWidgetProps {
	data: RemoteData<Error, PerformanceSummary[]>;
}

const columnConfigs: ColumnsType<PerformanceSummary> = [
	{
		title: 'Share Class',
		dataIndex: 'groupName',
		key: 'groupName',
		fixed: 'left',
		width: 'max(110px, min(200px, 25vw))',
		render: val => <span style={{ width: 'max(110px, min(200px, 25vw))', whiteSpace: 'normal' }}>{val}</span>,
	},
	{
		title: 'Day Change',
		dataIndex: 'dayChange',
		key: 'dayChange',
		align: 'right',
		render: renderOptionPercent,
		sorter: ord.contramap((p: PerformanceSummary) => p.dayChange)(ordOptionNumber).compare,
	},
	{
		title: 'MTD',
		dataIndex: 'mtd',
		key: 'mtd',
		align: 'right',
		render: renderOptionPercent,
		sorter: ord.contramap((p: PerformanceSummary) => p.mtd)(ordOptionNumber).compare,
	},
	{
		title: 'QTD',
		dataIndex: 'qtd',
		key: 'qtd',
		align: 'right',
		render: renderOptionPercent,
		sorter: ord.contramap((p: PerformanceSummary) => p.qtd)(ordOptionNumber).compare,
	},
	{
		title: 'YTD',
		dataIndex: 'ytd',
		key: 'ytd',
		align: 'right',
		render: renderOptionPercent,
		sorter: ord.contramap((p: PerformanceSummary) => p.ytd)(ordOptionNumber).compare,
	},
	{
		title: 'Since Inception',
		dataIndex: 'sinceInception',
		key: 'sinceInception',
		align: 'right',
		render: renderOptionPercent,
		sorter: ord.contramap((p: PerformanceSummary) => p.sinceInception)(ordOptionNumber).compare,
	},
];

export const ShareClassPerformanceWidget = memo<ShareClassPerformanceWidgetProps>(({ data }) => {
	return (
		<Col xs={24} lg={12}>
			<TableWidget<PerformanceSummary>
				columns={columnConfigs}
				header={
					<WidgetTitle>
						Share Class Performance&nbsp;
						<GlossaryHint term="Share Class Performance (Table)" />
					</WidgetTitle>
				}
				rowKey={row => row.groupName}
				dataSource={pipe(
					data,
					remoteData.getOrElse(() => [] as PerformanceSummary[]),
				)}
			/>
		</Col>
	);
});

const QUERY = gql`
	query GetProductShareClassPerformance($product: String!) {
		products(ids: [$product]) {
			id
			generationDate
			performance {
				summary {
					shareClass
					mtd
					qtd
					ytd
					dayChange
					sinceInception
				}
			}
		}
	}
`;

export const ShareClassPerformanceWidgetContainer = () => {
	const { selectedProduct } = useContext(ProductContext);
	const { data, error, loading } = useQuery<
		GetProductShareClassPerformance,
		GetProductShareClassPerformanceVariables
	>(QUERY, { variables: { product: selectedProduct?.id ?? '' }, skip: !selectedProduct });

	const summaries = pipe(
		data?.products[0]?.performance?.summary,
		data => (loading ? pending : data ? success(data) : failure(error ?? new Error())),
		remoteData.map(
			array.map(item => ({
				groupName: item.shareClass,
				dayChange: pipe(
					option.fromNullable(item.dayChange),
					option.map(x => x * 100),
				),
				mtd: pipe(
					option.fromNullable(item.mtd),
					option.map(x => x * 100),
				),
				qtd: pipe(
					option.fromNullable(item.qtd),
					option.map(x => x * 100),
				),
				ytd: pipe(
					option.fromNullable(item.ytd),
					option.map(x => x * 100),
				),
				sinceInception: pipe(
					option.fromNullable(item.sinceInception),
					option.map(x => x * 100),
				),
			})),
		),
	);

	return <ShareClassPerformanceWidget data={summaries} />;
};
