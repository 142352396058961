import React, { memo } from 'react';
import { ResponsivePage } from '../layout/ResponsivePage';
import { PositionsWidgetContainer } from '../widgets/positions/PositionsWidget';

interface PositionsPageProps {
	onLayoutClassChange: (c: string) => void;
}

export const PositionsPage = memo<PositionsPageProps>(({ onLayoutClassChange }) => {
	return (
		<ResponsivePage
			onLayoutClassChange={onLayoutClassChange}
			pageName="Positions"
			page={PositionsWidgetContainer}
		/>
	);
});
