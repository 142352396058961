import { useIsDarkTheme } from './context.utils';

export const chartColors = [
	'#A18F69',
	'#6A6A6A',
	'#FFC35F',
	'#464646',
	'#E1B469',
	'#232323',
	'#D18F1F',
	'#876D35',
	'#A9A7A2',
];

export const darkColors = [
	'#A18F69',
	'#8D8D8D',
	'#FFC35F',
	'#CBCBCB',
	'#E1B469',
	'#F1F1F1',
	'#D18F1F',
	'#876D35',
	'#A9A7A2',
];

export const useColors = () => (useIsDarkTheme() ? darkColors : chartColors);

export const colorGray = '#A9A7A2';
export const colorGray2 = '#464646';
export const colorGreen = '#4EBD4C';
export const colorRed = '#D64444';

export const progressColors = ['rgb(199,145,59)', 'rgb(210,168,102)', 'rgb(231,208,173)'];
