import { memo, MouseEventHandler, PropsWithChildren, SVGAttributes, useMemo } from 'react';
import css from './Hexagon.module.less';
import cn from 'classnames';
import { v4 as uuid } from 'uuid';

type HexagonSvgProps = Omit<SVGAttributes<SVGElement>, 'onClick'> & {
	size?: number;
	fill?: string;
	shadow?: boolean;
	onClick?: MouseEventHandler<HTMLDivElement>;
};

export const HexagonSvg = memo<HexagonSvgProps>(({ size, fill = '#F4F5FA', shadow, onClick, ...props }) => {
	const id = useMemo(() => (shadow ? uuid() : ''), [shadow]);
	return (
		<svg
			viewBox={shadow ? '-25 -22 100 88' : '0 0 50 44'}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			{...props}
		>
			{shadow && (
				<filter id={id} height="200%" width="200%">
					<feOffset result="offOut" in="SourceGraphic" dx="10" dy="0"></feOffset>
					<feColorMatrix
						result="matrixOut"
						in="offOut"
						type="matrix"
						values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 0.4 0"
					></feColorMatrix>
					<feGaussianBlur result="blurOut" in="matrixOut" stdDeviation="10"></feGaussianBlur>
					<feBlend in="SourceGraphic" in2="blurOut" mode="normal"></feBlend>
				</filter>
			)}
			<path
				d="M50 22L37.5 43.6506H12.5L0 22L12.5 0.349365H37.5L50 22Z"
				fill={fill}
				style={shadow ? { filter: `url(#${id})` } : undefined}
			/>
		</svg>
	);
});

export const HexagonIcon = memo<PropsWithChildren<HexagonSvgProps>>(
	({ children, className, style, onClick, ...props }) => {
		return (
			<div
				className={cn(css.wrap, className)}
				onClick={onClick}
				style={{ ...style, width: props.size ?? props.width, height: props.size ?? props.height }}
			>
				<HexagonSvg {...props} />
				<div className={css.children}>{children}</div>
			</div>
		);
	},
);
