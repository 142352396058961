import { gql, useQuery } from '@apollo/client';
import { array, ord } from 'fp-ts';
import { pipe } from 'fp-ts/lib/function';
import { useMemo } from 'react';
import { GetGlossary } from '../../generated-types/GetGlossary';
import { remoteData, useRemoteData } from './remote-data.utils';
import { ordStringCaseInsensitive } from './string.utils';

const ordByTerm = ord.contramap((item: { term: string }) => item.term)(ordStringCaseInsensitive);

const QUERY = gql`
	query GetGlossary {
		glossary {
			term
			definition
		}
	}
`;

export const useGlossaryData = () => {
	const { data, loading, error } = useQuery<GetGlossary>(QUERY);

	const dataRaw = useRemoteData(data, loading, error);
	return useMemo(
		() =>
			pipe(
				dataRaw,
				remoteData.map(data =>
					pipe(
						data.glossary,
						array.sort(ordByTerm),
						array.map(item => ({ ...item, search: `${item.term} ${item.definition}`.toLowerCase() })),
					),
				),
			),
		[dataRaw],
	);
};
