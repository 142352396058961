import { Typography } from 'antd';
import React, { Fragment, memo, useCallback, useContext, useState } from 'react';
import { ProductContext, useIsMobile } from '../../../utils/context.utils';
import { MobileFundsCarousel } from '../../layout/MobileFundsCarousel';
import { TitleWithAutoFundSelector } from '../../ui-kit/TitleWithFundSelector';
import { ReportsWidgetContainer } from '../../widgets/reports-table/ReportsTableWidget';
import css from './ReportsPage.module.less';
import { array as tArray, boolean, string, type, TypeOf, undefined as tUndefined, union } from 'io-ts';
import { TimePeriodIO } from '../../../utils/date.utils';
import { Endomorphism, pipe } from 'fp-ts/lib/function';
import { either } from 'fp-ts';
import { ReportsFilter } from './ReportsFilter';

const ReportFilterIO = type({
	collapsed: boolean,
	search: string,
	products: tArray(string),
	dateRange: union([TimePeriodIO, tUndefined]),
});

export type ReportFilter = TypeOf<typeof ReportFilterIO>;

const emptyState: ReportFilter = {
	collapsed: true,
	search: '',
	products: [],
	dateRange: undefined,
};

const storageKey = 'HorusEye.ReportsFilter.value';

const loadInitialFilter = () =>
	pipe(
		localStorage.getItem(storageKey) ?? 'null',
		JSON.parse,
		ReportFilterIO.decode,
		either.getOrElse(() => emptyState),
	);

const storeFilter = (val: ReportFilter) => localStorage.setItem(storageKey, JSON.stringify(ReportFilterIO.encode(val)));

export const ReportsPage = memo(() => {
	const isMobile = useIsMobile();
	const productContext = useContext(ProductContext);
	const [filter, setFilter] = useState(loadInitialFilter);
	const handleFilterChange = useCallback(
		(upd: Endomorphism<ReportFilter>) =>
			setFilter(old => {
				const newVal = upd(old);
				storeFilter(newVal);
				return newVal;
			}),
		[],
	);

	return isMobile ? (
		<Fragment>
			<Typography.Title level={1}>Reports</Typography.Title>
			<ReportsFilter value={filter} onChange={handleFilterChange} />
			<ReportsWidgetContainer clientLevel />
			<div className={css.mobileFunds}>
				<MobileFundsCarousel
					renderPanel={product => (
						<ProductContext.Provider value={{ ...productContext, selectedProduct: product }}>
							<ReportsWidgetContainer />
						</ProductContext.Provider>
					)}
				/>
			</div>
		</Fragment>
	) : (
		<Fragment>
			<Typography.Title level={1}>Company Reports</Typography.Title>
			<ReportsFilter value={filter} onChange={handleFilterChange} />
			<ReportsWidgetContainer clientLevel filter={filter} />
			<div className={css.desktopFundReports}>
				<TitleWithAutoFundSelector noEffectiveDate>Fund Reports</TitleWithAutoFundSelector>
			</div>
			<ReportsWidgetContainer filter={filter} />
		</Fragment>
	);
});
