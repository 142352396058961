import { RemoteData } from '@devexperts/remote-data-ts';
import { Option } from 'fp-ts/lib/Option';
import React, { Fragment, memo, useContext, useMemo } from 'react';
import { MetricBarCard, MetricCard } from '../../ui-kit/MetricCard';
import { MetricsWidget } from '../metrics/MetricsWidget';
import day1Svg from '../../../icons/day1.svg';
import day20Svg from '../../../icons/day20.svg';
import cashSvg from '../../../icons/cash.svg';
import gaugeSvg from '../../../icons/gauge.svg';
import sigmaSvg from '../../../icons/sigma.svg';
import sigmaUpSvg from '../../../icons/sigma-up.svg';
import funnelSvg from '../../../icons/funnel.svg';
import { pipe } from 'fp-ts/lib/function';
import { remoteData, useRemoteData } from '../../../utils/remote-data.utils';
import { option, array } from 'fp-ts';
import { NA, renderOptionPercent, renderPercent } from '../../../utils/string.utils';
import css from './RiskMetricsWidget.module.less';
import { Col, Row } from 'antd';
import { ProductContext, useIsMobile } from '../../../utils/context.utils';
import { gql, useQuery } from '@apollo/client';
import { GetProductRiskMetrics } from '../../../../generated-types/GetProductRiskMetrics';
import { toPercent } from '../../../utils/math.utils';
import { GlossaryHint } from '../../ui-kit/GlossaryHint';
import { empty } from 'fp-ts/lib/Array';
import AutoSizer from 'react-virtualized-auto-sizer';

interface RiskMetrics {
	dv01: Option<number>;
	cs01: Option<number>;
	vega: Option<number>;
	// stopLoss1: Option<number>;
	// stopLoss2: Option<number>;
	sharpe12m: Option<number>;
	sortino12m: Option<number>;
	std12m: Option<number>;
	upsideStd12m: Option<number>;
	risk1Day: Option<number>;
	risk20Days: Option<number>;
	unencumberedCash: Option<number>;
	ifrsLevels: Option<number[]>;
}

interface RiskMetricsProps {
	value: RemoteData<Error, RiskMetrics>;
	width: number;
}

const metricTheme = {
	card: css.card,
};

export const RiskMetricsWidget = memo<RiskMetricsProps>(({ value, width }) => {
	const ifrsTitle = pipe(
		value,
		remoteData.toOption,
		option.chain(v => v.ifrsLevels),
		option.fold(
			() => 'IFRS Levels',
			v => 'IFRS Level ' + v.map((v, i) => i + 1).join(' / '),
		),
	);
	const isMobile = useIsMobile();
	const multiplier = isMobile ? 6 / 2.25 : width >= 1100 ? 1 : 2;
	const slot = 100 / 6;

	return (
		<Row gutter={[0, 20]} style={{ width }}>
			<Col xs={24}>
				<MetricsWidget theme={css}>
					<Col
						flex={`0 0 ${slot * multiplier}%`}
						className={css.col}
						style={{ width: `${slot * multiplier}%` }}
					>
						<MetricCard
							theme={metricTheme}
							icon={<img src={day1Svg} />}
							title={
								<Fragment>
									1 Day Value at Risk&nbsp;
									<GlossaryHint term={/1 day value at risk/i} />
								</Fragment>
							}
							value={pipe(
								value,
								remoteData.toOption,
								option.chain(p => p.risk1Day),
								renderOptionPercent,
							)}
						/>
					</Col>
					<Col
						flex={`0 0 ${slot * multiplier}%`}
						className={css.col}
						style={{ width: `${slot * multiplier}%` }}
					>
						<MetricCard
							theme={metricTheme}
							icon={<img src={day20Svg} />}
							title={
								<Fragment>
									20 Days Value at Risk&nbsp;
									<GlossaryHint term={/20 days? value at risk/i} />
								</Fragment>
							}
							value={pipe(
								value,
								remoteData.toOption,
								option.chain(p => p.risk20Days),
								renderOptionPercent,
							)}
						/>
					</Col>
					<Col
						flex={`0 0 ${slot * multiplier}%`}
						className={css.col}
						style={{ width: `${slot * multiplier}%` }}
					>
						<MetricCard
							theme={metricTheme}
							icon={<img src={gaugeSvg} />}
							title={
								<Fragment>
									Sharpe Ratio (12M)&nbsp;
									<GlossaryHint term={'Sharpe Ratio'} />
								</Fragment>
							}
							value={pipe(
								value,
								remoteData.toOption,
								option.chain(p => p.sharpe12m),
								renderOptionPercent,
							)}
						/>
					</Col>
					<Col
						flex={`0 0 ${slot * multiplier}%`}
						className={css.col}
						style={{ width: `${slot * multiplier}%` }}
					>
						<MetricCard
							theme={metricTheme}
							icon={<img src={gaugeSvg} />}
							title={
								<Fragment>
									Sortino Ratio (12M)&nbsp;
									<GlossaryHint term={'Sortino Ratio'} />
								</Fragment>
							}
							value={pipe(
								value,
								remoteData.toOption,
								option.chain(p => p.sortino12m),
								renderOptionPercent,
							)}
						/>
					</Col>
					<Col
						flex={`0 0 ${slot * multiplier}%`}
						className={css.col}
						style={{ width: `${slot * multiplier}%` }}
					>
						<MetricCard
							theme={metricTheme}
							icon={<img src={gaugeSvg} />}
							title={
								<Fragment>
									DV01 Risk&nbsp;
									<GlossaryHint term={'DV01'} />
								</Fragment>
							}
							value={pipe(
								value,
								remoteData.toOption,
								option.chain(p => p.dv01),
								renderOptionPercent,
							)}
						/>
					</Col>
					<Col
						flex={`0 0 ${slot * multiplier}%`}
						className={css.col}
						style={{ width: `${slot * multiplier}%` }}
					>
						<MetricCard
							theme={metricTheme}
							icon={<img src={gaugeSvg} />}
							title={
								<Fragment>
									CS01 Risk&nbsp;
									<GlossaryHint term={'CS01'} />
								</Fragment>
							}
							value={pipe(
								value,
								remoteData.toOption,
								option.chain(p => p.cs01),
								renderOptionPercent,
							)}
						/>
					</Col>
				</MetricsWidget>
			</Col>
			<Col xs={24}>
				<MetricsWidget theme={css}>
					<Col
						flex={`0 0 ${slot * multiplier}%`}
						className={css.col}
						style={{ width: `${slot * multiplier}%` }}
					>
						<MetricCard
							theme={metricTheme}
							icon={<img src={sigmaSvg} />}
							title="Std Deviation (12M)"
							value={pipe(
								value,
								remoteData.toOption,
								option.chain(p => p.std12m),
								renderOptionPercent,
							)}
						/>
					</Col>
					<Col
						flex={`0 0 ${slot * multiplier}%`}
						className={css.col}
						style={{ width: `${slot * multiplier}%` }}
					>
						<MetricCard
							theme={metricTheme}
							icon={<img src={sigmaUpSvg} />}
							title={
								<Fragment>
									Upside Deviation (12M)&nbsp;
									<GlossaryHint term="Upside Deviation" />
								</Fragment>
							}
							value={pipe(
								value,
								remoteData.toOption,
								option.chain(p => p.upsideStd12m),
								renderOptionPercent,
							)}
						/>
					</Col>
					<Col
						flex={`0 0 ${slot * multiplier}%`}
						className={css.col}
						style={{ width: `${slot * multiplier}%` }}
					>
						<MetricCard
							theme={metricTheme}
							icon={<img src={cashSvg} />}
							title={
								<Fragment>
									Unencumbered cash&nbsp;
									<GlossaryHint term="Unencumbered cash" />
								</Fragment>
							}
							value={
								NA /*pipe(	// TODO: temporary fix for wrong values from JSON
								value,
								remoteData.toOption,
								option.chain(p => p.unencumberedCash),
								renderOptionPercent,
						)*/
							}
						/>
					</Col>
					<Col
						flex={`0 0 ${(isMobile ? 2 : 3) * slot * multiplier}%`}
						className={css.col}
						style={{ width: `${(isMobile ? 2 : 3) * slot * multiplier}%` }}
					>
						<MetricBarCard
							theme={metricTheme}
							icon={<img src={funnelSvg} />}
							title={
								<Fragment>
									{ifrsTitle}&nbsp;
									<GlossaryHint term={'IFRS Levels'} />
								</Fragment>
							}
							value={pipe(
								value,
								remoteData.toOption,
								option.chain(p => p.ifrsLevels),
								option.fold(
									() => empty,
									array.mapWithIndex((i, val) => ({
										value: toPercent(val),
										tooltip: `Level ${i + 1}: ${renderPercent(toPercent(val))}`,
									})),
								),
							)}
						/>
					</Col>
				</MetricsWidget>
			</Col>
		</Row>
	);
});

const QUERY = gql`
	query GetProductRiskMetrics($product: String!) {
		products(ids: [$product]) {
			id
			generationDate
			unencumberedCash
			risk1Day
			risk20Days
			cs01
			dv01
			vega
			sortino12m
			sharpe12m
			std12m
			upsideStd12m
			ifrsReport {
				label
				value
			}
		}
	}
`;

export const RiskMetricsWidgetContainer = memo(() => {
	const { selectedProduct } = useContext(ProductContext);
	const { data, loading, error } = useQuery<GetProductRiskMetrics>(QUERY, {
		variables: { product: selectedProduct?.id ?? '' },
		skip: !selectedProduct,
	});

	const dataRaw = useRemoteData(data, loading, error);
	const value = useMemo(
		() =>
			pipe(
				dataRaw,
				remoteData.map(data => {
					const ifrsLevels = pipe(
						option.fromNullable(data.products[0]?.ifrsReport),
						option.map(levels => {
							const result = [];
							for (let i = 1; ; i++) {
								const level = levels.find(l => l.label.toLowerCase() === `level ${i}`);
								if (level) {
									result.push(level.value);
								} else {
									break;
								}
							}
							return result;
						}),
					);
					return {
						cs01: pipe(option.fromNullable(data.products[0]?.cs01), option.map(toPercent)),
						dv01: pipe(option.fromNullable(data.products[0]?.dv01), option.map(toPercent)),
						vega: pipe(option.fromNullable(data.products[0]?.vega), option.map(toPercent)),
						sharpe12m: pipe(option.fromNullable(data.products[0]?.sharpe12m), option.map(toPercent)),
						sortino12m: pipe(option.fromNullable(data.products[0]?.sortino12m), option.map(toPercent)),
						std12m: pipe(option.fromNullable(data.products[0]?.std12m), option.map(toPercent)),
						upsideStd12m: pipe(option.fromNullable(data.products[0]?.upsideStd12m), option.map(toPercent)),
						risk1Day: pipe(option.fromNullable(data.products[0]?.risk1Day), option.map(toPercent)),
						risk20Days: pipe(option.fromNullable(data.products[0]?.risk20Days), option.map(toPercent)),
						unencumberedCash: pipe(
							option.fromNullable(data.products[0]?.unencumberedCash),
							option.map(toPercent),
						),
						ifrsLevels,
					};
				}),
			),
		[dataRaw],
	);

	return (
		<Col xs={24}>
			<div>
				<AutoSizer disableHeight>{({ width }) => <RiskMetricsWidget value={value} width={width} />}</AutoSizer>
			</div>
		</Col>
	);
});
