import { useEffect, useState } from 'react';
import { AuthService, AuthToken } from './services/auth.service';
import { App } from './App';
import { failure, pending, RemoteData, success } from '@devexperts/remote-data-ts';
import { useDIValue } from './utils/container.utils';

export const AppContainer = () => {
	const authService = useDIValue<AuthService>('auth');
	const [token, setToken] = useState(pending as RemoteData<Error, AuthToken>);
	useEffect(() => {
		authService.getToken().then(
			t => {
				console.log('success', t);
				setToken(success(t));
			},
			e => {
				console.log('error', e);
				setToken(failure(e instanceof Error ? e : new Error(e)));
			},
		);
	}, [authService]);

	return <App token={token} />;
};
