import { parseISO } from 'date-fns';
import { pipe } from 'fp-ts/lib/pipeable';
import { memo, useMemo, useState } from 'react';
import { useCurrentDate } from '../../../utils/context.utils';
import { periodToFilter, TimePeriod } from '../../../utils/date.utils';
import { remoteData } from '../../../utils/remote-data.utils';
import { useTradesData } from '../../../utils/trades.utils';
import { GlossaryHint } from '../../ui-kit/GlossaryHint';
import { TimePeriodSelector } from '../../ui-kit/TimePeriodSelector';
import { WidgetTitle } from '../../ui-kit/WidgetTitle';
import { BarchartWidget } from '../barchart/BarchartWidget';

interface TradesBreakdownBarchartWidgetContainerProps {
	type: 'Trades per Broker' | 'Trades per Instrument';
}

export const TradesBreakdownBarchartWidgetContainer = memo<TradesBreakdownBarchartWidgetContainerProps>(({ type }) => {
	const [timePeriod, setTimePeriod] = useState<TimePeriod>({ preset: 'ytd' });
	const now = useCurrentDate();
	const dataRaw = useTradesData();
	const data = useMemo(
		() =>
			pipe(
				dataRaw,
				remoteData.map(data => {
					const key = type === 'Trades per Broker' ? 'brokerGroup' : 'instrument';
					const result = new Map<string, number>();
					const dateFilter = periodToFilter(timePeriod, now);
					data.forEach(d => {
						if (dateFilter(parseISO(d.tradeDate))) {
							const category = d[key] ?? 'Unknown';
							result.set(category, (result.get(category) ?? 0) + 1);
						}
					});
					const categories = Array.from(result.keys());
					const series = [
						{
							name: type,
							data: categories.map(c => result.get(c) ?? 0),
						},
					];
					return { categories, series };
				}),
			),
		[dataRaw, timePeriod, type, now],
	);

	return (
		<BarchartWidget
			header={
				<WidgetTitle>
					{type}&nbsp;
					<GlossaryHint term="Trades per Instrument (Chart)" />
				</WidgetTitle>
			}
			data={data}
			chartProps={{
				options: {
					annotations: {
						yaxis: [{ y: 0 }],
					},
				},
			}}
			beforeChart={
				<TimePeriodSelector
					value={timePeriod}
					onChange={setTimePeriod}
					presets={['yesterday', 'wtd', 'mtd', 'ytd']}
				/>
			}
		/>
	);
});
