import { Button, Layout } from 'antd';
import logoImg from '../../../images/logo.svg';
import css from './Header.module.less';
import { useState } from 'react';
import burgerSvg from '../../../icons/burger.svg';
import { SidebarContainer } from './Sidebar';

export const Header = () => {
	const [sidebarVisible, setSidebarVisible] = useState(false);
	return (
		<Layout.Header className={css.header}>
			<SidebarContainer visible={sidebarVisible} onClose={() => setSidebarVisible(false)} />
			<img className={css.logo} src={logoImg} alt="Quay Partners logo" />
			<Button className={css.menuBtn} type="text" onClick={() => setSidebarVisible(true)}>
				<img src={burgerSvg} />
			</Button>
		</Layout.Header>
	);
};
