import { gql, useQuery } from '@apollo/client';
import { RemoteData } from '@devexperts/remote-data-ts';
import { Col } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { parseISO } from 'date-fns';
import { option, ord } from 'fp-ts';
import { flow, pipe } from 'fp-ts/lib/function';
import { some, none, Option } from 'fp-ts/lib/Option';
import { memo, useContext, useMemo, useState } from 'react';
import { GetCommissionsByBroker } from '../../../../generated-types/GetCommissionsByBroker';
import { ProductContext, useCurrentDate } from '../../../utils/context.utils';
import { periodToFilter, TimePeriod } from '../../../utils/date.utils';
import { ordOptionNumber } from '../../../utils/math.utils';
import { remoteData, useRemoteData } from '../../../utils/remote-data.utils';
import {
	MDASH,
	ordStringCaseInsensitive,
	renderNumGrouped,
	renderOption,
	renderOptionMoney,
} from '../../../utils/string.utils';
import { useTradesData } from '../../../utils/trades.utils';
import { TableWidget } from '../../layout/TableWidget';
import { GlossaryHint } from '../../ui-kit/GlossaryHint';
import { TimePeriodSelector } from '../../ui-kit/TimePeriodSelector';
import { WidgetTitle } from '../../ui-kit/WidgetTitle';

export type CommissionsRow = {
	groupName: string;
	numTrades: Option<number>;
	totalCommissions: Option<number>;
};

interface CommissionsWidgetProps {
	data: RemoteData<Error, CommissionsRow[]>;
	timePeriod: TimePeriod;
	onTimePeriodChange: (val: TimePeriod) => void;
}

const columnConfigs: ColumnsType<CommissionsRow> = [
	{
		title: 'Broker',
		dataIndex: 'groupName',
		key: 'groupName',
		sorter: ord.contramap((p: CommissionsRow) => p.groupName)(ordStringCaseInsensitive).compare,
		width: 100,
	},
	{
		title: 'Trades',
		dataIndex: 'numTrades',
		key: 'numTrades',
		align: 'right',
		render: flow(option.map(renderNumGrouped), renderOption),
		sorter: ord.contramap((p: CommissionsRow) => p.numTrades)(ordOptionNumber).compare,
		width: 100,
	},
	{
		title: 'Total Comms',
		dataIndex: 'totalCommissions',
		key: 'totalCommissions',
		render: renderOptionMoney,
		align: 'right',
		sorter: ord.contramap((p: CommissionsRow) => p.totalCommissions)(ordOptionNumber).compare,
		width: 140,
	},
];

export const CommissionsWidget = memo<CommissionsWidgetProps>(({ data, onTimePeriodChange, timePeriod }) => {
	const header = (
		<WidgetTitle
			addon={
				<TimePeriodSelector value={timePeriod} onChange={onTimePeriodChange} presets={['wtd', 'mtd', 'ytd']} />
			}
		>
			Commissions&nbsp;
			<GlossaryHint term={/Commiss?ions/i} />
		</WidgetTitle>
	);

	return (
		<Col xs={24} xl={12} xxl={6}>
			<TableWidget<CommissionsRow>
				style={{ maxHeight: '310px', overflow: 'auto' }}
				columns={columnConfigs}
				header={header}
				rowKey={row => row.groupName}
				dataSource={data}
			/>
		</Col>
	);
});

const QUERY = gql`
	query GetCommissionsByBroker($product: String!) {
		products(ids: [$product]) {
			id
			generationDate
			brokerCommissionsWTD {
				label
				value
			}
			brokerCommissionsMTD {
				label
				value
			}
			brokerCommissionsYTD {
				label
				value
			}
		}
	}
`;

export const CommissionsWidgetContainer = () => {
	const [timePeriod, setTimePeriod] = useState<TimePeriod>({ preset: 'ytd' });

	const { selectedProduct } = useContext(ProductContext);
	const { data, loading, error } = useQuery<GetCommissionsByBroker>(QUERY, {
		variables: { product: selectedProduct?.id ?? '' },
		skip: !selectedProduct,
	});
	const commissionsRD = useRemoteData(data, loading, error);
	const now = useCurrentDate();

	const trades = useTradesData();
	const dataFromTrades = useMemo(
		() =>
			pipe(
				remoteData.combine(trades, commissionsRD),
				remoteData.map(([trades, commissions]) => {
					const filter = periodToFilter(timePeriod, now);
					const result = new Map<string, CommissionsRow>();
					trades.forEach(trade => {
						if (filter(parseISO(trade.tradeDate))) {
							const broker = trade.brokerGroup ?? MDASH;
							const comm = result.get(broker) ?? {
								groupName: broker,
								numTrades: some(0),
								totalCommissions: none,
							};
							comm.numTrades = pipe(
								comm.numTrades,
								option.alt(() => some(1)),
								option.map(x => x + 1),
							);
							result.set(broker, comm);
						}
					});
					const commKey =
						timePeriod.preset &&
						(`brokerCommissions${timePeriod.preset.toUpperCase()}` as `brokerCommissions${
							| 'WTD'
							| 'YTD'
							| 'MTD'}`);
					const comms = (commKey && commissions.products?.[0]?.[commKey]) ?? [];
					comms.forEach(c => {
						const comm = result.get(c.label) ?? {
							groupName: c.label,
							numTrades: none,
							totalCommissions: none,
						};
						comm.totalCommissions = some(c.value);
						result.set(c.label, comm);
					});
					return Array.from(result.values());
				}),
			),
		[trades, timePeriod, commissionsRD, now],
	);

	return <CommissionsWidget timePeriod={timePeriod} onTimePeriodChange={setTimePeriod} data={dataFromTrades} />;
};
