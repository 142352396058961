import { Button, Layout, Menu, MenuItemProps } from 'antd';
import logoImg from '../../images/logo.svg';
import { ReactComponent as LogoutImg } from '../../icons/logout.svg';
import { ReactComponent as DashboardImg } from '../../icons/dashboard.svg';
import { ReactComponent as CompassImg } from '../../icons/compass.svg';
import { ReactComponent as WarningImg } from '../../icons/warning.svg';
import { ReactComponent as CandlesImg } from '../../icons/candles.svg';
import { ReactComponent as ReportImg } from '../../icons/report.svg';
import { ReactComponent as GlossaryImg } from '../../icons/glossary.svg';
import { ReactComponent as ContactsImg } from '../../icons/contacts.svg';
import css from './Sidebar.module.less';
import { useHistory, useLocation } from 'react-router';
import { useDIValue } from '../../utils/container.utils';
import { AuthService } from '../../services/auth.service';
import { ClientSelectorContainer } from '../ui-kit/FundSelector';
import { ThemeSwitcher } from './ThemeSwitcher';
import { PrimaryContactWidgetContainer } from '../widgets/primary-contact-info/PrimaryContactWidget';

const logoutIcon = <LogoutImg />;

const RouteMenuItem = (props: MenuItemProps & { route: string }) => {
	const { route, ...rest } = props;
	const history = useHistory();
	return <Menu.Item {...rest} key={route} onClick={() => history.push(route)} />;
};

interface SidebarProps {
	onLogout: () => void;
}

export const Sidebar = ({ onLogout }: SidebarProps) => {
	const { pathname } = useLocation();

	return (
		<Layout.Sider className={css.sider} width={250}>
			<div className={css.logo}>
				<img src={logoImg} alt="Quay Partners logo" />
			</div>
			<ClientSelectorContainer />
			<Menu mode="inline" theme="dark" className={css.menu} selectedKeys={[pathname]}>
				<RouteMenuItem key={'/'} route={'/'}>
					<DashboardImg className={css.itemIcon} />
					Dashboard
				</RouteMenuItem>
				<RouteMenuItem key={'/positions'} route={'/positions'}>
					<CompassImg className={css.itemIcon} />
					Positions
				</RouteMenuItem>
				<RouteMenuItem key={'/trading'} route={'/trading'}>
					<CandlesImg className={css.itemIcon} />
					Trading
				</RouteMenuItem>
				<RouteMenuItem key={'/risk'} route={'/risk'}>
					<WarningImg className={css.itemIcon} />
					Risk
				</RouteMenuItem>
				<RouteMenuItem key={'/reports'} route={'/reports'}>
					<ReportImg className={css.itemIcon} />
					Reports
				</RouteMenuItem>
				<RouteMenuItem key={'/contacts'} route={'/contacts'}>
					<ContactsImg className={css.itemIcon} />
					Contacts
				</RouteMenuItem>
				<RouteMenuItem key={'/glossary'} route={'/glossary'}>
					<GlossaryImg className={css.itemIcon} />
					Glossary
				</RouteMenuItem>
			</Menu>
			<ThemeSwitcher />
			<div className={css.spacer} />
			<div className={css.contact}>
				<PrimaryContactWidgetContainer />
			</div>
			<div className={css.logoutPanel}>
				<Button className={css.logoutBtn} onClick={onLogout} type="text" icon={logoutIcon}>
					Logout
				</Button>
			</div>
		</Layout.Sider>
	);
};

export const SidebarContainer = () => {
	const auth = useDIValue<AuthService>('auth');
	return <Sidebar onLogout={auth.logout} />;
};
