import { memo, PropsWithChildren } from 'react';
import css from './MobileContextMenu.module.less';
import cn from 'classnames';

interface MobileContextMenuProps {
	onClose?: () => void;
	visible?: boolean;
}

export const MobileContextMenu = memo<PropsWithChildren<MobileContextMenuProps>>(
	({ children, visible = false, onClose }) => {
		return (
			<div className={cn(css.container, visible && css.container_visible)} onClick={onClose}>
				<div className={css.content}>{children}</div>
			</div>
		);
	},
);
