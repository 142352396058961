import {
	endOfDay,
	isWithinInterval,
	startOfDay,
	startOfMonth,
	startOfQuarter,
	startOfWeek,
	startOfYear,
	subDays,
	subMonths,
} from 'date-fns';
import { constTrue, Predicate } from 'fp-ts/lib/function';
import { literal, partial, tuple, Type, union } from 'io-ts';
import { DateFromISOString } from 'io-ts-types';
import { ArrayType } from './data.utils';

export const preset = [
	'12m' as const,
	'3m' as const,
	'1m' as const,
	'ytd' as const,
	'qtd' as const,
	'mtd' as const,
	'wtd' as const,
	'yesterday' as const,
	'all' as const,
];
export type Preset = ArrayType<typeof preset>;
export function isPreset(val: string): val is Preset {
	return preset.includes(val as Preset);
}

export function presetToDateRange(preset: Preset, now: Date): [Date, Date] | undefined {
	switch (preset) {
		case '12m':
			return [subMonths(now, 12), now];
		case '1m':
			return [subMonths(now, 1), now];
		case '3m':
			return [subMonths(now, 3), now];
		case 'mtd':
			return [startOfMonth(now), now];
		case 'qtd':
			return [startOfQuarter(now), now];
		case 'wtd':
			return [startOfWeek(now), now];
		case 'ytd':
			return [startOfYear(now), now];
		case 'yesterday':
			const ytd = subDays(now, 1);
			return [startOfDay(ytd), endOfDay(ytd)];
		case 'all':
			return undefined;
	}
}

export type TimePeriod = {
	preset?: Preset;
	range?: [Date, Date];
};

export function periodToDateRange(period: TimePeriod, now: Date): [Date, Date] | undefined {
	return period.preset ? presetToDateRange(period.preset, now) : period.range;
}

export function periodToFilter(period: TimePeriod, now: Date): Predicate<Date> {
	const range = periodToDateRange(period, now);
	if (!range) return constTrue;
	const interval = { start: range[0], end: range[1] };
	return (date: Date | number) => isWithinInterval(date, interval);
}

export const TimePeriodIO: Type<TimePeriod, unknown> = partial({
	preset: union([
		literal('12m'),
		literal('3m'),
		literal('1m'),
		literal('ytd'),
		literal('qtd'),
		literal('mtd'),
		literal('wtd'),
		literal('yesterday'),
		literal('all'),
	]),
	range: tuple([DateFromISOString, DateFromISOString]),
});
