import { gql, useQuery } from '@apollo/client';
import { Card, Col, Radio } from 'antd';
import deepmerge from 'deepmerge';
import { Fragment, memo, useContext, useEffect, useMemo, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { baseChartSettings, baseChartSettingsDark, chartClassName } from '../../../utils/chart.utils';
import { ProductContext, useIsDarkTheme } from '../../../utils/context.utils';
import { Widget } from '../../layout/Widget';
import {
	GetProductWeeklyPerformance,
	GetProductWeeklyPerformanceVariables,
} from '../../../../generated-types/GetProductWeeklyPerformance';
import moment from 'moment';
import { Legend } from '../../ui-kit/Legend';
import css from './PerformanceWidget.module.less';
import { isNonEmpty } from 'fp-ts/lib/ReadonlyArray';
import { last } from 'fp-ts/lib/ReadonlyNonEmptyArray';
import { ordString } from 'fp-ts/lib/Ord';
import cn from 'classnames';
import { v4 as uuid } from 'uuid';
import Autosizer from 'react-virtualized-auto-sizer';
import { failure, pending, RemoteData, success } from '@devexperts/remote-data-ts';
import { remoteData } from '../../../utils/remote-data.utils';
import { pipe } from 'fp-ts/lib/function';
import { ErrorCover, LoadingCover } from '../../ui-kit/Loading';
import { allPresetsExcept, TimePeriodSelector } from '../../ui-kit/TimePeriodSelector';
import { TimePeriod } from '../../../utils/date.utils';
import { WidgetTitle } from '../../ui-kit/WidgetTitle';
import { GlossaryHint } from '../../ui-kit/GlossaryHint';
import { THINSP } from '../../../utils/string.utils';
import type { ElementOf } from 'antd/lib/_util/type';

interface PerformanceSeries {
	name: string;
	data: Array<[string, number]>;
	isBenchmark: boolean;
}

interface PerformanceWidgetProps {
	series: RemoteData<Error, PerformanceSeries[]>;
	dateRange: TimePeriod;
	inceptionDate?: Date;
	primaryShareClass?: string;
	onDateRangeChange?: (val: TimePeriod) => void;
}

const presets = allPresetsExcept('yesterday', 'wtd');

export const PerformanceWidget = memo<PerformanceWidgetProps>(
	({ series: allSeries, dateRange, onDateRangeChange, inceptionDate, primaryShareClass }) => {
		const id = useMemo(() => uuid(), []);
		const [onlyPrimary, setOnlyPrimary] = useState(true);
		const chartSettings = useIsDarkTheme() ? baseChartSettingsDark : baseChartSettings;
		const chartData = useMemo(
			() =>
				pipe(
					allSeries,
					remoteData.map(allSeries => {
						type PerformanceSeries = ElementOf<ApexAxisChartSeries> & {
							visible: boolean;
							isBenchmark: boolean;
						};
						const series: Array<PerformanceSeries> = allSeries.map((s, i) => ({
							...s,
							data: s.data.map(d => [new Date(d[0]).getTime(), d[1]]),
							visible: !onlyPrimary || s.name === primaryShareClass || s.isBenchmark,
							color: chartSettings.colors[i],
						}));
						const legendItems = series.map((s, i) => ({
							title: s.name ?? '',
							color: s.color ?? '#ccc',
							visible: s.visible,
						}));
						const visibleSeries = series.filter(s => s.visible);
						return { series, legendItems, visibleSeries };
					}),
				),
			[allSeries, onlyPrimary, primaryShareClass, chartSettings],
		);

		return (
			<Col xs={24} lg={12}>
				<Widget
					stretch
					header={
						<WidgetTitle>
							Performance&nbsp;
							<GlossaryHint term="Peformance (Chart)" />
						</WidgetTitle>
					}
				>
					<Card bordered={false} className={css.card}>
						<Autosizer disableHeight>
							{({ width }) => {
								console.log(width);
								return (
									<div
										className={cn(css.controls, width < 550 && css.controls_narrow)}
										style={{ width }}
									>
										<div>
											<Radio.Group
												options={['Primary', 'All classes']}
												value={onlyPrimary ? 'Primary' : 'All classes'}
												optionType="button"
												onChange={e => setOnlyPrimary(e.target.value === 'Primary')}
											/>
										</div>
										<div />
										<div>
											{/*<Select
												options={['Since inception', '12m', 'YTD', '3m', 'QTD', '1m'].map(
													value => ({ value, label: value }),
												)}
												// value={onlyPrimary ? 'Primary' : 'All classes'}
												// optionType="button"
												// onChange={e => setOnlyPrimary(e.target.value === 'Primary')}
												/>*/}
											{/*<DateRangePicker
												value={dateRange}
												onChange={onDateRangeChange}
												inceptionDate={inceptionDate}
											/>*/}
											<TimePeriodSelector
												presets={presets}
												value={dateRange}
												onChange={onDateRangeChange}
											/>
										</div>
									</div>
								);
							}}
						</Autosizer>

						{pipe(
							chartData,
							remoteData.fold3(
								() => <LoadingCover />,
								() => <ErrorCover />,
								({ legendItems, series, visibleSeries }) => {
									const colors = visibleSeries.map(s => s.color ?? '#ccc');
									return (
										<Fragment>
											<div className={cn(css.chartArea, chartClassName)}>
												<ReactApexChart
													type="line"
													width="100%"
													height="100%"
													options={deepmerge(chartSettings, {
														annotations: {
															yaxis: [{ y: 100 }],
														},
														colors,
														stroke: {
															width: 2,
															dashArray: visibleSeries.map(s =>
																s.isBenchmark ? '12 9' : undefined,
															) as any /* bad typings */,
														},
														tooltip: {
															y: {
																formatter: (pct: number) =>
																	`${pct.toFixed(2)}${THINSP}%`,
															},
														},
														yaxis: {
															labels: {
																formatter: (pct: number) => String(Math.round(pct)),
															},
														},
														legend: { show: false },
														chart: {
															id,
															dropShadow: {
																enabled: true,
																top: 5,
																blur: 15,
																opacity: 0.25,
																color: colors as any, // bad typings in apexcharts
															},
														},
														xaxis: {
															type: 'datetime' as const,
														},
													})}
													series={visibleSeries}
												/>
											</div>
											<Legend items={legendItems} />
										</Fragment>
									);
								},
							),
						)}
					</Card>
				</Widget>
			</Col>
		);
	},
);

type QueryParams = {
	product: string;
	dateRange?: TimePeriod;
};

const QUERY = gql`
	query GetProductWeeklyPerformance($product: String!, $dateFrom: LocalDate, $dateTo: LocalDate) {
		products(ids: [$product]) {
			id
			generationDate
			inception
			primaryShareClass
			performance {
				points(dateFrom: $dateFrom, dateTo: $dateTo) {
					series
					date
					value
				}
			}
		}
	}
`;

export const PerformanceWidgetContainer = () => {
	const [dateRange, setDateRange] = useState<TimePeriod>({ preset: 'all' });
	const [params, setParams] = useState<QueryParams | undefined>();

	const skipRequest = !params;
	const { data, loading, error } = useQuery<GetProductWeeklyPerformance, GetProductWeeklyPerformanceVariables>(
		QUERY,
		{
			variables: {
				product: params?.product ?? '',
				dateFrom: formatDate(params?.dateRange?.range?.[0]),
				dateTo: formatDate(params?.dateRange?.range?.[1]),
			},
			skip: skipRequest,
		},
	);

	const { selectedProduct } = useContext(ProductContext);
	const selectedProductId = selectedProduct?.id;
	useEffect(() => {
		setParams(selectedProductId ? { product: selectedProductId } : undefined);
	}, [selectedProductId]);

	useEffect(() => {
		const points = data?.products[0]?.performance?.points;
		if (points && isNonEmpty(points)) {
			setDateRange(range => ({ ...range, range: [points[0].date, last(points).date] }));
		}
	}, [data]);

	const allPoints =
		skipRequest || loading
			? pending
			: data
			? success(data.products[0]?.performance?.points ?? [])
			: failure(error ?? new Error());
	const seriesArr = pipe(
		allPoints,
		remoteData.map(allPoints => {
			const series = new Map<string, Array<[string, number]>>();
			allPoints.forEach(p => {
				let s = series.get(p.series);
				if (!s) {
					series.set(p.series, (s = []));
				}
				s.push([p.date, p.value]);
			});
			// normalize:
			series.forEach(ser => {
				const base = ser[0][1];
				ser.forEach(p => (p[1] = (p[1] / base) * 100));
			});
			const seriesArr = Array.from(series.entries())
				.map(([name, data]) => ({
					name,
					data,
					isBenchmark: /index/i.test(name),
				}))
				.sort((a, b) =>
					a.isBenchmark === b.isBenchmark ? ordString.compare(a.name, b.name) : a.isBenchmark ? 1 : -1,
				);
			return seriesArr;
		}),
	);

	return (
		<PerformanceWidget
			series={seriesArr}
			dateRange={dateRange}
			inceptionDate={data?.products[0]?.inception}
			primaryShareClass={data?.products[0]?.primaryShareClass ?? undefined}
			onDateRangeChange={dateRange => {
				setDateRange(dateRange);
				setParams(params =>
					params
						? {
								...params,
								dateRange,
						  }
						: undefined,
				);
			}}
		/>
	);
};

function formatDate(date?: Date | null) {
	return date ? moment(date).format('YYYY-MM-DD') : undefined;
}
