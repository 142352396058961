import { Card, Col, Row, Skeleton, Tooltip, Typography } from 'antd';
import React, { Fragment, memo, ReactNode, useMemo } from 'react';
import { Widget } from '../../layout/Widget';
import { RemoteData } from '@devexperts/remote-data-ts';
import * as remoteData from '@devexperts/remote-data-ts';
import { pipe } from 'fp-ts/pipeable';
import { MetricCard, MetricCardMobile, MetricCardTheme } from '../../ui-kit/MetricCard';
import { MetricCard2 } from '../../ui-kit/MetricCard2';
import navPerShareSvg from '../../../icons/nav-per-share.svg';
import dailyChangeSvg from '../../../icons/daily-change.svg';
import moneySvg from '../../../icons/money.svg';
import arrowSvg from '../../../icons/arrow.svg';
import pieSvg from '../../../icons/pie.svg';
import cogSvg from '../../../icons/cog.svg';
import plusMinusSvg from '../../../icons/plus-minus.svg';
import clockSvg from '../../../icons/24.svg';
import css from './FundOverviewWidget.module.less';
import { Option } from 'fp-ts/lib/Option';
import {
	renderMoney,
	renderOption,
	renderOptionMoney,
	renderOptionPercent,
	renderPercent,
} from '../../../utils/string.utils';
import { array, option } from 'fp-ts';
import cn from 'classnames';
import { MD, useBreakpoint, useIsDarkTheme } from '../../../utils/context.utils';
import { ProductDetailsWidgetContainer } from '../product-details/ProductDetailsWidgetContainer';
import deepmerge from 'deepmerge';
import { MetricsWidgetMobile } from '../metrics/MetricsWidget';
import { constNull, tuple } from 'fp-ts/lib/function';
import { InfoCircleOutlined } from '@ant-design/icons';
import { GlossaryHint } from '../../ui-kit/GlossaryHint';

export type ProductDetails = { code: string; name: string; currencyCode: string; productType: string };

export type ProductOverview = {
	product: ProductDetails;
	nav: Option<number>;
	dailyChange: Option<number>;
	aum: Option<number>;
	grossExposure: Option<number>;
	netExposure: Option<number>;
	lastDayTrades: Option<number>;
	primaryShareClass: Option<string>;
};

interface FundOverviewWidgetTheme {
	title?: string;
	headerRow?: string;
	widget?: string;
	col?: string;
	card?: string;
	metricCard?: MetricCardTheme;
}

const singleTheme: FundOverviewWidgetTheme = {
	title: css.title,
	headerRow: css.headerRow,
	widget: css.widget,
	col: css.col,
	metricCard: { card: css.card, valueRow: css.cardValue },
};

const multiTheme: FundOverviewWidgetTheme = {
	title: cn(css.title, css.title_multi),
	headerRow: cn(css.headerRow, css.headerRow_multi),
	widget: cn(css.widget, css.widget_multi),
	col: cn(css.col, css.col_multi),
	metricCard: { card: cn(css.card, css.card_multi), iconFill: '#fff', valueRow: css.cardValue },
};

const multiThemeDark: FundOverviewWidgetTheme = deepmerge(multiTheme, {
	metricCard: { iconFill: '#464646', valueRow: css.cardValue },
});

interface FundOverviewWidgetProps {
	product: RemoteData<Error, ProductOverview>;
	withDetails?: boolean;
	theme?: 'single' | 'multiple';
	width: number;
}

/**
 * Font metrics:
 * Money:
 * 32px => requires 190px width
 * 24px => requires 140px width
 * 20px => requires 120px width
 * Percents above 100000%:
 * 32px => requires 270px width
 * 24px => requires 205px width
 * 20px => requires 170px width
 * Percents below 100000%:
 * 32px => requires 130px width
 * 24px => requires 95px width
 * 20px => requires 80px width
 *
 * The most spacious setup:
 * - 1612px total widget width
 * - 30px horiz paddings
 * - 20px gutter
 * - 20px box paddings
 *
 * Text width =
 * = ((total width) - (horiz paddings) * 2 - gutter * 4) / 5 - (box paddings) * 2 =
 * = ((total width) - 140) / 5 - 40 =
 * = (total width) / 5 - 68
 *
 * Required total width =
 * = ((text width) + (box paddings) * 2) * 5 + gutter * 4 + (horiz paddings) * 2 =
 * = (text width) * 5 + 340
 */

const textWidthSm = 120;
const textWidthMd = 140;
const textWidthLg = 190;
const fontSizeLg = 32;
const fontSizeMd = 24;
const fontSizeSm = 20;

const iconPadding = 10;
const iconSizeLg = 50;
const iconSizeSm = 40;

const allNumbers = (
	<Fragment>
		{pipe(
			array.makeBy(15, i => [renderMoney(0.0055555555555 * Math.pow(10, i), 'EUR'), <br />]),
			array.flatten,
		)}
	</Fragment>
);
const allPercents = (
	<Fragment>
		{pipe(
			array.makeBy(15, i => [renderPercent(0.0055555555555 * Math.pow(10, i)), <br />]),
			array.flatten,
		)}
	</Fragment>
);

// const colFlex = '0 0 20%';

const gutterSm = [10, 10] as [number, number];
const gutterMd = [10, 16] as [number, number];

function getIconSize(breakpoint: number): number | undefined {
	switch (breakpoint) {
		default:
			return 40;
	}
}

export function getWrapperPaddings(width: number) {
	return width >= 900 ? 30 : 15;
}

function getValueSizes(textWidth: number): [fontSize: number, iconSize: number] {
	const sizes = [
		{ minWidth: textWidthLg + iconPadding + iconSizeLg, result: tuple(fontSizeLg, iconSizeLg) },
		{ minWidth: textWidthLg + iconPadding + iconSizeSm, result: tuple(fontSizeLg, iconSizeSm) },
		{ minWidth: textWidthMd + iconPadding + iconSizeLg, result: tuple(fontSizeMd, iconSizeLg) },
		{ minWidth: textWidthMd + iconPadding + iconSizeSm, result: tuple(fontSizeMd, iconSizeSm) },
		{ minWidth: textWidthSm + iconPadding + iconSizeSm, result: tuple(fontSizeSm, iconSizeSm) },
		{ minWidth: textWidthLg, result: tuple(fontSizeLg, 0) },
		{ minWidth: textWidthMd, result: tuple(fontSizeMd, 0) },
		{ minWidth: -Infinity, result: tuple(fontSizeSm, 0) },
	];
	return sizes.find(config => textWidth >= config.minWidth)!.result;
}

export const FundOverviewWidget = memo<FundOverviewWidgetProps>(
	({ product, withDetails, theme: themeName = 'single', width }) => {
		const breakpoint = useBreakpoint();
		const isMdOrLarger = breakpoint >= MD;
		const isDark = useIsDarkTheme();
		const theme = themeName === 'single' ? singleTheme : isDark ? multiThemeDark : multiTheme;

		// Priority 1: try to fit everything in one row as much as possible
		// Priority 2: show icons if possible
		const numCols = width >= 1100 ? 6 : width >= 600 ? 3 : 2;
		const gutter = width >= 900 ? 20 : 10;
		const horizPaddings = getWrapperPaddings(width);
		const boxPaddings = width >= 900 ? 20 : 15;
		const boxWidth = (width - horizPaddings * 2 - gutter * (numCols - 1)) / numCols;
		const textWidth = boxWidth - boxPaddings * 2;
		const gridGutter = useMemo(() => tuple(gutter, gutter), [gutter]);
		const colFlex = `0 0 ${100 / numCols}%`;
		const [fontSize, iconSizeCalculated] = getValueSizes(textWidth);

		const title = (
			<Typography.Title level={2} className={theme.title}>
				{pipe(
					product,
					remoteData.map(p => p.product.name),
					remoteData.getOrElse(() => (<Skeleton paragraph={false} />) as ReactNode),
				)}
			</Typography.Title>
		);

		const header = (
			<Row className={theme.headerRow} gutter={[40, 15]}>
				<Col>{title}</Col>
				{withDetails && (
					<Col>
						<ProductDetailsWidgetContainer />
					</Col>
				)}
			</Row>
		);

		const mainShareClassName = pipe(
			product,
			remoteData.toOption,
			option.chain(p => p.primaryShareClass),
		);

		const mainShareClassTooltip = pipe(
			mainShareClassName,
			option.fold(constNull, shareClass => (
				<Tooltip overlay={`Calculated for primary share class (${shareClass})`}>
					<InfoCircleOutlined className={css.titleWithInfoIcon} />
				</Tooltip>
			)),
		);

		const renderTitleWithHint = (title: string, hint: ReactNode) => (
			<div className={css.titleWithInfo}>
				<span className={css.titleWithInfoText}>{title}</span>&nbsp;{hint}
			</div>
		);

		const renderTitleWithShareClass = (title: string) =>
			isMdOrLarger
				? renderTitleWithHint(title, mainShareClassTooltip)
				: pipe(
						mainShareClassName,
						option.fold(
							() => title,
							shareClass => `${title} (${shareClass})`,
						),
				  );

		const iconSize = isMdOrLarger ? iconSizeCalculated : 50;
		const iconImgSize = ((iconSize ?? 50) * 26) / 50;
		const metricTheme = useMemo(
			() => ({
				...theme.metricCard,
				iconSize,
				valueRow: cn(theme.metricCard?.valueRow, css[`valueRow_font${fontSize}`]),
				card: cn(theme.metricCard?.card, css[`card_padding${boxPaddings}`]),
			}),
			[theme.metricCard, iconSize, boxPaddings, fontSize],
		);

		const CardComponent = isMdOrLarger ? MetricCard2 : MetricCard;

		const cards = [
			<CardComponent
				theme={metricTheme}
				icon={iconSize > 0 && <img src={navPerShareSvg} width={iconImgSize} />}
				title={renderTitleWithShareClass('NAV per share')}
				value={
					/*allNumbers */ pipe(
						product,
						remoteData.toOption,
						option.chain(p =>
							pipe(
								p.nav,
								option.map(value => renderMoney(value, p.product.currencyCode)),
							),
						),
						renderOption,
					)
				}
			/>,
			<CardComponent
				theme={metricTheme}
				icon={iconSize > 0 && <img src={dailyChangeSvg} width={iconImgSize} />}
				title={renderTitleWithShareClass('Daily Change')}
				value={
					/*allPercents */ pipe(
						product,
						remoteData.toOption,
						option.chain(p => p.dailyChange),
						renderOptionPercent,
					)
				}
			/>,
			<CardComponent
				theme={metricTheme}
				icon={iconSize > 0 && <img src={moneySvg} width={iconImgSize} />}
				title={
					<Fragment>
						NAV&nbsp;
						<GlossaryHint term="NAV" />
					</Fragment>
				}
				value={pipe(
					product,
					remoteData.toOption,
					option.chain(p =>
						pipe(
							p.aum,
							option.map(value => renderMoney(value, p.product.currencyCode)),
						),
					),
					renderOption,
				)}
			/>,
			<CardComponent
				theme={metricTheme}
				icon={iconSize > 0 && <img src={plusMinusSvg} width={iconImgSize} />}
				title={
					<Fragment>
						Gross Exposure&nbsp;
						<GlossaryHint term="Gross Exposure" />
					</Fragment>
				}
				value={pipe(
					product,
					remoteData.toOption,
					option.chain(p => p.grossExposure),
					renderOptionPercent,
				)}
			/>,
			<CardComponent
				theme={metricTheme}
				icon={iconSize > 0 && <img src={plusMinusSvg} width={iconImgSize} />}
				title={
					<Fragment>
						Net Exposure&nbsp;
						<GlossaryHint term="Net Exposure" />
					</Fragment>
				}
				value={pipe(
					product,
					remoteData.toOption,
					option.chain(p => p.netExposure),
					renderOptionPercent,
				)}
			/>,
			<CardComponent
				theme={metricTheme}
				icon={iconSize > 0 && <img src={clockSvg} width={iconImgSize} />}
				title={renderTitleWithHint('Trades Prev Day', <GlossaryHint term="Trades Previous Day" />)}
				value={pipe(
					product,
					remoteData.toOption,
					option.chain(p => p.lastDayTrades),
					renderOption,
				)}
			/>,
		];

		if (!isMdOrLarger) {
			return <MetricsWidgetMobile style={{ width }}>{cards}</MetricsWidgetMobile>;
		}

		return (
			<Widget flex={'1 0 100%'} header={header} className={theme.widget}>
				<Row gutter={gridGutter}>
					{cards.map((card, i) => (
						<Col key={i} flex={colFlex} className={theme.col}>
							{card}
						</Col>
					))}
				</Row>
			</Widget>
		);
	},
);
