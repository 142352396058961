import { RemoteData } from '@devexperts/remote-data-ts';
import { Typography } from 'antd';
import { constNull, pipe } from 'fp-ts/lib/function';
import { ComponentProps, Fragment, HTMLAttributes, memo, ReactElement, ReactNode } from 'react';
import { remoteData } from '../../utils/remote-data.utils';
import { Spinner } from './Spinner';
import css from './Loading.module.less';
import cn from 'classnames';
import { FolderOpenOutlined } from '@ant-design/icons';

interface LoadingProps<A> {
	data: RemoteData<unknown, A>;
	success: (val: A) => ReactNode;
}

export const LoadingRaw = <A extends {}>(props: LoadingProps<A>) => {
	return pipe(
		props.data,
		remoteData.fold(
			constNull,
			() => <Spinner />,
			() => <Spinner />,
			value => <Fragment>{props.success(value)}</Fragment>,
		),
	);
};

export const Loading = memo(LoadingRaw) as typeof LoadingRaw;

// export const Spinner = () => <span>Loading...</span>;

interface DataStateCoverProps {
	icon?: ReactNode;
	title?: ReactNode;
	subtitle?: ReactNode;
	className?: string;
}

export const DataStateCover = ({ className, title, icon, subtitle }: DataStateCoverProps) => {
	return (
		<div className={cn(css.cover, className)}>
			{icon && <div className={css.icon}>{icon}</div>}
			{title && (
				<Typography.Title level={1} className={css.title}>
					{title}
				</Typography.Title>
			)}
			{subtitle && <div className={css.subtitle}>{subtitle}</div>}
		</div>
	);
};

export const LoadingCover = ({ className, ...props }: ComponentProps<typeof Spinner>) => {
	return <DataStateCover icon={<Spinner {...props} />} />;
};

export const LoadingInline = () => <span>Loading...</span>;

export const ErrorCover = () => (
	<DataStateCover title={'Data loading failure'} subtitle={'Please contact Quay Partners technical support'} />
);

export const NoDataCover = () => (
	<DataStateCover title={'No data'} icon={<FolderOpenOutlined className={css.noDataIcon} />} />
);

export const ErrorInline = () => <span className={css.error}>Loading error</span>;

interface RenderRemoteDataOpts<A, R> {
	pending?: R;
	failure?: (e: Error) => R;
	success: (data: A) => R;
	inline?: boolean;
}

export function renderRemoteData<A, R>(data: RemoteData<Error, A>, opts: RenderRemoteDataOpts<A, R>): ReactElement | R {
	return pipe(
		data,
		remoteData.fold3<Error, A, ReactElement | R>(
			() => opts.pending ?? (opts.inline ? <LoadingInline /> : <LoadingCover />),
			e => (opts.failure ? opts.failure(e) : opts.inline ? <ErrorInline /> : <ErrorCover />),
			opts.success,
		),
	);
}
