import { Col } from 'antd';
import React, { Fragment, memo, useContext } from 'react';
import { pending, RemoteData, failure, success } from '@devexperts/remote-data-ts';
import * as remoteData from '@devexperts/remote-data-ts';
import { pipe } from 'fp-ts/pipeable';
import { MetricCard } from '../../ui-kit/MetricCard';
import arrowSvg from '../../../icons/arrow.svg';
import arrowDownSvg from '../../../icons/arrow-down.svg';
import cashSvg from '../../../icons/cash.svg';
import css from './ExposureOverviewWidget.module.less';
import { Option } from 'fp-ts/lib/Option';
import { renderOptionPercent } from '../../../utils/string.utils';
import { option } from 'fp-ts';
import { gql, useQuery } from '@apollo/client';
import { ProductContext, useIsMobile } from '../../../utils/context.utils';
import {
	GetProductExposureOverview,
	GetProductExposureOverviewVariables,
} from '../../../../generated-types/GetProductExposureOverview';
import { MetricsWidget } from '../metrics/MetricsWidget';
import AutoSizer from 'react-virtualized-auto-sizer';
import { GlossaryHint } from '../../ui-kit/GlossaryHint';

export type ExposureValues = {
	short: Option<number>;
	long: Option<number>;
	gross: Option<number>;
	net: Option<number>;
};

interface ExposureOverviewWidgetProps {
	value: RemoteData<Error, ExposureValues>;
	width: number;
}

const gutterSm = [10, 10] as [number, number];
const gutterMd = [20, 16] as [number, number];

export const ExposureOverviewWidget = memo<ExposureOverviewWidgetProps>(({ value, width }) => {
	// const isMdOrLarger = useBreakpoint() >= MD;
	const isMobile = useIsMobile();

	const colWidth = isMobile ? 100 / 2.25 : width >= 600 ? 100 / 4 : 100 / 2;
	const gutter = 10; // width >= 900 ? 20 : 10;

	return (
		<MetricsWidget header={'Exposure'} style={{ width }} gutter={[gutter, gutter]}>
			{/* <Row gutter={isMdOrLarger ? gutterMd : gutterSm}> */}
			<Col className={css.col} flex={`0 0 ${colWidth}%`} style={{ width: `${colWidth}%` }}>
				<MetricCard
					className={css.card}
					icon={<img src={cashSvg} />}
					title={
						<Fragment>
							Gross&nbsp;
							<GlossaryHint term={'Gross Exposure'} />
						</Fragment>
					}
					value={pipe(
						value,
						remoteData.toOption,
						option.chain(p => p.gross),
						renderOptionPercent,
					)}
				/>
			</Col>
			<Col className={css.col} flex={`0 0 ${colWidth}%`} style={{ width: `${colWidth}%` }}>
				<MetricCard
					className={css.card}
					icon={<img src={cashSvg} />}
					title={
						<Fragment>
							Net&nbsp;
							<GlossaryHint term={'Net Exposure'} />
						</Fragment>
					}
					value={pipe(
						value,
						remoteData.toOption,
						option.chain(p => p.net),
						renderOptionPercent,
					)}
				/>
			</Col>
			<Col className={css.col} flex={`0 0 ${colWidth}%`} style={{ width: `${colWidth}%` }}>
				<MetricCard
					className={css.card}
					icon={<img src={arrowSvg} />}
					title={
						<Fragment>
							Long&nbsp;
							<GlossaryHint term={'Long Exposure'} />
						</Fragment>
					}
					value={pipe(
						value,
						remoteData.toOption,
						option.chain(p => p.long),
						renderOptionPercent,
					)}
				/>
			</Col>
			<Col className={css.col} flex={`0 0 ${colWidth}%`} style={{ width: `${colWidth}%` }}>
				<MetricCard
					className={css.card}
					icon={<img src={arrowDownSvg} />}
					title={
						<Fragment>
							Short&nbsp;
							<GlossaryHint term={'Short Exposure'} />
						</Fragment>
					}
					value={pipe(
						value,
						remoteData.toOption,
						option.chain(p => p.short),
						renderOptionPercent,
					)}
				/>
			</Col>
			{/* </Row> */}
			{/* </Widget> */}
		</MetricsWidget>
	);
});

const QUERY = gql`
	query GetProductExposureOverview($product: String!) {
		products(ids: [$product]) {
			id
			generationDate
			netExposure
			grossExposure
			longExposure
			shortExposure
		}
	}
`;

export const ExposureOverviewWidgetContainer = () => {
	const { selectedProduct } = useContext(ProductContext);
	const { data, loading, error } = useQuery<GetProductExposureOverview, GetProductExposureOverviewVariables>(QUERY, {
		variables: { product: selectedProduct?.id ?? '' },
		skip: !selectedProduct,
	});

	const value: ExposureValues | undefined = data
		? {
				net: option.fromNullable(data.products[0]?.netExposure),
				gross: option.fromNullable(data.products[0]?.grossExposure),
				long: option.fromNullable(data.products[0]?.longExposure),
				short: option.fromNullable(data.products[0]?.shortExposure),
		  }
		: undefined;
	const rd = loading ? pending : value ? success(value) : failure(error ?? new Error());
	return (
		<Col xs={24} md={12} className={css.container}>
			<div>
				<AutoSizer disableHeight>
					{({ width }) => <ExposureOverviewWidget value={rd} width={width} />}
				</AutoSizer>
			</div>
		</Col>
	);
};
