import { Col, Row } from 'antd';
import { pipe } from 'fp-ts/lib/pipeable';
import React, { Fragment, useContext } from 'react';
import { ProductContext } from '../../../utils/context.utils';
import { remoteData } from '../../../utils/remote-data.utils';
import { TitleWithFundSelector } from '../../ui-kit/TitleWithFundSelector';
import { ProductDetailsWidgetContainer } from '../../widgets/product-details/ProductDetailsWidgetContainer';
import css from './OverviewPageFundSelector.module.less';

export const OverviewPageFundSelector = () => {
	const { productOptions } = useContext(ProductContext);
	const hasMultiple = pipe(
		productOptions,
		remoteData.exists(opts => opts.length > 1),
	);
	return hasMultiple ? (
		<Row className={css.row} gutter={[40, 0]}>
			<Col xs={24}>
				<TitleWithFundSelector hasSelector>Overview for</TitleWithFundSelector>
			</Col>
			<Col>
				<ProductDetailsWidgetContainer />
			</Col>
		</Row>
	) : (
		<Fragment />
	);
};
