import { Typography } from 'antd';
import React, { Fragment, memo } from 'react';
import { ContactInfoWidgetContainer } from '../widgets/contact-info/ContactInfoWidgetContainer';

interface ContactsPageProps {}

export const ContactsPage = memo<ContactsPageProps>(() => {
	return (
		<Fragment>
			<Typography.Title level={1}>Contacts</Typography.Title>
			<ContactInfoWidgetContainer />
		</Fragment>
	);
});
