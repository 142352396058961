import { parseISO } from 'date-fns';
import { pipe } from 'fp-ts/lib/function';
import { memo, useMemo, useState } from 'react';
import { colorGray, colorGray2 } from '../../../utils/color.utils';
import { useCurrentDate, useIsDarkTheme } from '../../../utils/context.utils';
import { periodToFilter, TimePeriod } from '../../../utils/date.utils';
import { remoteData } from '../../../utils/remote-data.utils';
import { MDASH } from '../../../utils/string.utils';
import { useTradesData } from '../../../utils/trades.utils';
import { TimePeriodSelector } from '../../ui-kit/TimePeriodSelector';
import { PiechartWidget } from '../piechart/PiechartWidget';

interface TradesDistributionWidgetContainerProps {
	type: 'Traders - Volume' | 'Execution Venue';
}

export const TradesDistributionWidgetContainer = memo<TradesDistributionWidgetContainerProps>(({ type }) => {
	const [timePeriod, setTimePeriod] = useState<TimePeriod>({ preset: 'ytd' });
	const now = useCurrentDate();

	const trades = useTradesData();
	const dataFromTrades = useMemo(
		() =>
			pipe(
				trades,
				remoteData.map(trades => {
					const filter = periodToFilter(timePeriod, now);
					const result = new Map<string, number>();
					trades.forEach(trade => {
						if (filter(parseISO(trade.tradeDate))) {
							const venue = trade.executionVenue ?? MDASH;
							const comm = result.get(venue) ?? 0;
							result.set(venue, comm + 1);
						}
					});
					return Array.from(result.entries()).map(([name, value]) => ({ name, value }));
				}),
			),
		[trades, timePeriod, now],
	);

	const isDark = useIsDarkTheme();

	return (
		<PiechartWidget
			header={type}
			beforeChart={
				<TimePeriodSelector
					value={timePeriod}
					onChange={setTimePeriod}
					presets={['yesterday', 'wtd', 'mtd', 'ytd']}
				/>
			}
			chartProps={{
				options: {
					dataLabels: {
						style: {
							colors: [isDark ? colorGray : colorGray2],
						},
					},
					plotOptions: {
						pie: {
							dataLabels: {
								offset: 60,
								minAngleToShowLabel: 0,
							},
						},
					},
					grid: {
						padding: { top: 40, left: 60, right: 60, bottom: 40 },
					},
				},
			}}
			data={dataFromTrades}
		/>
	);
});
