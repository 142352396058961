import { Col, Row, Typography } from 'antd';
import React, { Fragment, memo, useContext, useState } from 'react';
import { ResponsivePage } from '../../layout/ResponsivePage';
import { WidgetList } from '../../layout/WidgetList';
import { ExposureOverviewWidgetContainer } from '../../widgets/exposure-overview/ExposureOverviewWidget';
import { FundOverviewWidgetContainer } from '../../widgets/fund-overview/FundOverviewWidgetContainer';
import { PerformanceWidgetContainer } from '../../widgets/performance/PerformanceWidget';
import { ProductDetailsWidgetContainer } from '../../widgets/product-details/ProductDetailsWidgetContainer';
import { RiskValuesWidgetContainer } from '../../widgets/risk-values/RiskValuesWidget';
import { ShareClassPerformanceWidgetContainer } from '../../widgets/share-class-performance/ShareClassPerformanceWidget';
import { TradingPrevMonthsWidgetContainer } from '../../widgets/trading-prev-12-months/TradingPrevMonthsWidget';
import { OverviewPageFundSelector } from './OverviewPageFundSelector';
import css from './OverviewPage.module.less';
import { AumChartWidgetContainer } from '../../widgets/aum-chart/AumChartWidget';
import { ProductContext } from '../../../utils/context.utils';
import { remoteData } from '../../../utils/remote-data.utils';
import { pipe } from 'fp-ts/lib/pipeable';
import { TitleWithFundSelector } from '../../ui-kit/TitleWithFundSelector';

interface OverviewPageProps {
	onLayoutClassChange: (c: string) => void;
}

/*
const RiskRow = () => {
	const breakpoint = useBreakpoint();
	const gutter = breakpoint >= MD ? [20, 50] : [20, 20];
	const exposuresMinWidth = (() => {
		switch (breakpoint) {
			case XS:
			case SM:
				return 250;
			case MD:
			case LG:
				return 640;
			default:
				return 760;
		}
	})();
	const riskMinWidth = (() => {
		switch (breakpoint) {
			case XS:
			case SM:
				return 250;
			case MD:
			case LG:
				return 500;
			default:
				return 620;
		}
	})();

	return (
		<Col xs={24}>
			<Autosizer disableHeight>
				{({ width }) => {
					const minWidth = Math.max(riskMinWidth, exposuresMinWidth);
					if (true) {
						//(width - gutter[0]) / 2 < minWidth) {
						return (
							<Row gutter={[20, 20]} style={{ width: width + gutter[0] }}>
								<ExposureOverviewWidgetContainer />
								<RiskValuesWidgetContainer />
							</Row>
						);
					} else {
						return (
							<Row gutter={[20, 20]} style={{ width: width + gutter[0] }}>
								<Col xs={12}>
									<MetricsWidgetWrapper>
										<Row>
											<ExposureOverviewWidgetContainer />
										</Row>
									</MetricsWidgetWrapper>
								</Col>
								<Col xs={12}>
									<MetricsWidgetWrapper>
										<Row>
											<RiskValuesWidgetContainer />
										</Row>
									</MetricsWidgetWrapper>
								</Col>
							</Row>
						);
					}
				}}
			</Autosizer>
		</Col>
	);
};*/

const content = (
	<Fragment>
		<PerformanceWidgetContainer />
		<TradingPrevMonthsWidgetContainer />
		<ShareClassPerformanceWidgetContainer />
		<AumChartWidgetContainer />
		<ExposureOverviewWidgetContainer />
		<RiskValuesWidgetContainer />
	</Fragment>
);

const OverviewPageDesktop = memo(() => {
	const { selectedClient, productOptions } = useContext(ProductContext);
	const hasMultiple = pipe(
		productOptions,
		remoteData.exists(opts => opts.filter(opt => opt.belongsToClient === selectedClient).length > 1),
	);

	return (
		<Fragment>
			<TitleWithFundSelector noEffectiveDate={hasMultiple}>Product Summary</TitleWithFundSelector>
			<WidgetList>
				<FundOverviewWidgetContainer />
			</WidgetList>
			<OverviewPageFundSelector />
			<Row gutter={[20, 50]}>{content}</Row>
		</Fragment>
	);
});

const OverviewPageMobile = memo(() => {
	const [opacity, setOpacity] = useState(1);

	return (
		<Fragment>
			<div style={{ opacity }}>
				<Row gutter={[20, 20]}>
					<FundOverviewWidgetContainer />
					<Col>
						<ProductDetailsWidgetContainer />
					</Col>
					{content}
				</Row>
			</div>
		</Fragment>
	);
});

const theme = {
	mobileLayout: css.layout,
	mobile: {
		bottomPanel: css.bottom,
	},
};

export const OverviewPage = memo<OverviewPageProps>(props => {
	return (
		<ResponsivePage
			theme={theme}
			noDesktopHeader
			extendedCards
			onLayoutClassChange={props.onLayoutClassChange}
			pageName="Overview"
			page={OverviewPageDesktop}
			mobilePage={OverviewPageMobile}
		/>
	);
});
