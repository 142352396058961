import { RemoteData } from '@devexperts/remote-data-ts';
import { Col, Empty, TableProps } from 'antd';
import { pipe } from 'fp-ts/lib/pipeable';
import { memo, useMemo, useState } from 'react';
import { Optional, optional } from '../../../../../common/utils/nullable.utils';
import { TableWidget } from '../../layout/TableWidget';
import { constVoid } from 'fp-ts/lib/function';
import { array, ord } from 'fp-ts';
import { TimePeriodSelector } from '../../ui-kit/TimePeriodSelector';
import { format, parse } from 'date-fns';
import css from './TradesWidget.module.less';
import { remoteData } from '../../../utils/remote-data.utils';
import { ordStringCaseInsensitive, renderMoney, renderNumGrouped, renderOptional } from '../../../utils/string.utils';
import { flow } from 'fp-ts/lib/function';
import { ordDate, ordNumber } from 'fp-ts/lib/Ord';
import { renderRemoteData } from '../../ui-kit/Loading';
import { TradeCard } from './TradeCard';
import { WidgetTitle } from '../../ui-kit/WidgetTitle';
import { useTradesData } from '../../../utils/trades.utils';
import { periodToFilter, TimePeriod } from '../../../utils/date.utils';
import { GlossaryHint } from '../../ui-kit/GlossaryHint';
import { useCurrentDate } from '../../../utils/context.utils';

export interface Trade {
	tradeDate: Optional<Date>;
	instrument: Optional<string>;
	productName: Optional<string>;
	bloombergTicker: Optional<string>;
	baseCurrency: Optional<string>;
	price: Optional<number>;
	quantity: Optional<number>;
	settleAmount: Optional<number>;
	brokerGroup: Optional<string>;
	trader: Optional<string>;
	side: Optional<string>;
}

interface TradesWidgetProps {
	trades: RemoteData<Error, Trade[]>;
	onClose?: () => void;
	visible: boolean;
	timePeriod: TimePeriod;
	onChangeTimePeriod: (val: TimePeriod) => void;
	cards?: boolean;
}

const columnConfigs: TableProps<Trade>['columns'] = [
	{
		dataIndex: 'tradeDate',
		key: 'tradeDate',
		sorter: ord.contramap((t: Trade) => t.tradeDate)(optional.getOrd(ordDate)).compare,
		title: 'Trade Date',
		render: flow(
			optional.map(d => format(d, 'yyyy-MM-dd')),
			renderOptional,
		),
		width: 130,
		defaultSortOrder: 'descend',
	},
	{
		dataIndex: 'instrument',
		key: 'instrument',
		sorter: ord.contramap((t: Trade) => t.instrument)(optional.getOrd(ordStringCaseInsensitive)).compare,
		title: 'Instrument',
		render: renderOptional,
		width: 130,
	},
	{
		dataIndex: 'productName',
		key: 'productName',
		sorter: ord.contramap((t: Trade) => t.productName)(optional.getOrd(ordStringCaseInsensitive)).compare,
		title: 'Product',
		render: renderOptional,
		width: 110,
	},
	{
		dataIndex: 'bloombergTicker',
		key: 'bloombergTicker',
		sorter: ord.contramap((t: Trade) => t.bloombergTicker)(optional.getOrd(ordStringCaseInsensitive)).compare,
		title: 'Ticker',
		render: renderOptional,
		width: 100,
	},
	{
		dataIndex: 'side',
		key: 'side',
		sorter: ord.contramap((t: Trade) => t.side)(optional.getOrd(ordStringCaseInsensitive)).compare,
		title: 'Side',
		render: renderOptional,
		width: 100,
	},
	{
		dataIndex: 'quantity',
		key: 'quantity',
		sorter: ord.contramap((t: Trade) => t.quantity)(optional.getOrd(ordNumber)).compare,
		title: 'Qty',
		render: renderNumGrouped,
		align: 'right',
		width: 75,
	},
	{
		dataIndex: 'price',
		key: 'price',
		sorter: ord.contramap((t: Trade) => t.price)(optional.getOrd(ordNumber)).compare,
		title: 'Price',
		align: 'right',
		render: val => renderMoney(val),
		width: 90,
	},
	{
		dataIndex: 'baseCurrency',
		key: 'baseCurrency',
		sorter: ord.contramap((t: Trade) => t.baseCurrency)(optional.getOrd(ordStringCaseInsensitive)).compare,
		title: 'Ccy',
		render: renderOptional,
		width: 80,
	},
	{
		dataIndex: 'settleAmount',
		key: 'settleAmount',
		sorter: ord.contramap((t: Trade) => t.settleAmount)(optional.getOrd(ordNumber)).compare,
		title: 'Settlement Amount',
		render: val => renderMoney(val),
		align: 'right',
		width: 190,
	},
	{
		dataIndex: 'brokerGroup',
		key: 'brokerGroup',
		sorter: ord.contramap((t: Trade) => t.brokerGroup)(optional.getOrd(ordStringCaseInsensitive)).compare,
		title: 'Broker Group',
		render: renderOptional,
		width: 150,
	},
	{
		dataIndex: 'trader',
		key: 'trader',
		sorter: ord.contramap((t: Trade) => t.trader)(optional.getOrd(ordStringCaseInsensitive)).compare,
		title: 'Trader',
		render: renderOptional,
		width: 100,
	},
];

// const ords: Partial<Record<keyof Trade, Ord<Trade>>> = {
// instrument: ord.contramap((p: Trade) => p.instrument)(nullable.getOrd(ordString)),
// price: ord.contramap((p: Trade) => p.price)(nullable.getOrd(ordNumber)),
// time: ord.contramap((p: Trade) => p.time)(nullable.getOrd(ordDate)),
// };

export const TradesWidget = memo<TradesWidgetProps>(
	({ trades, cards = false, onClose, visible, timePeriod, onChangeTimePeriod }) => {
		const header = (
			<WidgetTitle
				addon={
					<TimePeriodSelector
						value={timePeriod}
						onChange={onChangeTimePeriod}
						presets={['yesterday', 'wtd', 'mtd']}
					/>
				}
			>
				Trades&nbsp;
				<GlossaryHint term="Trades (Table)" />
			</WidgetTitle>
		);
		if (cards) {
			return (
				<Col xs={24}>
					{header}
					{renderRemoteData(trades, {
						success: rows =>
							rows.length ? (
								rows.map((t, i) => <TradeCard trade={t} key={`row-${i}`} />)
							) : (
								<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
							),
					})}
				</Col>
			);
		}

		return (
			// <Drawer onClose={onClose} visible={visible} placement="top" height="70%">
			<Col xs={24}>
				<TableWidget<Trade>
					theme={{ card: css.card }}
					header={header}
					// sticky
					// loading={remoteData.isPending(trades)}
					rowKey={(p, i) => `row-${i}`}
					columns={columnConfigs /*.map(x => ({ ...x, width: 150 }))*/}
					scroll={{ x: 'auto' }}
					sticky={{
						getContainer: () => document.querySelector(`[class~="${css.card}"]`)! as HTMLElement,
					}}
					dataSource={trades}
				/>
			</Col>
			// </Drawer>
		);
	},
);

export const TradesWidgetContainer = memo<
	Omit<TradesWidgetProps, 'trades' | 'onClose' | 'visible' | 'timePeriod' | 'onChangeTimePeriod'>
>(props => {
	const [timePeriod, setTimePeriod] = useState<TimePeriod>({ preset: 'mtd' });
	const now = useCurrentDate();
	const dataRaw = useTradesData();
	const dataRD = useMemo(() => {
		const dateFilter = periodToFilter(timePeriod, now);
		return pipe(
			dataRaw,
			remoteData.map(
				flow(
					array.map(trade => ({
						...trade,
						tradeDate: pipe(
							trade.tradeDate,
							optional.map(d => parse(d, 'yyyy-MM-dd', new Date(0))),
						),
					})),
					array.filter(trade => !trade.tradeDate || dateFilter(trade.tradeDate)),
				),
			),
		);
	}, [dataRaw, timePeriod, now]);

	/*const preset = timePeriod.preset ?? 'yesterday';
	const days = preset === 'wtd' ? 5 : preset === 'mtd' ? 20 : 1;
	const data = pipe(
		replicate(days, {
			instrument: 'PUT EUR vs TRY DIGITAL (cond EURTRY =<8.6)',
			price: 8.6,
			time: new Date(2021, 2, 18),
		}),
		array.mapWithIndex((i, trade) => ({ ...trade, time: subDays(trade.time, i) })),
		success,
	);*/

	return (
		<TradesWidget
			trades={dataRD}
			onClose={constVoid}
			visible={true}
			onChangeTimePeriod={setTimePeriod}
			timePeriod={timePeriod}
			{...props}
		/>
	);
});
