import { Button, ButtonProps, Layout } from 'antd';
import css from './Navbar.module.less';
import { memo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { ReactComponent as DashboardImg } from '../../../icons/dashboard.svg';
import { ReactComponent as CompassImg } from '../../../icons/compass.svg';
import { ReactComponent as WarningImg } from '../../../icons/warning.svg';
import { ReactComponent as CandlesImg } from '../../../icons/candles.svg';
import { ReactComponent as DotsImg } from '../../../icons/dots.svg';
import { ReactComponent as ReportImg } from '../../../icons/report.svg';
import { ReactComponent as GlossaryImg } from '../../../icons/glossary.svg';
import { ReactComponent as ContactsImg } from '../../../icons/contacts.svg';
import cn from 'classnames';
import { MobileContextMenu } from '../../ui-kit/MobileContextMenu';
import { constVoid } from 'fp-ts/lib/function';
import { useDIValue } from '../../../utils/container.utils';
import { AuthService } from '../../../services/auth.service';

const RouteMenuItem = (props: ButtonProps & { route: string }) => {
	const { route, onClick = constVoid, ...rest } = props;
	const history = useHistory();
	const { pathname } = useLocation();

	return (
		<Button
			{...rest}
			type="text"
			onClick={e => {
				history.push(route);
				onClick(e);
			}}
			className={cn(rest.className, css.item, pathname === route && css.item_active)}
		/>
	);
};

const morePathnames = ['/glossary', '/reports'];

interface NavbarProps {
	onLogout?: () => void;
}

export const Navbar = memo<NavbarProps>(({ onLogout }) => {
	const [isMenuOpen, setMenuOpen] = useState(false);
	const menu = (
		<MobileContextMenu visible={isMenuOpen} onClose={() => setMenuOpen(false)}>
			<div className={css.menu}>
				<RouteMenuItem route="/reports" className={css.menuItem}>
					<ReportImg className={css.itemIcon} />
					Reports
				</RouteMenuItem>
				<RouteMenuItem route="/contacts" className={css.menuItem}>
					<ContactsImg className={css.itemIcon} />
					Contacts
				</RouteMenuItem>
				<RouteMenuItem route="/glossary" className={css.menuItem}>
					<GlossaryImg className={css.itemIcon} />
					Glossary
				</RouteMenuItem>
			</div>
		</MobileContextMenu>
	);
	const { pathname } = useLocation();

	return (
		<Layout.Footer className={css.footer}>
			{menu}
			<RouteMenuItem route={'/'}>
				<div className={css.itemIcon}>
					<DashboardImg className={css.itemIcon} />
				</div>
				Dashboard
			</RouteMenuItem>
			<RouteMenuItem route={'/positions'}>
				<div className={css.itemIcon}>
					<CompassImg className={css.itemIcon} />
				</div>
				Positions
			</RouteMenuItem>
			<RouteMenuItem route={'/trading'}>
				<div className={css.itemIcon}>
					<CandlesImg className={css.itemIcon} />
				</div>
				Trading
			</RouteMenuItem>
			<RouteMenuItem route={'/risk'}>
				<div className={css.itemIcon}>
					<WarningImg className={css.itemIcon} />
				</div>
				Risk
			</RouteMenuItem>
			<Button
				type="text"
				onClick={() => setMenuOpen(x => !x)}
				className={cn(css.item, morePathnames.includes(pathname) && css.item_active)}
			>
				<div className={css.itemIcon}>
					<DotsImg className={css.itemIcon} />
				</div>
				more
			</Button>
		</Layout.Footer>
	);
});

export const NavbarContainer = () => {
	const auth = useDIValue<AuthService>('auth');
	return <Navbar onLogout={auth.logout} />;
};
