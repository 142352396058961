import { Card, Col, ColProps, Row } from 'antd';
import React, { Children, cloneElement, createElement, CSSProperties, memo, ReactElement, ReactNode } from 'react';
import { Widget } from '../../layout/Widget';
import { MetricCard, MetricCardMobile, MetricCardProps } from '../../ui-kit/MetricCard';
import css from './MetricsWidget.module.less';
import { MD, useBreakpoint, useIsMobile } from '../../../utils/context.utils';
import cn from 'classnames';

type ChildType = ReactElement<ColProps, typeof Col> | ReactElement<MetricCardProps, typeof MetricCard>;

interface MetricsWidgetProps {
	children: ChildType | ChildType[];
	header?: ReactNode;
	style?: CSSProperties;
	theme?: {
		widget?: string;
		col?: string;
		card?: string;
	};
	gutter?: [number, number];
}

const gutterSm = [10, 10] as [number, number];
const gutterMd = [20, 16] as [number, number];

export const MetricsWidget = memo(({ children, style, header, theme = {}, gutter }: MetricsWidgetProps) => {
	const isMdOrLarger = useBreakpoint() >= MD;
	const isMobile = useIsMobile();

	const colWidth = `${100 / Children.count(children)}%`;
	const colFlex = `0 0 ${colWidth}`;
	const cards = Children.map(children, (p, i) =>
		p.type === MetricCard ? (
			<Col key={i} flex={colFlex} className={theme.col} style={{ width: colWidth }}>
				{cloneElement(p as any, { ...p.props, className: cn(p.props.className, css.card), key: { i } })}
			</Col>
		) : (
			cloneElement(p as any, { ...p.props, key: { i } })
		),
	);

	return (
		<Widget header={header} className={cn(theme.widget, isMobile && css.widget_mobile)} style={style}>
			<Row className={cn(isMobile && css.row_mobile)} gutter={gutter ?? (isMdOrLarger ? gutterMd : gutterSm)}>
				{cards}
			</Row>
		</Widget>
	);
});

export const MetricsWidgetMobile = memo(({ children, header, theme = {}, style }: MetricsWidgetProps) => {
	const cards = Children.map(children, (child, i) =>
		createElement(MetricCardMobile, { ...(child.props as any), key: i }),
	);

	return (
		<Widget header={header} className={theme.widget} style={style}>
			<Card className={css.mobileCard} bordered={false}>
				{cards}
			</Card>
		</Widget>
	);
});
