import { gql, useQuery } from '@apollo/client';
import { RemoteData } from '@devexperts/remote-data-ts';
import { Card, Col, Typography } from 'antd';
import deepmerge from 'deepmerge';
import { array, ord } from 'fp-ts';
import { empty } from 'fp-ts/lib/Array';
import { flow } from 'fp-ts/lib/function';
import { identity } from 'fp-ts/lib/function';
import { pipe } from 'fp-ts/lib/function';
import { ordNumber } from 'fp-ts/lib/Ord';
import { memo, useContext, useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';
import { GetProductLiquidity } from '../../../../generated-types/GetProductLiquidity';
import { adjustChartOptions, baseChartSettings, baseChartSettingsDark } from '../../../utils/chart.utils';
import { useColors } from '../../../utils/color.utils';
import { ProductContext, useIsDarkTheme, useIsMobile } from '../../../utils/context.utils';
import { toPercent } from '../../../utils/math.utils';
import { remoteData, useRemoteData } from '../../../utils/remote-data.utils';
import { renderPercent } from '../../../utils/string.utils';
import { Widget } from '../../layout/Widget';
import { GlossaryHint } from '../../ui-kit/GlossaryHint';
import { getBarchartOptions } from '../barchart/BarchartWidget';
import css from './LiquidityWidget.module.less';
import AutoSizer from 'react-virtualized-auto-sizer';

interface DataItem {
	category: string;
	value: number;
}

interface LiquidityWidgetProps {
	data: RemoteData<Error, Array<DataItem>>;
}

const LiquidityWidget = memo<LiquidityWidgetProps>(({ data }) => {
	const items = pipe(
		data,
		remoteData.getOrElse(() => empty as DataItem[]),
	);

	const series = useMemo(
		() =>
			pipe(
				data,
				remoteData.getOrElse(() => [] as DataItem[]),
				items => [
					{
						name: 'Cumulative',
						type: 'line',
						data: items.reduce((arr, val) => {
							const prev = arr.length ? arr[arr.length - 1] : 0;
							arr.push(prev + val.value);
							return arr;
						}, [] as number[]),
					},
					{
						name: 'Liquidity',
						type: 'column',
						data: items.map(i => i.value),
					},
				],
			),
		[data],
	);

	const isMobile = useIsMobile();
	const isDark = useIsDarkTheme();
	const chartSettings = isDark ? baseChartSettingsDark : baseChartSettings;
	const colors = useColors();
	const options = useMemo(
		() =>
			deepmerge.all(
				[
					chartSettings,
					getBarchartOptions(isMobile),
					{
						annotations: {
							yaxis: [{ y: 0 }],
						},
						chart: {
							type: 'line' as const,
							stacked: false,
						},
						labels: items.map(i => i.category),
						legend: { show: false },
						markers: {
							size: [5, 0],
						},
						stroke: {
							width: [1, 0],
						},
						colors: [colors[2], colors[0]],
						yaxis: {
							forceNiceScale: false,
							max: identity,
							min: identity,
							tickAmount: 5,
							labels: {
								formatter: (val: number) => renderPercent(val),
							},
						},
					},
				],
				{ arrayMerge: (_, source) => source },
			),
		[chartSettings, items, colors, isMobile],
	);

	return (
		<Col xs={24} lg={12}>
			<Widget
				stretch
				header={
					<Typography.Title level={2}>
						Liquidity <GlossaryHint term="Liquidity" />
					</Typography.Title>
				}
			>
				<AutoSizer disableHeight style={{ height: '100%' }}>
					{({ width }) => (
						<Card bordered={false} className={css.card} style={{ width }}>
							<div className={css.chartArea}>
								<ReactApexChart
									width="100%"
									height="300"
									options={adjustChartOptions(options, series, width)}
									series={series}
								/>
							</div>
						</Card>
					)}
				</AutoSizer>
			</Widget>
		</Col>
	);
});

const ordByDays = pipe(
	ordNumber,
	ord.contramap((val: string) => {
		if (/over/i.test(val)) {
			return Infinity;
		}
		const m = /\d+/.exec(val);
		return m ? Number(m[0]) : 0;
	}),
	ord.contramap((item: DataItem) => item.category),
);

interface LiquidityWidgetContainerProps {}

const QUERY = gql`
	query GetProductLiquidity($product: String!) {
		products(ids: [$product]) {
			id
			generationDate
			liquidity {
				label
				value
			}
		}
	}
`;

export const LiquidityWidgetContainer = memo<LiquidityWidgetContainerProps>(() => {
	const { selectedProduct } = useContext(ProductContext);
	const { data, loading, error } = useQuery<GetProductLiquidity>(QUERY, {
		variables: { product: selectedProduct?.id ?? '' },
		skip: !selectedProduct,
	});

	const dataRaw = useRemoteData(data?.products?.[0]?.liquidity ?? undefined, loading, error);
	const dataRD = useMemo(
		() =>
			pipe(
				dataRaw,
				remoteData.map(
					flow(
						array.map(item => ({
							category: item.label,
							value: toPercent(item.value),
						})),
						array.sort(ordByDays),
					),
				),
			),
		[dataRaw],
	);

	return <LiquidityWidget data={dataRD} />;
});
