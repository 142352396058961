import { Fragment, memo } from 'react';
import cn from 'classnames';
import css from './Sidebar.module.less';
import { ClientSelectorContainer } from '../../ui-kit/FundSelector';
import { ThemeSwitcher } from '../ThemeSwitcher';
import { Button } from 'antd';
import { ReactComponent as LogoutImg } from '../../../icons/logout.svg';
import { useDIValue } from '../../../utils/container.utils';
import { AuthService } from '../../../services/auth.service';
import { PrimaryContactWidgetContainer } from '../../widgets/primary-contact-info/PrimaryContactWidget';

interface SidebarProps {
	visible?: boolean;
	onLogout?: () => void;
	onClose?: () => void;
}

const themeSwitcherTheme = {
	container: css.theme,
};

export const Sidebar = memo<SidebarProps>(({ visible, onLogout, onClose }) => {
	return (
		<Fragment>
			<div className={cn(css.backdrop, visible && css.backdrop_visible)} onClick={onClose} />
			<div className={cn(css.sidebar, visible && css.sidebar_visible)}>
				<ClientSelectorContainer className={css.client} />
				<ThemeSwitcher theme={themeSwitcherTheme} />
				<PrimaryContactWidgetContainer inline />
				<Button type="text" className={css.logout} onClick={onLogout}>
					<LogoutImg className={css.itemIcon} />
					Logout
				</Button>
			</div>
		</Fragment>
	);
});

export const SidebarContainer = memo<Omit<SidebarProps, 'onLogout'>>(props => {
	const auth = useDIValue<AuthService>('auth');
	return <Sidebar onLogout={auth.logout} {...props} />;
});
