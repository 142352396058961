import { Card, Col } from 'antd';
import deepmerge from 'deepmerge';
import { ComponentProps, Fragment, memo, ReactNode, useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';
import {
	adjustChartOptions,
	baseChartSettings,
	baseChartSettingsDark,
	chartClassName,
} from '../../../utils/chart.utils';
import { useIsDarkTheme, useIsMobile } from '../../../utils/context.utils';
import { Widget } from '../../layout/Widget';
import css from './BarchartWidget.module.less';
import { Legend } from '../../ui-kit/Legend';
import cn from 'classnames';
import { RemoteData, success } from '@devexperts/remote-data-ts';
import { remoteData } from '../../../utils/remote-data.utils';
import { pipe } from 'fp-ts/lib/pipeable';
import { renderRemoteData } from '../../ui-kit/Loading';
import { ApexOptions } from 'apexcharts';
import AutoSizer from 'react-virtualized-auto-sizer';

interface BarchartData {
	series: Array<{
		name: string;
		data: number[];
	}>;
	categories: string[];
}

interface BarchartWidgetProps extends Partial<BarchartData> {
	data?: RemoteData<Error, BarchartData>;
	noLegend?: boolean;
	header: ReactNode;
	beforeChart?: ReactNode;
	chartProps?: Partial<ComponentProps<typeof ReactApexChart>>;
	adjustOptions?: (options: ApexOptions, width: number) => ApexOptions;
}

const noAdjustOptions = (options: ApexOptions) => options;

export const getBarchartOptions = (isMobile: boolean) => ({
	plotOptions: {
		bar: {
			columnWidth: isMobile ? '40px' : '20px',
			borderRadius: 4,
		},
	},
});

export const BarchartWidget = memo<BarchartWidgetProps>(
	({
		data: dataProp,
		series,
		beforeChart,
		noLegend,
		categories,
		header,
		chartProps,
		adjustOptions = noAdjustOptions,
	}) => {
		const data = useMemo(
			() => dataProp ?? success({ series: series!, categories: categories! }),
			[dataProp, series, categories],
		);
		const isMobile = useIsMobile();
		const chartSettings = useIsDarkTheme() ? baseChartSettingsDark : baseChartSettings;
		const chartData = useMemo(
			() =>
				pipe(
					data,
					remoteData.map(data => {
						const legendItems = data.series.map((s, i) => ({
							title: s.name,
							color: chartSettings.colors[i],
						}));
						const options = deepmerge.all([
							chartSettings,
							getBarchartOptions(isMobile),
							{
								chart: {
									type: 'bar',
									stacked: true,
								},
								dataLabels: { enabled: false },
								yaxis: {
									labels: {
										formatter: (x: number) => Math.round(x),
									},
								},
								xaxis: {
									categories: data.categories,
									labels: {
										hideOverlappingLabels: true,
									},
								},
								legend: { show: false },
							},
							chartProps?.options ?? {},
						]);
						return { series: data.series, legendItems, options };
					}),
				),
			[data, chartSettings, chartProps?.options, isMobile],
		);

		return (
			<Col xs={24} lg={12}>
				<Widget stretch header={header}>
					<AutoSizer disableHeight style={{ height: '100%' }}>
						{({ width }) => (
							<Card bordered={false} className={css.card} style={{ width }}>
								{beforeChart}
								{renderRemoteData(chartData, {
									success: data => {
										return (
											<Fragment>
												<div className={cn(css.chartArea, chartClassName)}>
													<ReactApexChart
														type="bar"
														width="100%"
														height="100%"
														{...chartProps}
														options={adjustChartOptions(
															adjustOptions(data.options, width),
															data.series,
															width,
														)}
														series={data.series}
													/>
												</div>
												{!noLegend && <Legend items={data.legendItems} />}
											</Fragment>
										);
									},
								})}
							</Card>
						)}
					</AutoSizer>
				</Widget>
			</Col>
		);
	},
);
