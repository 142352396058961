import { Typography } from 'antd';
import React, { HTMLAttributes, PureComponent, ReactNode } from 'react';
import css from './Widget.module.less';
import cn from 'classnames';

type WidgetProps = HTMLAttributes<HTMLDivElement> & {
	type?: 'bordered';
	header?: ReactNode;
	flex?: string;
	stretch?: boolean;
	contentClassName?: string;
};

export class Widget extends PureComponent<WidgetProps> {
	state = { isFailed: false };

	render() {
		if (this.state.isFailed) {
			return <div>Error!</div>;
		}

		const { children, header, flex, className, contentClassName, stretch, ...rest } = this.props;
		const headerEl = typeof header === 'string' ? <Typography.Title level={2}>{header}</Typography.Title> : header;
		return (
			<div
				{...rest}
				style={{ ...rest.style, flex }}
				className={cn(css.widget, stretch && css.widget_stretch, className)}
			>
				{headerEl}
				<div className={cn(css.content, contentClassName)}>{children}</div>
			</div>
		);
	}
}
