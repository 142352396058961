import { gql, useQuery } from '@apollo/client';
import { RemoteData } from '@devexperts/remote-data-ts';
import { Card, Col, Typography } from 'antd';
import { format, parseISO } from 'date-fns';
import { isSameYear } from 'date-fns/esm';
import deepmerge from 'deepmerge';
import { array } from 'fp-ts';
import { empty } from 'fp-ts/lib/Array';
import { pipe } from 'fp-ts/lib/function';
import { memo, useContext, useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';
import AutoSizer from 'react-virtualized-auto-sizer';
import { nullable } from '../../../../../common/utils/nullable.utils';
import {
	GetProductAumChangeReport,
	GetProductAumChangeReportVariables,
} from '../../../../generated-types/GetProductAumChangeReport';
import { adjustChartOptions, baseChartSettings, baseChartSettingsDark } from '../../../utils/chart.utils';
import { chartColors, colorGreen, colorRed } from '../../../utils/color.utils';
import { ProductContext, useIsDarkTheme, useIsMobile } from '../../../utils/context.utils';
import { makeRemoteData, remoteData } from '../../../utils/remote-data.utils';
import { renderMoney, renderMoneyAsText } from '../../../utils/string.utils';
import { Widget } from '../../layout/Widget';
import { GlossaryHint } from '../../ui-kit/GlossaryHint';
import { getBarchartOptions } from '../barchart/BarchartWidget';
import css from './AumChartWidget.module.less';

interface DataItem {
	date: string;
	subscriptions: number;
	redemptions: number;
	aum: number;
}

interface AumChartWidgetProps {
	data: RemoteData<Error, Array<DataItem>>;
}

const yaxisOptions = deepmerge(baseChartSettings.yaxis, {
	labels: {
		formatter: (val: number) => renderMoneyAsText(val),
	},
});

const AumChartWidget = memo<AumChartWidgetProps>(({ data }) => {
	const isMobile = useIsMobile();
	const items = pipe(
		data,
		remoteData.getOrElse(() => empty as DataItem[]),
	);

	const series = useMemo(
		() => [
			{
				name: 'AUM',
				type: 'line',
				data: items.map(val => val.aum),
			},
			{
				name: 'Subscriptions',
				type: 'column',
				data: items.map(i => i.subscriptions),
			},
			{
				name: 'Redemptions',
				type: 'column',
				data: items.map(i => i.redemptions),
			},
		],
		[items],
	);

	const isDark = useIsDarkTheme();
	const chartSettings = isDark ? baseChartSettingsDark : baseChartSettings;

	const options = useMemo(() => {
		const hasTransfers = items.some(i => i.redemptions !== 0 || i.subscriptions !== 0);
		return deepmerge.all(
			[
				chartSettings,
				getBarchartOptions(isMobile),
				{
					annotations: {
						yaxis: [{ y: 0 }],
					},
					chart: {
						type: 'line' as const,
						stacked: false,
						zoom: { enabled: false },
					},
					tooltip: {
						x: {
							formatter: (index: number) =>
								pipe(
									items[index - 1],
									nullable.map(d => format(parseISO(d.date), "MMM ''yy")),
								) ?? '',
						},
					},
					labels: items.map(i => i.date),
					colors: [chartColors[0], colorGreen, colorRed],
					stroke: {
						width: [2, 0, 0],
					},
					grid: {
						padding: {
							top: 0,
							bottom: 0,
						},
					},
					yaxis: [
						{
							...yaxisOptions,
							opposite: true,
							seriesName: 'AUM',
							floating: isMobile,
							min: 0,
							// Note: this should be rather
							// min: (min: number) => Math.min(0, min),
							// but this fails because of a bug in apexcharts
							// https://github.com/apexcharts/apexcharts.js/issues/4357
						},
						{
							...yaxisOptions,
							seriesName: 'Redemptions',
							show: !isMobile && hasTransfers,
						},
						{
							...yaxisOptions,
							show: !isMobile && hasTransfers,
							seriesName: 'Redemptions',
						},
					],
					xaxis: {
						labels: {
							// hideOverlappingLabels: false,
						},
					},
				},
			],
			{ arrayMerge: (_, source) => source },
		);
	}, [items, isMobile, chartSettings]);

	return (
		<Col xs={24} lg={12}>
			<Widget
				stretch
				header={
					<Typography.Title level={2}>
						AUM Change <GlossaryHint term="AUM Change (Chart)" />
					</Typography.Title>
				}
			>
				<AutoSizer disableHeight>
					{({ width }) => {
						const adjustedOptions = adjustChartOptions(options, series, width);
						const adjustedWidth = width - 160;
						const skip = adjustedWidth / items.length < 20 ? 2 : 1;
						adjustedOptions.labels = items.map((item, i) => {
							if (i % skip !== 0) return '';
							const d = parseISO(item.date);
							const fmt =
								i === 0 ||
								i === items.length - 1 ||
								(i > skip && !isSameYear(d, parseISO(items[i - skip].date)))
									? "MMM ''yy"
									: 'MMM';
							return format(d, fmt);
						});
						return (
							<Card bordered={false} className={css.card} style={{ width }}>
								{isMobile && items.length > 0 && (
									<div className={css.summary}>
										<span>{renderMoney(items[0].aum)}</span>
										<span>&rarr;</span>
										<span>{renderMoney(items[items.length - 1].aum)}</span>
									</div>
								)}
								<div className={css.chartArea}>
									<ReactApexChart
										width="100%"
										height="375"
										options={adjustedOptions}
										series={series}
									/>
								</div>
							</Card>
						);
					}}
				</AutoSizer>
			</Widget>
		</Col>
	);
});

interface AumChartWidgetContainerProps {}

const QUERY = gql`
	query GetProductAumChangeReport($product: String!) {
		products(ids: [$product]) {
			id
			generationDate
			aumChangeReport {
				date
				fundSize
				subscriptions
				redemptions
			}
		}
	}
`;

export const AumChartWidgetContainer = memo<AumChartWidgetContainerProps>(() => {
	const { selectedProduct } = useContext(ProductContext);
	const { data, loading, error } = useQuery<GetProductAumChangeReport, GetProductAumChangeReportVariables>(QUERY, {
		variables: { product: selectedProduct?.id ?? '' },
		skip: !selectedProduct,
	});

	const report = data?.products[0]?.aumChangeReport;
	const dataRD = useMemo(
		() =>
			pipe(
				makeRemoteData(report, loading, error),
				remoteData.map(items =>
					items.map((item, i) => {
						const date = parseISO(item.date);
						return {
							aum: item.fundSize ?? 0,
							redemptions: -(item.redemptions ?? 0),
							subscriptions: item.subscriptions ?? 0,
							date: item.date,
							// i === 0 || i === items.length - 1 || !isSameYear(date, parseISO(items[i - 1].date))
							// ? format(date, "MMM ''yy")
							// : format(date, 'MMM'),
						};
					}),
				) /*[
					{
						date: 'Jul 2020',
						subscriptions: 1000,
						redemptions: 100,
						aum: 100900,
					},
					{
						date: 'Aug 2020',
						subscriptions: 50000,
						redemptions: 0,
						aum: 150900,
					},
					{
						date: 'Sep 2020',
						subscriptions: 0,
						redemptions: 1000,
						aum: 149900,
					},
					{
						date: 'Oct 2020',
						subscriptions: 5000,
						redemptions: 5000,
						aum: 149900,
					},
					{
						date: 'Nov 2020',
						subscriptions: 5000,
						redemptions: 5000,
						aum: 149900,
					},
					{
						date: 'Dec 2020',
						subscriptions: 5000,
						redemptions: 5000,
						aum: 149900,
					},
					{
						date: 'Jan 2020',
						subscriptions: 5000,
						redemptions: 5000,
						aum: 149900,
					},
					{
						date: 'Feb 2020',
						subscriptions: 5000,
						redemptions: 5000,
						aum: 149900,
					},
					{
						date: 'Mar 2020',
						subscriptions: 5000,
						redemptions: 5000,
						aum: 149900,
					},
					{
						date: 'Apr 2020',
						subscriptions: 5000,
						redemptions: 5000,
						aum: 149900,
					},
					{
						date: 'May 2020',
						subscriptions: 5000,
						redemptions: 5000,
						aum: 149900,
					},
					{
						date: 'Jun 2020',
						subscriptions: 5000,
						redemptions: 5000,
						aum: 149900,
					},
				]),*/,
			),
		[report, loading, error],
	);

	return <AumChartWidget data={dataRD} />;
});
