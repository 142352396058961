import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { ThemeContextProvider } from './components/ui-kit/ThemeContextProvider';
import './styles/Raleway.less';
import './antd-theme-root.less';
import './antd-theme-dark-root.less';
import { AppContainer } from './AppContainer';
import reportWebVitals from './reportWebVitals';
import { authService } from './services/auth.service';
import { DIChildContext } from './utils/container.utils';
import { ApolloClient, ApolloProvider, createHttpLink, gql, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { GetProductTimestamps } from '../generated-types/GetProductTimestamps';
import { constVoid } from 'fp-ts/lib/function';

const context = {
	auth: authService(),
};

const httpLink = createHttpLink({
	uri: '/graphql',
	useGETForQueries: true,
});

const authLink = setContext((op, { headers }) =>
	context.auth.getToken().then(token => ({
		headers: {
			...headers,
			Authorization: `Bearer ${token.raw}`,
		},
	})),
);

const client = new ApolloClient({
	cache: new InMemoryCache({
		typePolicies: {
			Product: {
				fields: {
					performance: {
						merge: (existing, incoming) => ({ ...existing, ...incoming }),
					},
				},
			},
		},
	}),
	link: authLink.concat(httpLink),
});

client
	.watchQuery<GetProductTimestamps>({
		query: gql`
			query GetProductTimestamps {
				products {
					id
					generationDate
				}
			}
		`,
		fetchPolicy: 'cache-only',
		nextFetchPolicy: 'cache-only',
	})
	.filter(result => !!result?.data?.products)
	.reduce((prev, curr) => {
		const changed = curr.data.products.filter(
			p => prev.data.products.find(q => q.id === p.id)?.generationDate !== p.generationDate,
		);
		if (changed.length) {
			client.refetchQueries({
				updateCache: cache => {
					cache.modify({
						fields: {
							products: (value, { DELETE }) => {
								return DELETE;
							},
							positions: (value, { DELETE }) => {
								return DELETE;
							},
						},
					});
				},
			});
		}
		return curr;
	})
	.subscribe(constVoid);

ReactDOM.render(
	<React.StrictMode>
		<ThemeContextProvider>
			<ApolloProvider client={client}>
				<DIChildContext value={context}>
					<AppContainer />
				</DIChildContext>
			</ApolloProvider>
		</ThemeContextProvider>
	</React.StrictMode>,
	document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
