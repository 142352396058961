import { Card, Col } from 'antd';
import deepmerge from 'deepmerge';
import { ComponentProps, Fragment, memo, ReactNode, useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useColors } from '../../../utils/color.utils';
import { Widget } from '../../layout/Widget';
import css from './PiechartWidget.module.less';
import { Legend } from '../../ui-kit/Legend';
import { useIsDarkTheme } from '../../../utils/context.utils';
import { baseChartSettings, baseChartSettingsDark } from '../../../utils/chart.utils';
import AutoSizer from 'react-virtualized-auto-sizer';
import { NoDataCover, renderRemoteData } from '../../ui-kit/Loading';
import { pipe } from 'fp-ts/lib/function';
import { remoteData } from '../../../utils/remote-data.utils';
import { RemoteData } from '@devexperts/remote-data-ts';

export interface PiechartWidgetProps {
	data: RemoteData<
		Error,
		Array<{
			name: string;
			value: number;
		}>
	>;
	header: ReactNode;
	beforeChart?: ReactNode;
	chartProps?: Partial<ComponentProps<typeof ReactApexChart>>;
}

export const PiechartWidget = memo<PiechartWidgetProps>(({ data, beforeChart, header, chartProps }) => {
	const colors = useColors();

	const isDarkTheme = useIsDarkTheme();
	const chartSettings = isDarkTheme ? baseChartSettingsDark : baseChartSettings;
	const chartData = useMemo(
		() =>
			pipe(
				data,
				remoteData.map(data => {
					const legendItems = data.map((s, i) => ({
						title: s.name,
						color: colors[i],
					}));
					const series = data.map(d => d.value);
					const options = deepmerge.all(
						[
							chartSettings,
							{
								labels: data.map(d => d.name),
								stroke: {
									show: true,
									width: 4,
									colors: isDarkTheme ? ['#3c3d3f'] : ['#ffffff'],
								},
								dataLabels: {
									dropShadow: {
										enabled: false,
									},
									color: '#FFFFFF',
								},
							},
							chartProps?.options ?? {},
						],
						{ arrayMerge: (_, source) => source },
					);
					return { legendItems, series, options };
				}),
			),
		[data, colors, chartProps?.options, chartSettings, isDarkTheme],
	);

	return (
		<Col xs={24} lg={12}>
			<Widget stretch header={header}>
				<Card bordered={false} className={css.card}>
					{beforeChart}
					{renderRemoteData(chartData, {
						success: data =>
							data.series.length ? (
								<Fragment>
									<div className={css.chartArea}>
										<AutoSizer disableHeight>
											{({ width }) => (
												<div>
													<ReactApexChart
														type="pie"
														width={width}
														height={Math.min(width, 400)}
														{...chartProps}
														options={data.options}
														series={data.series}
													/>
												</div>
											)}
										</AutoSizer>
									</div>
									<Legend items={data.legendItems} />
								</Fragment>
							) : (
								<NoDataCover />
							),
					})}
				</Card>
			</Widget>
		</Col>
	);
});
