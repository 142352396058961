import { Select } from 'antd';
import { memo, useMemo, useState } from 'react';
import { nullable } from '../../../../common/utils/nullable.utils';
import { isPreset, Preset, presetToDateRange, TimePeriod } from '../../utils/date.utils';
import { DateRangePicker } from './DateRangePicker';
import css from './TimePeriodSelector.module.less';
import cn from 'classnames';
import { useCurrentDate } from '../../utils/context.utils';

const options: Array<{ value: Preset | 'custom'; label: string }> = [
	{ value: 'custom', label: 'Custom' },
	{ value: 'yesterday', label: 'Yesterday' },
	{ value: 'wtd', label: 'WTD' },
	{ value: 'mtd', label: 'MTD' },
	{ value: '1m', label: '1M' },
	{ value: 'qtd', label: 'QTD' },
	{ value: '3m', label: '3M' },
	{ value: 'ytd', label: 'YTD' },
	{ value: '12m', label: '12M' },
	{ value: 'all', label: 'All' },
];

export const allPresets = options.map(opt => opt.value);

export const allPresetsExcept = (...exclude: Array<Preset | 'custom'>) => allPresets.filter(p => !exclude.includes(p));

export interface TimePeriodSelectorProps {
	value?: TimePeriod;
	onChange?: (val: TimePeriod) => void;
	presets?: Array<Preset | 'custom'>;
	theme?: {
		container?: string;
		preset?: string;
		range?: string;
	};
}

export const TimePeriodSelector = memo<TimePeriodSelectorProps>(({ theme, value, onChange, presets }) => {
	const [type, setType] = useState(getPeriodType(value));
	const [range, setRange] = useState<[Date, Date] | undefined>(value?.range);
	const now = useCurrentDate();

	const handleSetType = (type: string) => {
		const preset = type as Preset;
		if (isPreset(preset)) {
			setType(preset);
			setRange(undefined);
			onChange?.({
				preset,
				range: presetToDateRange(preset, now),
			});
		} else {
			setType('custom');
			setRange(value?.range);
		}
	};

	const handleSetRange = (range?: [Date | null, Date | null]) => {
		if (range && range.every(nullable.isDefined)) {
			setRange(range as [Date, Date]);
			onChange?.({
				preset: undefined,
				range: range as [Date, Date],
			});
		}
	};

	const filteredOptions = useMemo(
		() => (presets ? options.filter(opt => presets.includes(opt.value)) : options),
		[presets],
	);

	return (
		<div className={cn(theme?.container, css.timePeriod)}>
			<div className={cn(theme?.preset, css.preset)}>
				<Select
					listHeight={400}
					dropdownMatchSelectWidth={false}
					options={filteredOptions}
					value={type}
					onChange={handleSetType}
				/>
			</div>
			{type === 'custom' && <DateRangePicker className={theme?.range} value={range} onChange={handleSetRange} />}
		</div>
	);
});

function getPeriodType(val?: TimePeriod): Preset | 'custom' | undefined {
	return val?.preset ?? 'custom';
}
