import { Switch } from 'antd';
import { memo, useContext } from 'react';
import { ThemeContext } from '../../utils/context.utils';
import { ReactComponent as NightImg } from '../../icons/night.svg';
import { ReactComponent as DayImg } from '../../icons/day.svg';
import css from './ThemeSwitcher.module.less';
import cn from 'classnames';

interface ThemeSwitcherTheme {
	container?: string;
	switch?: string;
}

interface ThemeSwitcherProps {
	theme?: ThemeSwitcherTheme;
}

export const ThemeSwitcher = memo<ThemeSwitcherProps>(({ theme: styles }) => {
	const { setTheme, theme } = useContext(ThemeContext);

	return (
		<div className={cn(css.theme, styles?.container)}>
			<span>Theme</span>
			<Switch
				className={cn(css.themeSwitch, styles?.switch)}
				checked={theme === 'dark'}
				checkedChildren={<NightImg />}
				unCheckedChildren={<DayImg />}
				onChange={val => setTheme(val ? 'dark' : 'light')}
			></Switch>
		</div>
	);
});
