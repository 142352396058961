import { failure, initial, pending, RemoteData } from '@devexperts/remote-data-ts';
import { Empty, TableProps } from 'antd';
import { parseISO, parse } from 'date-fns';
import format from 'date-fns/format';
import { pipe } from 'fp-ts/lib/pipeable';
import { Fragment, memo, useCallback, useContext, useMemo, useState } from 'react';
import { ProductContext, useCurrentDate, useIsMobile } from '../../../utils/context.utils';
import { TableWidget } from '../../layout/TableWidget';
import { renderRemoteData } from '../../ui-kit/Loading';
import { ReportCard } from './ReportCard';
import { ReactComponent as FileCsv } from '../../../icons/file-csv.svg';
import { ReactComponent as FileXlsx } from '../../../icons/file-excel.svg';
import { ReactComponent as FilePdf } from '../../../icons/file-pdf.svg';
import css from './ReportsTableWidget.module.less';
import { gql, useApolloClient, useQuery } from '@apollo/client';
import { makeRemoteData, remoteData, useRemoteData } from '../../../utils/remote-data.utils';
import { GetReports } from '../../../../generated-types/GetReports';
import { flow, identity } from 'fp-ts/lib/function';
import { DownloadReport } from '../../../../generated-types/DownloadReport';
import { Spinner } from '../../ui-kit/Spinner';
import { renderOptional } from '../../../utils/string.utils';
import { optional } from '../../../../../common/utils/nullable.utils';
import { ReportFilter } from '../../pages/reports/ReportsPage';
import { array } from 'fp-ts';
import { periodToFilter } from '../../../utils/date.utils';

interface ReportFile {
	id: string;
	format: string;
}
export interface ReportRow {
	client: string;
	fund?: string;
	type: string;
	date?: Date;
	generatedDate?: Date;
	formats: Array<ReportFile>;
	category?: string;
}

function reportRowKey(row: ReportRow) {
	return row.formats.map(f => f.id).join();
}

interface ReportsTableWidgetProps {
	reports: RemoteData<Error, ReportRow[]>;
	clientLevel: boolean;
}

function extensionToFormat(ext: string): 'pdf' | 'csv' | 'xlsx' | undefined {
	switch (ext) {
		case 'pdf':
			return 'pdf';
		case 'csv':
			return 'csv';
		case 'xlsx':
		case 'xls':
			return 'xlsx';
		default:
			return undefined;
	}
}

export function renderFormatIcon(ext: string) {
	const type = extensionToFormat(ext);
	switch (type) {
		case 'csv':
			return <FileCsv />;
		case 'xlsx':
			return <FileXlsx />;
		case 'pdf':
			return <FilePdf />;
		default:
			return ext.toUpperCase() + ' ';
	}
}

const DOWNLOAD_QUERY = gql`
	query DownloadReport($client: String!, $fund: String, $id: String!) {
		reportContent(client: $client, fund: $fund, id: $id) {
			content
			filename
		}
	}
`;

export const useReportDownload = () => {
	const client = useApolloClient();
	const [result, setResult] = useState<RemoteData<Error, unknown>>(initial);
	const onDownload = useCallback(
		(clientName: string, fund: string | undefined, id: string) => {
			setResult(pending);
			client
				.query<DownloadReport>({
					query: DOWNLOAD_QUERY,
					variables: { client: clientName, fund, id },
					fetchPolicy: 'no-cache',
				})
				.then(
					({ data, loading, error }) => {
						const result = makeRemoteData(data.reportContent, loading, error);
						if (remoteData.isSuccess(result)) {
							const a = document.createElement('a');
							a.href = result.value.content;
							a.download = result.value.filename ?? 'Report';
							document.body.appendChild(a);
							a.click();
							document.body.removeChild(a);
						}
						setResult(result);
					},
					() => {
						setResult(failure(new Error()));
					},
				);
		},
		[client],
	);
	return { result, onDownload };
};

const DownloadIcon = (props: { client: string; fund?: string; file: ReportFile }) => {
	const { result, onDownload } = useReportDownload();
	return (
		<a
			href="#"
			onClick={e => {
				e.preventDefault();
				onDownload(props.client, props.fund, props.file.id);
			}}
			className={css.fileFormat}
		>
			{remoteData.isPending(result) ? (
				<Spinner size={16} className={css.fileFormatSpinner} />
			) : (
				renderFormatIcon(props.file.format)
			)}
		</a>
	);
};

const columnConfigs: TableProps<ReportRow>['columns'] = [
	{
		dataIndex: 'formats',
		key: 'formats',
		title: '',
		render: (formats: ReportFile[], row: ReportRow) => {
			return formats.map(x => <DownloadIcon key={x.id} client={row.client} fund={row.fund} file={x} />);
		},
		width: 100,
		className: css.fileFormatCol,
	},
	{
		dataIndex: 'category',
		key: 'category',
		title: 'Category',
		render: renderOptional,
		width: 'max(200px, 15vw)',
	},
	{
		dataIndex: 'type',
		key: 'type',
		title: 'Report Name',
	},
	{
		dataIndex: 'date',
		key: 'date',
		title: 'Reporting Date',
		width: 200,
		render: flow(
			optional.map(date => format(date, 'yyyy-MM-dd')),
			renderOptional,
		),
	},
];

export const ReportsTableWidget = memo<ReportsTableWidgetProps>(({ reports, clientLevel }) => {
	const isMobile = useIsMobile();

	return isMobile ? (
		<Fragment>
			{renderRemoteData(reports, {
				success: rows =>
					rows.length ? (
						rows.map(row => <ReportCard row={row} key={reportRowKey(row)} />)
					) : (
						<Empty
							image={Empty.PRESENTED_IMAGE_SIMPLE}
							description={clientLevel ? 'No company-level reports' : 'No product-level reports'}
						/>
					),
			})}
		</Fragment>
	) : (
		<Fragment>
			<TableWidget<ReportRow>
				// sticky
				dataSource={reports}
				// onChange={handleChangeTable}
				rowKey={reportRowKey}
				columns={columnConfigs /*.map(x => ({ ...x, width: 150 }))*/}
				scroll={{ x: 'auto' }}
				sticky
			/>
		</Fragment>
	);
});

interface ReportsWidgetContainerProps {
	clientLevel?: boolean;
	filter?: ReportFilter;
}

const QUERY = gql`
	query GetReports {
		reports {
			product
			client
			category
			date
			generationDate
			name
			formats {
				id
				format
			}
		}
	}
`;

const applyFilter = (now: Date, filter: ReportFilter | undefined) => {
	if (!filter) return identity;

	const searchValue = filter.search.trim().toLowerCase();
	const dateFilterActive = filter.dateRange?.preset || filter.dateRange?.range;
	const dateFilter = periodToFilter(filter.dateRange ?? {}, now);
	return array.filter((report: ReportRow) => {
		let match = filter.products.length === 0 || (!!report.fund && filter.products.includes(report.fund));
		match =
			match &&
			(searchValue === '' ||
				!!(
					report.category?.toLowerCase().includes(searchValue) ||
					report.type?.toLowerCase().includes(searchValue)
				));
		if (dateFilterActive) {
			match = match && !!report.date && dateFilter(report.date);
		}
		return match;
	});
};

export const ReportsWidgetContainer = memo<ReportsWidgetContainerProps>(({ clientLevel = false, filter }) => {
	const { selectedClient, selectedProduct } = useContext(ProductContext);
	const { data, loading, error } = useQuery<GetReports>(QUERY);
	const now = useCurrentDate();

	const dataRaw = useRemoteData(data, loading, error);
	const dataRD = useMemo(
		() =>
			pipe(
				dataRaw,
				remoteData.map(
					flow(
						reports =>
							reports.reports
								.filter(r =>
									clientLevel
										? r.client === selectedClient && !optional.isDefined(r.product)
										: r.product === selectedProduct?.id,
								)
								.map(report => ({
									client: report.client,
									type: report.name,
									fund: report.product ?? undefined,
									date: report.date ? parse(report.date, 'yyyy-MM-dd', new Date(0)) : undefined,
									generatedDate: report.generationDate ? parseISO(report.generationDate) : undefined,
									formats: report.formats,
									category: report.category ?? 'Uncategorized',
								})),
						applyFilter(now, filter),
					),
				),
			),
		[dataRaw, selectedProduct, selectedClient, clientLevel, filter, now],
	);
	return <ReportsTableWidget reports={dataRD} clientLevel={clientLevel} />;
});
