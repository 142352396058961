import { gql, useQuery } from '@apollo/client';
import { Typography } from 'antd';
import { pipe } from 'fp-ts/lib/pipeable';
import { memo, useContext, useMemo } from 'react';
import { GetProductCostOfCarry } from '../../../../generated-types/GetProductCostOfCarry';
import { chartColors } from '../../../utils/color.utils';
import { ProductContext } from '../../../utils/context.utils';
import { toPercent } from '../../../utils/math.utils';
import { remoteData, useRemoteData } from '../../../utils/remote-data.utils';
import { renderPercent } from '../../../utils/string.utils';
import { GlossaryHint } from '../../ui-kit/GlossaryHint';
import { BarchartWidget } from '../barchart/BarchartWidget';

interface CostOfCarryWidgetContainerProps {}

const QUERY = gql`
	query GetProductCostOfCarry($product: String!) {
		products(ids: [$product]) {
			id
			generationDate
			carryCost {
				label
				value
			}
		}
	}
`;

export const CostOfCarryWidgetContainer = memo<CostOfCarryWidgetContainerProps>(() => {
	const { selectedProduct } = useContext(ProductContext);
	const { data, loading, error } = useQuery<GetProductCostOfCarry>(QUERY, {
		variables: { product: selectedProduct?.id ?? '' },
		skip: !selectedProduct,
	});

	const dataRaw = useRemoteData(data?.products?.[0]?.carryCost ?? undefined, loading, error);
	const dataRD = useMemo(
		() =>
			pipe(
				dataRaw,
				remoteData.map(data => ({
					categories: data.map(c => c.label),
					series: [{ name: 'Cost of Carry', data: data.map(d => toPercent(d.value)) }],
				})),
			),
		[dataRaw],
	);

	return (
		<BarchartWidget
			header={
				<Typography.Title level={2}>
					Carry Cost <GlossaryHint term={/carry cost/i} />
				</Typography.Title>
			}
			data={dataRD}
			noLegend
			chartProps={{
				options: {
					annotations: {
						yaxis: [{ y: 0 }],
					},
					stroke: {
						show: true,
						width: 2,
						colors: chartColors,
					},
					yaxis: {
						labels: {
							formatter: (val: number) => renderPercent(val),
						},
					},
				},
			}}
		/>
	);
});
