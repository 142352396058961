import { Card } from 'antd';
import { memo, PropsWithChildren } from 'react';
import css from './MetricsWidgetWrapper.module.less';

interface MetricsWidgetWrapperProps {}

export const MetricsWidgetWrapper = memo<PropsWithChildren<MetricsWidgetWrapperProps>>(({ children }) => {
	return (
		<Card className={css.multiWrapper} bordered={false}>
			{children}
		</Card>
	);
});
