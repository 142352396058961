import { ApolloError } from '@apollo/client';
import * as remoteData from '@devexperts/remote-data-ts';
import { createRemoteDataFromJSON, failure, pending, RemoteData, success } from '@devexperts/remote-data-ts';
import { pipe } from 'fp-ts/lib/function';
import { unknown } from 'io-ts';
import { ReactNode, useMemo } from 'react';
import { optional, Optional } from '../../../common/utils/nullable.utils';

export { remoteData };

export function makeRemoteData<A>(data: Optional<A>, loading: boolean, error?: ApolloError): RemoteData<Error, A> {
	return loading ? pending : optional.isDefined(data) ? success(data) : failure(error ?? new Error());
}

export function useRemoteData<A>(data: Optional<A>, loading: boolean, error?: ApolloError): RemoteData<Error, A> {
	return useMemo(() => makeRemoteData(data, loading, error), [data, loading, error]);
}

const remoteDataCodec = createRemoteDataFromJSON(unknown, unknown);
export function isRemoteData(obj: unknown): obj is RemoteData<unknown, unknown> {
	return remoteDataCodec.is(obj);
}
