import { Card } from 'antd';
import { memo, useState } from 'react';
import {
	renderMoney,
	renderNumGrouped,
	renderOption,
	renderOptional,
	renderOptionalPercent,
	renderOptionPercent,
	renderPercent,
	renderQty,
} from '../../utils/string.utils';
import { Position } from '../widgets/positions/PositionsWidget';
import css from './PositionCard.module.less';
import cn from 'classnames';

interface PositionCardProps {
	position: Position;
}

export const PositionCard = memo<PositionCardProps>(({ position }) => {
	const [expanded, setExpanded] = useState(false);
	return (
		<Card className={css.card} onClick={() => setExpanded(x => !x)}>
			<div className={css.row1}>
				<span className={css.ticker}>{position.productName}</span>
				<div className={css.metrics}>
					<span className={css.qty}>{renderNumGrouped(position.quantity)}</span>
					<span className={css.value}>{renderMoney(position.value, position.currency ?? undefined)}</span>
				</div>
			</div>
			<div className={cn(css.props, expanded && css.props_expand)}>
				<div className={css.propsInner}>
					<div className={css.prop}>
						<span className={css.label}>Instrument:</span>
						{position.instrument}
					</div>
					<div className={css.prop}>
						<span className={css.label}>Side:</span>
						{position.side}
					</div>
					<div className={css.prop}>
						<span className={css.label}>Currency:</span>
						{position.currency}
					</div>
					<div className={css.prop}>
						<span className={css.label}>Open Price:</span>
						{renderMoney(position.openPrice, position.currency ?? undefined)}
					</div>
					{/* <div className={css.prop}>
						<span className={css.label}>Last Price:</span>
						{renderMoney(position.lastPrice, position.currency ?? undefined)}
					</div>
					<div className={css.prop}>
						<span className={css.label}>P&amp;L:</span>
						{renderMoney(position.profitLoss, position.currency ?? undefined)}
					</div>
					<div className={css.prop}>
						<span className={css.label}>Exposure % of NAV:</span>
						{renderOptionalPercent(position.exposurePctOfNav)}
					</div> */}
					<div className={css.prop}>
						<span className={css.label}>Alert:</span>
						{renderOptional(position.stopLossAlert)}
					</div>
					<div className={css.prop}>
						<span className={css.label}>Country:</span>
						{position.country}
					</div>
				</div>
			</div>
		</Card>
	);
});
