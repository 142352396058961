import { Fragment, memo, useState } from 'react';
import { ReportRow, renderFormatIcon, useReportDownload } from './ReportsTableWidget';
import css from './ReportCard.module.less';
import cardCss from '../../ui-kit/PositionCard.module.less';
import { Card } from 'antd';
import format from 'date-fns/format';
import cn from 'classnames';
import { ReactComponent as DownloadSvg } from '../../../icons/download.svg';
import { renderDate, renderOptional } from '../../../utils/string.utils';
import { remoteData } from '../../../utils/remote-data.utils';
import { Spinner } from '../../ui-kit/Spinner';
import { optional } from '../../../../../common/utils/nullable.utils';
import { pipe } from 'fp-ts/lib/pipeable';

export const ReportCard = memo<{ row: ReportRow }>(({ row }) => {
	const [expanded, setExpanded] = useState(false);
	const { result, onDownload } = useReportDownload();
	return (
		<Card className={css.card} onClick={() => setExpanded(x => !x)}>
			<div className={css.row1}>
				<span className={css.ticker}>{row.type}</span>
				<div>
					<span className={css.date}>
						{pipe(
							row.date,
							optional.map(date => format(date, 'yyyy-MM-dd')),
							renderOptional,
						)}
					</span>
				</div>
			</div>
			<div className={css.row2}>
				{row.formats.slice(0, 3).map((f, i) => (
					<div key={i} className={cn(css.formatIcon, css[`formatIcon_${i}`])}>
						{renderFormatIcon(f.format)}
						<span className={css.sep}>·</span>
						{f.format.toUpperCase()}
					</div>
				))}
			</div>
			<div className={cn(cardCss.props, expanded && cardCss.props_expand)}>
				<div className={cardCss.propsInner}>
					<div className={cardCss.prop}>
						<span className={cardCss.label}>Reporting date:</span>
						{pipe(row.generatedDate, optional.map(renderDate), renderOptional)}
					</div>
					<div className={cardCss.prop}>
						<span className={cardCss.label}>Category:</span>
						{row.category}
					</div>
					<div className={cn(cardCss.prop, css.downloadLinks)}>
						<span className={cardCss.label}>
							Download{' '}
							{row.formats.map((f, i) => (
								<Fragment key={i}>
									{i > 0 && ', '}
									<a
										href="#"
										className={css.downloadLink}
										onClick={e => {
											e.stopPropagation();
											onDownload(row.client, row.fund, f.id);
										}}
									>
										{f.format.toUpperCase()}{' '}
										{remoteData.isPending(result) ? <Spinner size={14} /> : <DownloadSvg />}
									</a>
								</Fragment>
							))}
						</span>
					</div>
				</div>
			</div>
		</Card>
	);
});
