import { Card, CardProps } from 'antd';
import { memo, ReactNode } from 'react';
import { HexagonIcon } from './Hexagon';
import css from './MetricCard2.module.less';
import cn from 'classnames';
import { useIsDarkTheme } from '../../utils/context.utils';

export interface MetricCardTheme {
	card?: string;
	iconFill?: string;
	iconSize?: number;
	valueRow?: string;
}

export type MetricCardProps = CardProps & {
	icon?: ReactNode;
	chart?: ReactNode;
	title: ReactNode;
	value: ReactNode;
	change?: ReactNode;
	theme?: MetricCardTheme;
};

export const MetricCard2 = memo<MetricCardProps>(({ icon, chart, title, value, change, theme, ...rest }) => {
	const isDark = useIsDarkTheme();
	const iconFill = theme?.iconFill ?? (isDark ? '#2D2D2D' : '#F4F5FA');
	return (
		<Card bordered={false} {...rest} className={cn(css.card, rest.className, theme?.card)}>
			{icon && (
				<HexagonIcon size={theme?.iconSize ?? 50} fill={iconFill} style={{ float: 'left', marginRight: 10 }}>
					{icon}
				</HexagonIcon>
			)}
			<div className={cn(css.value, theme?.valueRow)}>{value}</div>
			<div className={cn(css.title)}>{title}</div>
		</Card>
	);
});
