import deepmerge from 'deepmerge';
import { chartColors, colorGray, darkColors } from './color.utils';
import css from './Chart.module.less';
import { ApexOptions } from 'apexcharts';

export const chartClassName = css.chart;

export const baseChartSettings = {
	grid: {
		borderColor: '#F4F5FA',
		xaxis: { lines: { show: true } },
		yaxis: { lines: { show: true } },
	},
	yaxis: {
		labels: {
			style: {
				fontSize: '15px',
			},
		},
	},
	legend: { show: false },
	chart: {
		fontFamily: 'Raleway, sans-serif',
		foreColor: colorGray,
		toolbar: {
			show: false,
		},
	},
	dataLabels: {
		style: {
			fontFamily: 'Raleway, sans-serif',
			fontSize: '15px',
		},
	},
	colors: chartColors,
	xaxis: {
		axisBorder: {
			color: '#F4F5FA',
		},
		axisTicks: {
			color: '#F4F5FA',
		},
		labels: {
			style: {
				fontSize: '15px',
			},
		},
	},
};

export const baseChartSettingsDark = deepmerge(
	baseChartSettings,
	{
		grid: {
			borderColor: '#2D2D2D',
		},
		xaxis: {
			axisBorder: {
				color: '#2D2D2D',
			},
			axisTicks: {
				color: '#2D2D2D',
			},
		},
		colors: darkColors,
	},
	{
		arrayMerge: (_, source) => source,
	},
);

export function getBarCount(opts: ApexOptions, series: any): number {
	if (Array.isArray(opts.xaxis?.categories)) {
		return opts.xaxis!.categories.length;
	}
	if (Array.isArray(series) && series.length) {
		const total = series.reduce(
			(total, ser) =>
				typeof ser === 'object' && Array.isArray(ser.data) && (ser.type === 'column' || !ser.type)
					? total + ser.data.length
					: total,
			0,
		);
		if (total) {
			return total;
		}
	}
	return 1;
}

export function adjustChartOptions(opts: ApexOptions, series: any, width: number): ApexOptions {
	const columnWidth = opts.plotOptions?.bar?.columnWidth;
	if (width && typeof columnWidth === 'string' && columnWidth.endsWith('px')) {
		const pxWidth = parseFloat(columnWidth);
		const pctWidth = (pxWidth / ((width - 100) / getBarCount(opts, series))) * 100;
		return deepmerge(opts, {
			plotOptions: {
				bar: {
					columnWidth: `${Math.min(pctWidth, 90)}%`,
				},
			},
		});
	}
	return opts;
}
