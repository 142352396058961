import { gql, useQuery } from '@apollo/client';
import { useContext } from 'react';
import { GetProductTrades } from '../../generated-types/GetProductTrades';
import { ProductContext } from './context.utils';
import { useRemoteData } from './remote-data.utils';

const QUERY = gql`
	query GetProductTrades($product: String!) {
		products(ids: [$product]) {
			id
			generationDate
			trades {
				tradeDate
				instrument
				productName
				bloombergTicker
				baseCurrency
				price
				quantity
				settleAmount
				brokerGroup
				trader
				side
				executionVenue
			}
		}
	}
`;

export const useTradesData = () => {
	const { selectedProduct } = useContext(ProductContext);
	const { data, loading, error } = useQuery<GetProductTrades>(QUERY, {
		variables: { product: selectedProduct?.id ?? '' },
		skip: !selectedProduct,
	});

	return useRemoteData(data?.products[0]?.trades ?? undefined, loading, error);
};
