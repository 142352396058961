import React, { useContext } from 'react';
import { success } from '@devexperts/remote-data-ts';
import { FundOverviewWidget, getWrapperPaddings, ProductOverview } from './FundOverviewWidget';
import { gql, useQuery } from '@apollo/client';
import { GetProductOverviews } from '../../../../generated-types/GetProductOverviews';
import { array, option } from 'fp-ts';
import { ProductContext, useIsMobile } from '../../../utils/context.utils';
import { Col } from 'antd';
import css from './FundOverviewWidget.module.less';
import { pipe } from 'fp-ts/lib/pipeable';
import { remoteData } from '../../../utils/remote-data.utils';
import { MetricsWidgetWrapper } from '../metrics/MetricsWidgetWrapper';
import AutoSizer from 'react-virtualized-auto-sizer';
import { toPercent } from '../../../utils/math.utils';

const QUERY = gql`
	query GetProductOverviews {
		products {
			id
			generationDate
			name
			type
			navPerShare
			dailyNavPerShareChangePct
			aum
			grossExposurePct
			netExposure
			tradesPrevDay
			primaryShareClass
			currency
			belongsToClient
		}
	}
`;

export const FundOverviewWidgetContainer = () => {
	const { selectedClient, selectedProduct } = useContext(ProductContext);
	const { data } = useQuery<GetProductOverviews>(QUERY);

	const funds: ProductOverview[] = (data?.products ?? [])
		.filter(p => p.belongsToClient === selectedClient)
		.map(p => ({
			product: {
				code: p.id,
				currencyCode: p.currency ?? '-',
				name: p.name,
				productType: p.type ?? '-',
			},
			nav: option.fromNullable(p.navPerShare),
			aum: option.fromNullable(p.aum),
			grossExposure: pipe(option.fromNullable(p.grossExposurePct), option.map(toPercent)),
			netExposure: option.fromNullable(p.netExposure),
			dailyChange: pipe(option.fromNullable(p.dailyNavPerShareChangePct), option.map(toPercent)),
			lastDayTrades: option.fromNullable(p.tradesPrevDay),
			primaryShareClass: option.fromNullable(p.primaryShareClass),
		}));

	if (useIsMobile()) {
		const fund = pipe(
			funds,
			array.findFirst(f => f.product.code === selectedProduct?.id),
			o => remoteData.fromOption(o, () => new Error()),
		);
		return (
			<Col xs={24}>
				<AutoSizer disableHeight>
					{({ width }) => (
						<FundOverviewWidget theme={'single'} withDetails={false} product={fund} width={width} />
					)}
				</AutoSizer>
			</Col>
		);
	}

	const hasMultiple = funds.length > 1;

	return (
		<Col xs={24}>
			<div>
				<AutoSizer disableHeight>
					{({ width }) => {
						const horizPadding = getWrapperPaddings(width);
						const rows = funds
							.map(f => (
								<FundOverviewWidget
									theme={hasMultiple ? 'multiple' : 'single'}
									withDetails={!hasMultiple}
									width={width}
									product={success(f)}
									key={f.product.code}
								/>
							))
							.map(widget =>
								hasMultiple ? (
									<div
										className={css.widgetWithSep}
										style={{ paddingLeft: horizPadding, paddingRight: horizPadding }}
									>
										{widget}
									</div>
								) : (
									widget
								),
							);
						return (
							<div style={{ width }}>
								{hasMultiple ? (
									<MetricsWidgetWrapper>{rows}</MetricsWidgetWrapper>
								) : (
									// <Card className={css.multiWrapper} bordered={false}>
									// </Card>
									rows
								)}
							</div>
						);
					}}
				</AutoSizer>
			</div>
		</Col>
	);
};
