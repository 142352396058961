import { option, ord } from 'fp-ts';
import { Fragment, ReactNode } from 'react';
import { Nullable, Optional, optional } from '../../../common/utils/nullable.utils';
import css from './string.utils.module.less';
import { format } from 'date-fns';
import { ordNumber, ordString } from 'fp-ts/lib/Ord';
import { pipe } from 'fp-ts/lib/pipeable';

export const NBSP = ' ';
export const MDASH = '—';
export const NA = 'N/A';
export const THINSP = '\u2009';

export const currencies: Record<string, string> = {
	USD: '$',
	AUD: '$',
	NZD: '$',
	CAD: '$',
	EUR: '€',
	GBP: '£',
	JPY: '¥',
};

const fixed2Format = new Intl.NumberFormat('en-US', {
	maximumFractionDigits: 2,
	minimumFractionDigits: 2,
	useGrouping: true,
});
const fixed1Format = new Intl.NumberFormat('en-US', {
	maximumFractionDigits: 1,
	minimumFractionDigits: 1,
	useGrouping: true,
});
const precision5Format = new Intl.NumberFormat('en-US', {
	maximumSignificantDigits: 5,
	useGrouping: true,
});
const percentFormat = new Intl.NumberFormat('en-US', {
	maximumFractionDigits: 2,
	useGrouping: true,
});
const groupedFormat = new Intl.NumberFormat('en-US', {
	useGrouping: true,
});

export const renderMoney = (num: Optional<number>, currencyCode?: string): ReactNode => {
	if (!optional.isDefined(num)) {
		return MDASH;
	}
	const curr = currencyCode ? (
		<Fragment>
			<span className={css.currency}>{currencyCode}</span>
			{THINSP}
		</Fragment>
	) : (
		''
	);
	return (
		<span>
			{curr}
			{renderMoneyCommon(num)}
		</span>
	);
};

export const renderMoneyAsText = (num: Optional<number>, currencyCode?: string): string => {
	if (!optional.isDefined(num)) {
		return MDASH;
	}
	const curr = currencyCode ? `${currencyCode}${THINSP}` : '';
	return `${curr}${renderMoneyCommon(num)}`;
};

const renderMoneyCommon = (num: number): string => {
	const sign = num < 0 ? '-' : '';

	const abs = Math.abs(num);
	const range = Math.log10(abs);
	if (range >= 9) {
		return `${sign}${fixed2Format.format(abs / 1e9)}${NBSP}B`;
	} else if (range >= 6) {
		return `${sign}${fixed2Format.format(abs / 1e6)}${NBSP}M`;
	} else if (range >= 5) {
		return `${sign}${fixed1Format.format(abs / 1e3)}${NBSP}k`;
	} else {
		return `${sign}${(range >= 4 ? precision5Format : fixed2Format).format(abs)}`;
	}
};

export const renderQty = (num: Nullable<number>): ReactNode => renderMoney(num);

export const renderNumGrouped = (num: Nullable<number>): ReactNode =>
	pipe(
		num,
		optional.fold(() => MDASH, groupedFormat.format),
	);

export const renderNumFixed2 = (num: Nullable<number>): ReactNode =>
	pipe(
		num,
		optional.fold(() => MDASH, fixed2Format.format),
	);

export const renderPercent = (num: number) => {
	if (num === 0) {
		return '0%';
	} else if (Math.abs(num) < 0.01) {
		return '0.00%';
	} else if (Math.abs(num) >= 1000) {
		return percentFormat.format(Math.round(num)) + '%';
	} else {
		return percentFormat.format(num) + '%';
	}
};

export const renderOption = option.getOrElse<ReactNode>(() => MDASH);
export const renderOptional = optional.getOrElse<ReactNode>(() => MDASH);

export const renderOptionMoney = option.fold(() => MDASH, renderMoney);
export const renderOptionPercent = option.fold(() => MDASH, renderPercent);
export const renderOptionalPercent = optional.fold(() => MDASH, renderPercent);

export const renderDate = (date: Optional<Date>) => (date ? format(date, 'MM/dd/yyyy') : MDASH);

export const toLowerCase = (a: string) => a.toLowerCase();
export const ordStringCaseInsensitive = ord.contramap(toLowerCase)(ordString);
export const ordStringLength = ord.contramap((x: string) => x.length)(ordNumber);
