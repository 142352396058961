import { Card, Col } from 'antd';
import { memo, useContext } from 'react';
import { Widget } from '../../layout/Widget';
import css from './EventsWidget.module.less';
import { RemoteData } from '@devexperts/remote-data-ts';
import { pipe } from 'fp-ts/lib/function';
import { renderRemoteData } from '../../ui-kit/Loading';
import { array } from 'fp-ts';
import { makeBy } from 'fp-ts/lib/Array';
import { addDays, format, isSameDay, parseISO } from 'date-fns';
import cn from 'classnames';
import { ProductContext, useIsMobile, useCurrentDate } from '../../../utils/context.utils';
import { gql, useQuery } from '@apollo/client';
import { remoteData, useRemoteData } from '../../../utils/remote-data.utils';
import { GetProductUpcomingEvents } from '../../../../generated-types/GetProductUpcomingEvents';
import { WidgetTitle } from '../../ui-kit/WidgetTitle';
import { GlossaryHint } from '../../ui-kit/GlossaryHint';

interface TradingEvent {
	date: Date;
	title: string;
}

interface EventsWidgetProps {
	data: RemoteData<Error, TradingEvent[]>;
}

export const EventsWidget = memo<EventsWidgetProps>(({ data }) => {
	const isHorizontal = !useIsMobile();
	const now = useCurrentDate();
	return (
		<Col xs={24} xxl={18}>
			<Widget
				stretch
				header={
					<WidgetTitle>
						Upcoming Events&nbsp;
						<GlossaryHint term={/Upcomm?ing Events/i} />
					</WidgetTitle>
				}
			>
				<Card
					bordered={false}
					className={cn(css.card, isHorizontal && css.horizontal)}
					style={{ maxHeight: '310px', overflow: 'auto' }}
				>
					{renderRemoteData(data, {
						success: events => {
							return pipe(
								makeBy(7, i => addDays(now, i)),
								array.map(date => {
									const dayEvents = events.filter(e => isSameDay(e.date, date));
									return (
										<div className={css.row} key={format(date, 'yyyy-MM-dd')}>
											{isHorizontal ? (
												<div className={css.date}>{format(date, 'MMM d, EEE')}</div>
											) : (
												<div className={css.date}>
													<div className={css.month}>{format(date, 'MMM ')}</div>
													<div className={css.day}>{format(date, 'd')}</div>
													<div className={css.weekDay}>{format(date, 'EEE')}</div>
												</div>
											)}
											<div className={css.events}>
												{dayEvents.length ? (
													dayEvents.map((e, i) => (
														<div className={css.event} key={`${i}-${e.title}`}>
															{e.title}
														</div>
													))
												) : (
													<div className={css.noEvents}>No events</div>
												)}
											</div>
										</div>
									);
								}),
							);
						},
					})}
				</Card>
			</Widget>
		</Col>
	);
});

const QUERY = gql`
	query GetProductUpcomingEvents($product: String!) {
		products(ids: [$product]) {
			id
			generationDate
			upcomingEvents {
				date
				events
			}
		}
	}
`;

export const EventsWidgetContainer = () => {
	const { selectedProduct } = useContext(ProductContext);
	const { data, loading, error } = useQuery<GetProductUpcomingEvents>(QUERY, {
		variables: { product: selectedProduct?.id ?? '' },
		skip: !selectedProduct,
	});

	const events = pipe(
		useRemoteData(data, loading, error),
		remoteData.map(data =>
			(data.products[0]?.upcomingEvents ?? []).map(event => ({
				date: parseISO(event.date),
				title: event.events,
			})),
		),
	);

	return <EventsWidget data={events} />;

	/*return (
		<EventsWidget
			data={success([
				{ date: new Date(), title: 'MSFT dividends' },
				{ date: new Date(), title: 'TSLA split' },
				{
					date: new Date(),
					title: 'Berkshire Hathaway out-of-turn shareholder public meeting regarding the upcoming share split',
				},
				{
					date: new Date(),
					title: 'Berkshire Hathaway share split',
				},
				{ date: addDays(new Date(), 2), title: 'AAPL dividends' },
				{ date: addDays(new Date(), 3), title: 'Monthly options expiration' },
			])}
		/>
	);*/
};
