import { ProductDetailsWidget } from './ProductDetailsWidget';
import { useContext } from 'react';
import { ProductContext } from '../../../utils/context.utils';
import { GetProductInfo, GetProductInfoVariables } from '../../../../generated-types/GetProductInfo';
import { gql, useQuery } from '@apollo/client';
import { pending, success, failure } from '@devexperts/remote-data-ts';
import { pipe } from 'fp-ts/lib/pipeable';
import { remoteData } from '../../../utils/remote-data.utils';
import { option } from 'fp-ts';

const QUERY = gql`
	query GetProductInfo($product: String!) {
		products(ids: [$product]) {
			id
			generationDate
			inception
			currency
			type
		}
	}
`;

export const ProductDetailsWidgetContainer = () => {
	const { selectedProduct } = useContext(ProductContext);
	const { data, loading, error } = useQuery<GetProductInfo, GetProductInfoVariables>(QUERY, {
		variables: { product: selectedProduct?.id ?? '' },
		skip: !selectedProduct,
	});

	const value = pipe(
		loading ? pending : data ? success(data) : failure(error ?? new Error()),
		remoteData.map(data => ({
			currencyCode: option.fromNullable(data.products[0]?.currency),
			inceptionDate: option.fromNullable(data.products[0]?.inception),
			productType: option.fromNullable(data.products[0]?.type),
		})),
	);
	return <ProductDetailsWidget product={value} />;
};
