import { initial, RemoteData } from '@devexperts/remote-data-ts';
import { Grid } from 'antd';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';
import { constVoid } from 'fp-ts/lib/function';
import { pipe } from 'fp-ts/lib/pipeable';
import { createContext, useContext } from 'react';
import { optional } from '../../../common/utils/nullable.utils';

export interface ProductOption {
	name: string;
	id: string;
	belongsToClient: string;
	currency?: string;
	type?: string;
	inception?: Date;
	effectiveDate?: Date;
	demo?: boolean;
}

export interface ClientOption {
	code: string;
	shortName: string;
}

export const ProductContext = createContext<{
	selectedClient?: string;
	selectedProduct?: ProductOption;
	clientOptions: Array<ClientOption>;
	productOptions: RemoteData<Error, Array<ProductOption>>;
	onSelectProduct: (code: string) => void;
	onSelectClient: (name: string) => void;
}>({
	clientOptions: [],
	productOptions: initial,
	onSelectProduct: constVoid,
	onSelectClient: constVoid,
});

export const XS = 0;
export const SM = 576;
export const MD = 768;
export const LG = 992;
export const XL = 1200;
export const XXL = 1600;
export const BREAKPOINTS: Array<{ key: Breakpoint; min: number }> = [
	{ key: 'xxl', min: XXL },
	{ key: 'xl', min: XL },
	{ key: 'lg', min: LG },
	{ key: 'md', min: MD },
	{ key: 'sm', min: SM },
	{ key: 'xs', min: XS },
];

const { useBreakpoint: useBreakpointAntd } = Grid;
export const useBreakpoint = () => {
	const antd = useBreakpointAntd();
	if (Object.keys(antd).length === 0) {
		const w = window.innerWidth;
		for (let i = 0; i < BREAKPOINTS.length; i++) {
			if (w >= BREAKPOINTS[i].min) return BREAKPOINTS[i].min;
		}
	} else {
		for (let i = 0; i < BREAKPOINTS.length; i++) {
			if (!!antd[BREAKPOINTS[i].key]) return BREAKPOINTS[i].min;
		}
	}
	return XS;
};

export const useIsMobile = () => {
	const bp = useBreakpoint();
	return bp < MD;
};

export type Theme = 'dark' | 'light';
export const ThemeContext = createContext({
	theme: 'light' as Theme,
	setTheme: (theme: Theme) => {},
});

export const useIsDarkTheme = () => {
	return useContext(ThemeContext).theme === 'dark';
};

export const useCurrentDate = () => {
	const { selectedProduct } = useContext(ProductContext);
	return pipe(
		selectedProduct,
		optional.map(p => (p.demo ? p.effectiveDate : undefined)),
		optional.getOrElse(() => new Date()),
	);
};
