import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Theme, ThemeContext } from '../../utils/context.utils';

const storageKey = 'HorusEye.theme';
const themes = ['light', 'dark'];

export const ThemeContextProvider = memo(({ children }) => {
	const initialTheme = useMemo<Theme>(() => (localStorage.getItem(storageKey) || 'dark') as Theme, []);
	const [theme, setTheme] = useState<Theme>(initialTheme);
	const handleChange = useCallback(
		(val: Theme) => {
			setTheme(val);
			localStorage.setItem(storageKey, val);
		},
		[setTheme],
	);

	useEffect(() => {
		const body = document.body;
		if (body) {
			themes.forEach(option => body.classList.toggle(option, option === theme));
		}
	}, [theme]);

	return <ThemeContext.Provider value={{ setTheme: handleChange, theme }}>{children}</ThemeContext.Provider>;
});
