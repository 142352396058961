import { memo, SVGAttributes } from 'react';
import css from './Spinner.module.less';

interface SpinnerProps {
	size?: number;
}

const normalSize = 71;

function calcSize(size: number) {
	return (105 * size) / normalSize;
}

export const Spinner = memo<SpinnerProps & SVGAttributes<SVGElement>>(({ size = normalSize, ...rest }) => {
	const baseSize = (105 * size) / normalSize;
	const stroke = (4 * normalSize) / size;
	return (
		<svg width={baseSize} height={baseSize} viewBox="-15 0 105 105" fill="none" {...rest}>
			<g>
				<circle cx="37.5" cy="47.5" r="35.5" stroke="#D18F1F" strokeOpacity="0.3" strokeWidth={stroke} />
				<g filter="url(#filter0_d)">
					<g className={css.spinner}>
						<path
							d="M36.6364 12C56.7194 12 73 27.8939 73 47.5C73 67.1061 56.7194 83 36.6364 83C32.5668 83 28.6534 82.3474 25 81.1435"
							stroke="url(#paint0_linear)"
							strokeWidth={stroke}
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</g>
				</g>
			</g>
			<defs>
				<filter
					id="filter0_d"
					x="-15"
					y="0"
					width="105"
					height="105"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
					<feOffset dy="5" />
					<feGaussianBlur stdDeviation="7.5" />
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 0.819608 0 0 0 0 0.560784 0 0 0 0 0.121569 0 0 0 0.4 0"
					/>
					<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
					<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
				</filter>
				<linearGradient
					id="paint0_linear"
					x1="48.7576"
					y1="8.68667"
					x2="37.1919"
					y2="83.0864"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#F2B44C" stopOpacity="0.8" />
					<stop offset="1" stopColor="#D18F1F" />
				</linearGradient>
			</defs>
		</svg>
	);
});
