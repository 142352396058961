import { Badge, Col, Input, Row, Select } from 'antd';
import { array } from 'fp-ts';
import { Endomorphism, pipe } from 'fp-ts/lib/function';
import { ChangeEvent, memo, useContext, useEffect, useMemo, useState } from 'react';
import { TimePeriod } from '../../../utils/date.utils';
import { SearchOutlined } from '@ant-design/icons';
import { HexagonIcon } from '../../ui-kit/Hexagon';
import { TimePeriodSelector } from '../../ui-kit/TimePeriodSelector';
import { ProductContext, useIsDarkTheme } from '../../../utils/context.utils';
import { remoteData } from '../../../utils/remote-data.utils';
import cogSvg from '../../../icons/cog.svg';
import { constEmpty } from '../../../utils/data.utils';
import { ReportFilter } from './ReportsPage';
import cn from 'classnames';
import css from './ReportsFilter.module.less';

interface ReportsFilterProps {
	value: ReportFilter;
	onChange: (upd: Endomorphism<ReportFilter>) => void;
}

const datePickerTheme = {
	container: css.dateRange,
	preset: css.dateRangePreset,
};

export const ReportsFilter = memo<ReportsFilterProps>(({ value, onChange }) => {
	const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) =>
		onChange(value => ({ ...value, search: e.target.value }));
	const handleDateRangeChange = (dateRange: TimePeriod) => onChange(value => ({ ...value, dateRange }));
	const handleProductsChange = (products: string[]) => onChange(value => ({ ...value, products }));

	const { productOptions } = useContext(ProductContext);
	const productSelectOptions = useMemo(
		() =>
			pipe(
				productOptions,
				remoteData.getOrElse(constEmpty()),
				array.map(p => ({ label: p.name, value: p.id })),
			),
		[productOptions],
	);

	const isDark = useIsDarkTheme();
	const iconFill = isDark ? '#464646' : '#fff';

	const filterCount = [
		value.dateRange?.range || (value.dateRange?.preset && value.dateRange.preset !== 'all'),
		value.products.length > 0,
		value.search.match(/\S/),
	].filter(x => !!x).length;

	const [isAnimating, setAnimating] = useState<boolean | null>(null);
	const { collapsed } = value;

	useEffect(() => {
		if (isAnimating !== null) {
			onChange(value => ({ ...value, collapsed: !value.collapsed }));
			const tmr = setTimeout(() => setAnimating(null), 500);
			return () => clearTimeout(tmr);
		}
	}, [isAnimating, onChange]);

	// const toggleCollapsed = () => onChange(value => ({ ...value, collapsed: !value.collapsed }));
	const toggleCollapsed = () => setAnimating(!collapsed);

	return (
		<div className={css.container}>
			<HexagonIcon className={css.cog} onClick={toggleCollapsed} size={40} fill={iconFill}>
				<Badge size="default" count={filterCount}>
					<img src={cogSvg} />
				</Badge>
			</HexagonIcon>
			<div
				className={cn(css.inputsWrapper, collapsed && css.inputsWrapper_collapsed)}
				style={isAnimating === null && !collapsed ? { maxHeight: 'none' } : undefined}
			>
				<Row className={css.inputs}>
					<Col className={css.col}>
						<div className={css.label}>Keywords:</div>
						<Input
							type="text"
							value={value.search}
							className={css.search}
							onChange={handleSearchChange}
							suffix={<SearchOutlined />}
						/>
					</Col>
					<Col className={css.col}>
						<div className={css.label}>Reporting Date:</div>
						<TimePeriodSelector
							theme={datePickerTheme}
							value={value.dateRange}
							onChange={handleDateRangeChange}
						/>
					</Col>
					<Col className={css.col}>
						<div className={css.label}>Products:</div>
						<Select
							className={css.products}
							mode="multiple"
							dropdownMatchSelectWidth={false}
							options={productSelectOptions}
							value={value.products}
							onChange={handleProductsChange}
						></Select>
					</Col>
				</Row>
			</div>
		</div>
	);
});
