import { Col, Row } from 'antd';
import React, { Fragment, memo, useContext, useMemo } from 'react';
import { Widget } from '../../layout/Widget';
import { pending, RemoteData, failure, success } from '@devexperts/remote-data-ts';
import * as remoteData from '@devexperts/remote-data-ts';
import { pipe } from 'fp-ts/pipeable';
import { MetricCard } from '../../ui-kit/MetricCard';
import day1Svg from '../../../icons/day1.svg';
import day20Svg from '../../../icons/day20.svg';
import cashSvg from '../../../icons/cash.svg';
import css from './RiskValuesWidget.module.less';
import { Option } from 'fp-ts/lib/Option';
import { NA, renderOptionPercent } from '../../../utils/string.utils';
import { option } from 'fp-ts';
import { gql, useQuery } from '@apollo/client';
import { GetProductRiskValues, GetProductRiskValuesVariables } from '../../../../generated-types/GetProductRiskValues';
import { ProductContext, useIsMobile } from '../../../utils/context.utils';
import { useRemoteData } from '../../../utils/remote-data.utils';
import { toPercent } from '../../../utils/math.utils';
import { MetricsWidget } from '../metrics/MetricsWidget';
import { GlossaryHint } from '../../ui-kit/GlossaryHint';

export type RiskValues = {
	risk1Day: Option<number>;
	risk20Days: Option<number>;
	unencumberedCash: Option<number>;
};

interface RiskValuesWidgetProps {
	value: RemoteData<Error, RiskValues>;
}

export const RiskValuesWidget = memo<RiskValuesWidgetProps>(({ value }) => {
	const isMobile = useIsMobile();
	const colWidth = isMobile ? 100 / 2.25 : 100 / 3; //width >= 600 ? 100 / 4 : 100 / 2;
	const gutter = 10; //isMobile ? 10 : 20;

	return (
		<Col xs={24} md={12} className={css.container}>
			<MetricsWidget header={'Risk'} gutter={[gutter, gutter]}>
				<Col flex={`0 0 ${colWidth}%`} style={{ width: `${colWidth}%` }} className={css.col}>
					<MetricCard
						className={css.card}
						icon={<img src={day1Svg} />}
						title={
							<Fragment>
								1 Day Value at Risk&nbsp;
								<GlossaryHint term={/1 day value at risk/i} />
							</Fragment>
						}
						value={pipe(
							value,
							remoteData.toOption,
							option.chain(p => p.risk1Day),
							renderOptionPercent,
						)}
					/>
				</Col>
				<Col flex={`0 0 ${colWidth}%`} style={{ width: `${colWidth}%` }} className={css.col}>
					<MetricCard
						className={css.card}
						icon={<img src={day20Svg} />}
						title={
							<Fragment>
								20 Days Value at Risk&nbsp;
								<GlossaryHint term={/20 days? value at risk/i} />
							</Fragment>
						}
						value={pipe(
							value,
							remoteData.toOption,
							option.chain(p => p.risk20Days),
							renderOptionPercent,
						)}
					/>
				</Col>
				<Col flex={`0 0 ${colWidth}%`} style={{ width: `${colWidth}%` }} className={css.col}>
					<MetricCard
						className={css.card}
						icon={<img src={cashSvg} />}
						title={
							<Fragment>
								Unencumbered cash&nbsp;
								<GlossaryHint term={'Unencumbered cash'} />
							</Fragment>
						}
						value={
							NA /*pipe(	// TODO: temporary fix for wrong values from JSON
							value,
							remoteData.toOption,
							option.chain(p => p.unencumberedCash),
							renderOptionPercent,
					)*/
						}
					/>
				</Col>
			</MetricsWidget>
		</Col>
	);
});

const QUERY = gql`
	query GetProductRiskValues($product: String!) {
		products(ids: [$product]) {
			id
			generationDate
			unencumberedCash
			risk1Day
			risk20Days
		}
	}
`;

export const RiskValuesWidgetContainer = () => {
	const { selectedProduct } = useContext(ProductContext);
	const { data, loading, error } = useQuery<GetProductRiskValues, GetProductRiskValuesVariables>(QUERY, {
		variables: { product: selectedProduct?.id ?? '' },
		skip: !selectedProduct,
	});

	const dataRaw = useRemoteData(data, loading, error);
	const value = useMemo(
		() =>
			pipe(
				dataRaw,
				remoteData.map(data => ({
					risk1Day: pipe(option.fromNullable(data.products[0]?.risk1Day), option.map(toPercent)),
					risk20Days: pipe(option.fromNullable(data.products[0]?.risk20Days), option.map(toPercent)),
					unencumberedCash: pipe(
						option.fromNullable(data.products[0]?.unencumberedCash),
						option.map(toPercent),
					),
				})),
			),
		[dataRaw],
	);

	return <RiskValuesWidget value={value} />;
};
