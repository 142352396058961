import React, { memo, PropsWithChildren, useContext } from 'react';
import { Container, interfaces } from 'inversify';

export const DIContext = React.createContext(new Container());

export const DIChildContext = memo<PropsWithChildren<{ value: Record<string, unknown> }>>(({ children, value }) => {
	const container = useContext(DIContext);
	const child = new Container();
	child.parent = container;
	Object.keys(value).forEach(k => {
		child.bind(k).toConstantValue(value[k]);
	});
	return <DIContext.Provider value={child} children={children} />;
});

export const useDIContext = () => useContext(DIContext);

export const useDIValue = <T extends any>(key: interfaces.ServiceIdentifier<T>) => useDIContext().get(key);
