import { Card } from 'antd';
import { array, option } from 'fp-ts';
import { pipe } from 'fp-ts/lib/pipeable';
import {
	Fragment,
	memo,
	ReactNode,
	TouchEvent,
	UIEvent,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { chartColors } from '../../utils/color.utils';
import { ProductContext, ProductOption } from '../../utils/context.utils';
import { HexagonIcon } from '../ui-kit/Hexagon';
import moment from 'moment';
import { ReactComponent as CalendarSvg } from '../../icons/calendar.svg';
import css from './MobileFundsCarousel.module.less';
import cn from 'classnames';
import { remoteData } from '../../utils/remote-data.utils';
import { constEmpty } from '../../utils/data.utils';
import { renderRemoteData } from '../ui-kit/Loading';

const gutter = 10;
const horizPadding = 20;

export interface MobileFundsCarouselTheme {
	topPanel?: string;
	bottomPanel?: string;
}

interface MobileFundsCarouselProps {
	header?: ReactNode;
	extendedCards?: boolean;
	renderPanel: (product: ProductOption) => ReactNode;
	theme?: MobileFundsCarouselTheme;
}
/*
export const MobileFundsCarouselOld = memo<MobileFundsCarouselProps>(({ renderPanel }) => {
	const { productOptions } = useContext(ProductContext);
	const [currentIndex, setCurrentIndex] = useState(0);

	const [translateX, setTranslateX] = useState(0);

	const handleTouchStart = (e: TouchEvent) => {
		console.log('touchstart', e);
	};
	const handleTouchMove = (e: TouchEvent) => {
		console.log('touchmove', e);
	};

	const panelCnt = pipe(
		productOptions,
		remoteData.map(x => x.length),
		remoteData.getOrElse(() => 0),
	);
	const panelWidth = `100vw - ${2 * horizPadding}px`;
	const totalWidth = `calc((${panelWidth}) * ${panelCnt} + ${horizPadding * 2 + gutter * (panelCnt - 1)}px)`;

	return (
		<div className={css.carousel} onTouchStart={handleTouchStart} onTouchMove={handleTouchMove}>
			<div className={css.panels} style={{ width: totalWidth }}>
				{productOptions.map((product, index) => {
					return (
						<div className={css.panel} key={product.id} style={{ width: `calc(${panelWidth})` }}>
							<Card
								className={css.header}
								style={{ backgroundColor: chartColors[index % chartColors.length] }}
							>
								{product.name}
							</Card>
							{renderPanel(product)}
						</div>
					);
				})}
			</div>
		</div>
	);
});
*/
interface FundCardProps {
	product: ProductOption;
	index: number;
}

const FundCard = memo<FundCardProps>(({ product, index }) => {
	return (
		<Card
			bordered={false}
			className={css.header}
			style={{ backgroundColor: chartColors[index % chartColors.length] }}
		>
			{product.name}
		</Card>
	);
});

const FundCardExtended = memo<FundCardProps>(({ product, index }) => {
	return (
		<Card
			bordered={false}
			className={css.header}
			style={{ backgroundColor: chartColors[index % chartColors.length] }}
		>
			<div className={css.productTitle}>{product.name}</div>
			<div className={css.productDetails}>
				<div className={css.productInception}>
					{product.inception && (
						<Fragment>
							<HexagonIcon size={40} fill={'rgba(255,255,255,0.15)'} className={css.productInceptionIcon}>
								<CalendarSvg color="#fff" />
							</HexagonIcon>
							{moment(product.inception).format('YYYY-MM-DD')}
						</Fragment>
					)}
				</div>
				<div className={css.productType}>
					<span className={css.productTypeLabel}>Type: </span>
					{product.type}
				</div>
			</div>
		</Card>
	);
});

export const MobileFundsCarousel = memo<MobileFundsCarouselProps>(
	({ header, renderPanel, extendedCards = false, theme }) => {
		const { productOptions, selectedProduct, onSelectProduct } = useContext(ProductContext);
		const productOptionsOrEmpty = useMemo(
			() => pipe(productOptions, remoteData.getOrElse(constEmpty())),
			[productOptions],
		);
		const carouselRef = useRef<HTMLDivElement>(null);
		const [selectedPanel, setSelectedPanel] = useState(() =>
			pipe(
				productOptionsOrEmpty,
				array.findIndex(p => p.id === selectedProduct?.id),
				option.getOrElse(() => 0),
			),
		);

		useEffect(() => {
			if (carouselRef.current) {
				const totalWidth = carouselRef.current?.scrollWidth - 2 * horizPadding;
				const panelWidth = (totalWidth - gutter * (panelCnt - 1)) / panelCnt;
				carouselRef.current.scrollLeft = selectedPanel * panelWidth;
			}
		}, [carouselRef.current]);

		const panelCnt = productOptionsOrEmpty.length;
		const panelWidth = `100vw - ${2 * horizPadding}px`;
		const totalWidth = `calc((${panelWidth}) * ${panelCnt} + ${horizPadding * 2 + gutter * (panelCnt - 1)}px)`;

		const [panelOpacity, setPanelOpacity] = useState(1);
		const handleScroll = useCallback(
			(e: UIEvent<HTMLDivElement>) => {
				const totalWidth = e.currentTarget.scrollWidth - 2 * horizPadding;
				const panelWidth = (totalWidth - gutter * (panelCnt - 1)) / panelCnt;
				const maxScroll = totalWidth - panelWidth;
				const position = e.currentTarget.scrollLeft / maxScroll;
				const nearest = Math.round(position);
				if (Math.abs(nearest - position) < 0.05) {
					setSelectedPanel(nearest);
					onSelectProduct(productOptionsOrEmpty[nearest].id);
					console.log('Selecting product', productOptionsOrEmpty[nearest].id);
				}
				const dist = Math.abs(position - selectedPanel);
				console.log('scroll', totalWidth, panelWidth, maxScroll, e.currentTarget.scrollLeft, dist);
				setPanelOpacity(Math.max(0, 1 - dist));
			},
			[panelCnt, selectedPanel, productOptionsOrEmpty, onSelectProduct],
		);

		const onTouchEnd = useCallback(() => {
			console.log('touchend');
		}, []);

		const Card = extendedCards ? FundCardExtended : FundCard;

		return (
			<Fragment>
				{renderRemoteData(productOptions, {
					success: productOptions => (
						<Fragment>
							<div className={cn(theme?.topPanel, css.topPanel)}>
								<div
									className={css.carousel}
									ref={carouselRef}
									onScroll={handleScroll}
									onTouchEnd={onTouchEnd}
								>
									<div className={css.panels} style={{ width: totalWidth }}>
										{productOptions.map((product, index) => {
											return (
												<div
													className={css.panel}
													key={product.id}
													style={{ width: `calc(${panelWidth})` }}
												>
													<Card product={product} index={index} />
												</div>
											);
										})}
									</div>
								</div>
								{header}
							</div>
							<div className={cn(css.bottomPanel, theme?.bottomPanel)} style={{ opacity: panelOpacity }}>
								{renderPanel(selectedProduct ?? productOptions[0])}
							</div>
						</Fragment>
					),
				})}
			</Fragment>
		);
	},
);
