import { Typography } from 'antd';
import React, { memo, PropsWithChildren, ReactNode } from 'react';
import css from './WidgetTitle.module.less';

interface WidgetTitleProps {
	addon?: ReactNode;
}

export const WidgetTitle = memo<PropsWithChildren<WidgetTitleProps>>(({ children, addon }) => (
	<div className={css.header}>
		<Typography.Title className={css.title} level={2}>
			{children}
		</Typography.Title>
		<div className={css.addon}>{addon}</div>
	</div>
));
