import { Input, List, Skeleton, TableProps } from 'antd';
import React, { Fragment, memo, useMemo, useState } from 'react';
import * as remoteData from '@devexperts/remote-data-ts';
import { RemoteData, isPending } from '@devexperts/remote-data-ts';
import { pipe } from 'fp-ts/pipeable';
import { GetContacts_contacts } from '../../../../generated-types/GetContacts';
import { SearchOutlined } from '@ant-design/icons';
import { TableWidget } from '../../layout/TableWidget';
import { array } from 'fp-ts';
import { Optional, optional } from '../../../../../common/utils/nullable.utils';
import css from './ContactInfoWidget.module.less';

interface ContactInfoWidgetProps {
	contacts: RemoteData<Error, GetContacts_contacts[]>;
}

type ContactRow = Omit<GetContacts_contacts, 'firstName' | 'lastName'> & {
	name?: Optional<string>;
};

const columnConfigs: TableProps<ContactRow>['columns'] = [
	{
		dataIndex: 'company',
		key: 'company',
		sorter: true,
		title: 'Company',
	},
	{
		dataIndex: 'role',
		key: 'role',
		sorter: true,
		title: 'Role',
	},
	{
		dataIndex: 'name',
		key: 'name',
		sorter: true,
		title: 'Name',
	},
	{
		dataIndex: 'email',
		key: 'email',
		sorter: true,
		title: 'Email',
	},
	{
		dataIndex: 'phoneNumber',
		key: 'phoneNumber',
		sorter: true,
		title: 'Phone',
	},
];

export const ContactInfoWidget = memo<ContactInfoWidgetProps>(({ contacts }) => {
	const [search, setSearch] = useState('');
	const searchInput = (
		<Input
			allowClear
			type="text"
			value={search}
			className={css.search}
			onChange={e => setSearch(e.target.value)}
			suffix={<SearchOutlined />}
		/>
	);

	const data = useMemo(
		() =>
			pipe(
				contacts,
				remoteData.map(
					array.map(({ firstName, lastName, ...rest }) => ({
						...rest,
						name: pipe(
							((firstName ?? '') + ' ' + (lastName ?? '')).trim(),
							optional.filter(x => x.length > 0),
						),
						search: pipe(
							[firstName, lastName, rest.company, rest.email, rest.phoneNumber, rest.role],
							array.map(s => s?.trim()?.toLowerCase() ?? ''),
							arr => arr.join(' '),
						),
					})),
				),
				remoteData.map(items => {
					const term = search.toLowerCase();
					return items.filter(item => item.search.includes(term));
				}),
			),
		[contacts, search],
	);

	return (
		<Fragment>
			{searchInput}
			<TableWidget<ContactRow>
				// this `getContainer` suppresses the virtual scrollbar
				// TODO: find out a better way
				sticky={{ getContainer: () => document.querySelector<HTMLElement>('.ant-table-body')! }}
				rowKey={(p, index) => `row-${index}`}
				columns={columnConfigs /*.map(x => ({ ...x, width: 150 }))*/}
				scroll={{ x: 'auto' }}
				dataSource={data}
			/>
		</Fragment>
	);
});
